import { ActionType } from "@src/dtos/enums";
import { SigaSimRegState } from ".";
import { ActionReducer, IAction } from "../types";

const initReducer = function () {
    const INITIAL_STATE:SigaSimRegState = {
        documentData: [],
        loading: false,
        validatePukStatus: {
            code: null,
            errormsg: null
        },
        sigaRegisterResponse: {
            isSuccess: null,
            iccId: null,
            subscriptionId: null,
            errorMessage: null,
            isFMVNO: null
        }

    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.SigaSimRegGetDocumentRequest] = (state = INITIAL_STATE, actionData:any) => {
        return{
            ...state,
           // loading: true
        }
    }

    reducersByActions[ActionType.SigaSimRegGetDocumentResponse] = (state:SigaSimRegState, actionData:any) => {
        return{
            ...state,
            documentData: actionData,
            loading: false
        }
    }

    reducersByActions[ActionType.SigaSimRegValidatePukRequest] = (state: SigaSimRegState,actionData:any) => {
        return {
            ...state,
            loading: true
        }
    }

    reducersByActions[ActionType.SigaSimRegValidatePukResponse] = (state: SigaSimRegState, actionData:any) => {
        return {
            ...state,
            validatePukStatus: { ...state.validatePukStatus, ...actionData },
            loading: false
        }
    }

    reducersByActions[ActionType.SigaSimRegFormSubmitRequest] = (state: SigaSimRegState,actionData:any) => {
        return {
            ...state,
            loading: true
        }
    }

    reducersByActions[ActionType.SigaSimRegFormSubmitResponse] = (state: SigaSimRegState,actionData:any) => {
        return {
            ...state,
            loading: false,
            sigaRegisterResponse:  { ...state.validatePukStatus, ...actionData },
        }
    }

    reducersByActions[ActionType.SigaSimRegResetValidPuk] = (state:SigaSimRegState) => {
        return {
            ...state,
            loading: false,
            validatePukStatus:{
                code: null,
                errormsg: null
            }
        }
    }

    reducersByActions[ActionType.SigaSimRegResetRegister] = (state:SigaSimRegState) => {
        return {
            ...state,
            loading: false,
            sigaRegisterResponse:{
                isSuccess: null,
                iccId: null,
                subscriptionId: null,
                errorMessage: null,
                isFMVNO: null
            }
        }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    return reducer
}

export default initReducer