import { IStandaloneTopUpService } from "@src/services/StandaloneTopUpService";
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";

const initReducer = function (standaloneTopUpService:IStandaloneTopUpService) {
    const INITIAL_STATE = {
      fetchInProgress: false,
      clientData: (undefined as any),
      oldClientData: (undefined as any),
      number: '',
      clientDataError: '',
      topUpType:'LR',

      inProgressTopUp: (undefined as any),
      inProgressTopUpProductName: '',
      topUpResult: (undefined as any),
      // topUpError: { res: { Message: 'Omni_Delay' } }
      topUpError: (undefined as any)
    };

    let reducersByActions = {} as Partial<ActionReducer>
    
    reducersByActions[ActionType.StanaloneMsisdnDataRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
        oldClientData: state.clientData,
        clientData: (undefined as any),
        clientDataError: '',
        number: actionData.number
      };
    }

    reducersByActions[ActionType.StanaloneMsisdnDataResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        clientData: actionData,
        clientDataError: '',
      }
    }

    reducersByActions[ActionType.StanaloneMsisdnDataError] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        clientDataError: actionData.error,
        clientData: undefined
      };
    }

    reducersByActions[ActionType.StanaloneTopUpCleanErorr] = (state: any, actionData: any) => {
      return {
        ...state,
        clientDataError: ''
      }
    }

    reducersByActions[ActionType.StanaloneCleanClientData] = (state: any, actionData: any) => {
      return {
        ...state,
        clientData: undefined,
        number: '',
      }
    }
    reducersByActions[ActionType.StanaloneApplyTopUpRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        inProgressTopUp: actionData
      };
    }
    reducersByActions[ActionType.StanaloneApplyTopUpResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        topUpResult: actionData,
        topUpError: null
      };
    }
    reducersByActions[ActionType.StanaloneApplyTopUpError] =  (state: any, actionData: any) => {
      return {
        ...state,
        topUpResult: null,
        topUpError: actionData
      };
    }
    reducersByActions[ActionType.StandaloneMsisdnDataError] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        clientDataError: actionData.error,
        clientData: undefined
      };
    }
    
    const reducer = (state = {...INITIAL_STATE}, action: IAction): typeof INITIAL_STATE => {
      // console.log("state: ", state)
      // console.log("action: ", action)
      return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    
    return reducer;
  };
  
  export default initReducer;