import { ITopUpHistoryService } from "@src/services/TopUpHistoryService";
import moment from "moment";
import { ActionReducer, IAction } from "../types";
import { ActionType } from "@src/dtos/enums";
import { TopupHistoryDto } from "@src/dtos/transactionHistoryDto";
import { TopUpHistoryState } from ".";
import { FiltersDto } from "@src/dtos/filtersDto";

const INITIAL_STATE: TopUpHistoryState = {
  fetchInProgress: false,
  history: [] as TopupHistoryDto,
  isDownloading: false,
  filters: {
    dateFrom: moment().subtract(7, 'days').toDate().toISOString(),
    toDate: moment().toDate().toISOString(),
    msisdn: '',
    status: '',
    type: ''
  }
};

const initReducer = function (userService: ITopUpHistoryService) {

  const reducersByActions = {
    [ActionType.TopHistoryRequest]: (state: TopUpHistoryState, actionData: TopUpHistoryState['filters']): TopUpHistoryState => {
      return {
        ...state,
        fetchInProgress: true,
        history: [],
        filters: actionData
      };
    },
    [ActionType.TopHistoryResponse]: (state: TopUpHistoryState, actionData: { data: TopupHistoryDto }): TopUpHistoryState => {
      return {
        ...state,
        fetchInProgress: false,
        history: actionData.data,
      };
    },
    [ActionType.TopHistoryStartDownload]: (state: TopUpHistoryState): TopUpHistoryState => {
      return {
        ...state,
        isDownloading: true
      }
    },
    [ActionType.TopHistoryEndDownload]: (state: TopUpHistoryState): TopUpHistoryState => {
      return {
        ...state,
        isDownloading: false
      }
    },
    [ActionType.TopHistoryClean]: (): TopUpHistoryState => {
      return INITIAL_STATE
    },
    [ActionType.Logout]: (): TopUpHistoryState => {
      return INITIAL_STATE
    },

    [ActionType.TopHistorySetFilters]: (state: TopUpHistoryState, actionData: FiltersDto) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...actionData
        }
      }
    }

  } as Partial<ActionReducer>

  const reducer = (state = INITIAL_STATE, action: { type: keyof typeof reducersByActions, data: any }) => {
    // console.log("state: ", state)
    // console.log("action: ", action)
    return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
  };


  return reducer;
};

export default initReducer;