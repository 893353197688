import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, Grid2 as Grid, Input, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';

import styles from './style.module.scss'
import Loading from '@src/View/Widgets/Loading';
import LngSelect from '@src/View/Widgets/LngSelect';

import eyeIcon from '@src/res/eye.svg?url';
import closedEyeIcon from '@src/res/closed_eye.svg?url';
import { RootState } from '@src/modules/configureStore';
import { useAction } from '@src/View/Hooks/useAction';
import InfoImg from '@src/res/info.svg?url'
import { trackLoginAttempt, trackReCaptchaPopup } from '@src/modules/analyticsTracking';
import useReCaptcha from '../../Hooks/useReCaptcha'
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha'
// import useHttpServiceHook from '@src/View/Hooks/useHttpServiceHook';
import Info from '@src/View/Widgets/Info';
import { useLocation, useNavigate, useParams } from 'react-router';
// import { push } from 'redux-first-history';

type LoginFormInput = { login: string, password: string }

const formInitialState: LoginFormInput = { login: '', password: '' }

const CAPTCHA_LEN = 6

function MainLogin() {
    const { t } = useTranslation('translations')
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    // const dispatch = useDispatch()

    const login = useAction(actions => actions.user.login)
    const resetPassword = useAction(actions => actions.user.resetPassword)
    const cleanErorr = useAction(actions => actions.user.cleanErorr)
    const countrySelect = useAction(actions => actions.user.countrySelect)

    const user = useSelector((state: RootState) => state.user)

    const [formInput, setFormInput] = useReducer(
        (state: LoginFormInput, newState: Partial<LoginFormInput>) => ({ ...state, ...newState }), { ...formInitialState }
    );

    const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha(user.grecaptchaKey as string)
    const [captchaInput, setCaptchaInput] = React.useState('')
    const [showCaptcha, setShowCaptcha] = React.useState(false)

    const [resetPasswordShown, setResetPasswordShown] = React.useState(false)
    const [passwordShown, setPasswordShown] = React.useState(false)

    React.useEffect(() => {
        const match = /\/login\/([A-Za-z]{2})/.exec(location?.pathname)
        const { country } = params
        console.log('country', country)        

        if (match && match[1] && !user.selectedCountry) {
            countrySelect(match[1])
        }
    }, [location?.pathname])

    React.useEffect(() => {
        if (showCaptcha) {
            setTimeout(() => loadCaptchaEnginge(CAPTCHA_LEN), 1500)
        }
    }, [showCaptcha])

    const onCancel = () => {
        setResetPasswordShown(false);
        cleanErorr()
    }

    const handleSubmit = (ev: any) => {
        ev.preventDefault()
        if (!reCaptchaLoaded || showCaptcha) return
        const { login: axcode, password } = formInput
        trackLoginAttempt(axcode, user.selectedCountry as string)
        generateReCaptchaToken('LOGIN').then((token: string | null) => {
            login(axcode, password, user.selectedCountry as string, token as string)
        })        
    }

    useEffect(() => {
        return () => {
            setFormInput({ ...formInitialState })
            cleanErorr()
        }
    }, [])

    useEffect(() => {
        if (user.loginScore !== undefined && user.loginScore < 0.5 && !user.loginError) {
            setShowCaptcha(true)
            const { login: axcode } = formInput
            trackReCaptchaPopup(axcode, user.selectedCountry as string, user.loginScore, user.lowScoreReason)
        }
    }, [user.loginScore])

    const handleResetPasswordSubmit = (ev: any) => {
        ev.preventDefault()
        resetPassword(formInput.login, user.selectedCountry as string)
    }

    const handleInput = (evt: any) => {
        const name = evt.target.name as keyof LoginFormInput;
        const newValue = evt.target.value as string;
        setFormInput({ [name]: newValue });
    };

    const handleCaptchaInput = (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const text = ev.target.value
        if (/^[a-zA-Z0-9]{0,6}$/.test(text))
            setCaptchaInput(text)
    }

    const handleCaptchSubmit = () => {
        if (validateCaptcha(captchaInput)) {
            setShowCaptcha(false)
            const { login: axcode, password } = formInput
            trackLoginAttempt(axcode, user.selectedCountry as string)
            generateReCaptchaToken('LOGIN').then((token: string | null) => {
                setCaptchaInput('')
                login(axcode, password, user.selectedCountry as string, token as string)
            })
        } else {
            setCaptchaInput('')
        }
    }

    return (<>
        <div className={styles.body}>
            <div className={styles.bodyContainer}>
                <div className={styles.selectHeader}>{t('home-staticmsg1')}</div>
                <div className={styles.selectSubheader}>{t('home-staticmsg2')}</div>
                {!user.selectedCountry && <div className={styles.countrySelectContainer}>
                    <Loading inProgress={user.countriesInProgress || !user.countriesList}>
                        <Select
                            className={classNames('selectSelector', styles.selectSelector)}
                            value=''
                            displayEmpty
                            input={
                                <Input
                                    name="age"
                                    id="outlined-age-simple"
                                />
                            }
                            onChange={(ev, val: any) => {
                                navigate(`/login/${val.props.value}`)
                            }}
                        >
                            <MenuItem value="" className={styles.countyItem}>
                                {t('home-ChooseYourCountry')}
                            </MenuItem>
                            {user.countriesList && user.countriesList.map(country =>
                                <MenuItem value={country.id} key={country.id} className={styles.countyItem}>{country.fullName}</MenuItem>
                            )}
                        </Select>
                    </Loading>
                    <div className={styles.copyright}>© Copyright 2018 Lebara Retail. All rights reserved.</div>
                </div>}
                {user.selectedCountry &&
                    <div className={styles.loginContainer}>
                        <div className={styles.formRow}>
                            <div className={styles.formContainer}>
                                {!resetPasswordShown && <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <div className={styles.formText}>{t('logon-selectlanguage')}</div>
                                    <LngSelect />
                                    <div className={styles.formText}>{t('logon-userid')}</div>
                                    <Input
                                        className={styles.formField}
                                        id="login"
                                        name="login"
                                        placeholder={t('logon-userid')}
                                        onChange={handleInput}
                                        value={formInput.login}
                                        autoComplete="off"
                                    />
                                    {(user.idError && !formInput.login) && <div className={styles.error}>{t('login-usernamemsg')}</div>}
                                    <div className={styles.formText}>{t('login-password')}</div>
                                    <Input
                                        className={styles.formField}
                                        id="Password"
                                        name="password"
                                        placeholder={t('logon-password')}
                                        type={passwordShown ? "text" : "password"}
                                        value={formInput.password}
                                        onChange={handleInput}
                                        autoComplete="off"
                                        endAdornment={
                                            <InputAdornment position="end" className={styles.showHideIcon} onClick={() => setPasswordShown(!passwordShown)}>
                                                <img src={passwordShown ? eyeIcon : closedEyeIcon} alt="..." />
                                            </InputAdornment>
                                        }
                                    />
                                    {(user.passwordError && !formInput.password) && <div className={styles.error}>{t('login-passwordmsg')}</div>}
                                    <Button
                                        className={styles.formButton}
                                        variant="contained"
                                        color="primary"
                                        disabled={!reCaptchaLoaded || showCaptcha}
                                        type="submit"
                                    >
                                        {t('logon-submit')}
                                    </Button>
                                    {user.loginError && <div className={styles.loginError}>{t(user.loginError)}</div>}
                                    <div className={styles.forgotPassword}>{t('logon-forgot')}</div>
                                    <div className={styles.resetPassword} onClick={() => setResetPasswordShown(true)}>{t('logon-resetpassword')}</div>
                                    <div className={styles.userIdHint}>{t('logon-loginid')}</div>
                                </form>}

                                {user.resetPasswordResult && <div className={styles.resetSuccess}>{t('logon-resetpasswordinfo')}</div>}
                                {resetPasswordShown && !user.resetPasswordResult && <form noValidate autoComplete="off" onSubmit={handleResetPasswordSubmit}>
                                    <div className={styles.formText}>
                                        {t('logon-resetpasswordheader')}&nbsp;&nbsp;
                                        <Tooltip title={
                                            <React.Fragment>
                                                <div style={{ fontSize: '130%', lineHeight: '1.5em', padding: '0 6px' }} dangerouslySetInnerHTML={{ __html: `${t('password-reset-info-box')}` }} />
                                            </React.Fragment>
                                        } placement="right-start">
                                            <img src={InfoImg} alt="..." />
                                        </Tooltip>
                                        {/* <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <Tooltip
                                                PopperProps={{
                                                disablePortal: true,
                                                }}
                                                onClose={handleTooltipClose}
                                                open={open}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={
                                                <React.Fragment>
                                                    <div style={{ fontSize: '130%', lineHeight: '1.5em', padding: '0 6px' }} dangerouslySetInnerHTML={{ __html: `${t('password-reset-info-box')}` }} />
                                                </React.Fragment>
                                                } placement="right-start">
                                                <img onClick={handleTooltipOpen} src={InfoImg} alt="..." />
                                            </Tooltip>
                                            </ClickAwayListener> */}
                                    </div>
                                    <Input
                                        className={styles.formField}
                                        id="login"
                                        name="login"
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                    {user.resetPasswordError && <div className={styles.loginErrorResertPassword}>{t(user.resetPasswordError)}</div>}
                                    <div className={styles.buttonContainer}>
                                        <Button
                                            className={styles.formButton}
                                            variant="contained"
                                            color="primary"
                                            disabled={user.loginInProgress}
                                            type="submit"
                                        >
                                            Log in
                                        </Button>
                                        <Button
                                            className={styles.formButton}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className={styles.userIdHint}>{t('logon-loginid')}</div>
                                    <Loading inProgress={user.resetPasswordInProgress}>
                                        <div className={styles.loading} />
                                    </Loading>
                                </form>}
                            </div>
                            <div className={styles.newsContainer}
                                dangerouslySetInnerHTML={{ __html: t('logon-rightcontent') }} />
                        </div>
                    </div>
                }
            </div>
        </div>
        <Dialog open={showCaptcha}>
            <DialogContent style={{ maxWidth: 400, overflow: 'hidden' }}>
                <Grid container spacing={3}>
                    <Grid size={12}>
                        <Typography variant='h5' >{t('recaptcha-header-popup')}</Typography>
                    </Grid>
                    <Grid size={12}>
                        <Info>{t('recaptcha-description-text')}</Info>
                    </Grid>
                    <Grid size={6}>
                        <LoadCanvasTemplate reloadText={t('recaptcha-reload-challenge')} />
                    </Grid>
                    <Grid size={6}>
                        <TextField variant='outlined' value={captchaInput} onChange={handleCaptchaInput} size='small' />
                    </Grid>
                    <Grid size={12}>
                        <Button 
                            onClick={handleCaptchSubmit}
                            className={styles.formButton}
                            style={{ width: '100%', marginBottom: 12 }}
                            variant="contained"
                            color="primary"
                            disabled={captchaInput.length != CAPTCHA_LEN}>
                                Log in
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </>);
}

export default MainLogin
