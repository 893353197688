import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { MyOrdersDto } from '@src/dtos/myOrdersDto';

export interface MyOrdersFilters {
  dateFrom?: Date | null;
  toDate?: Date | null;
  orderNumber?: string | unknown;
  status?: string | unknown;
  productId?: string | unknown;
  pageNumber?: number;
  orderBy?: string | unknown;
  orderDesc?: boolean;
}

export interface MyOrdersState {
  fetchInProgress: boolean;
  myOrders: MyOrdersDto;
  isDownloading: boolean;
  products: any;
  filters: MyOrdersFilters;
  OrderTotalCount: number;
  orderSummary: any;
  isFetchingSummary: boolean;
}

const configureMyOrdersModule = (services: IServices) => {
  const actions = initActions(services.myOrdersService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type MyOrdersReducer = ReturnType<typeof initReducer>
export type MyOrdersActions = ReturnType<typeof initActions>

export default configureMyOrdersModule;