import { IServices } from "@src/services";
import initActions from "./actions";
import initReducer from "./reducer";

export interface PrepaidPortalUserDetails {
    FirstName: string | null,
    LastName: string | null,
    DOB: string | null,
    Email: string | null,
    MSISDN: string,
    Operator: string | null,
    RequestDate: string | null,
    IsOnContract: Boolean | null,
    HouseNumber: string | null,
    Street: string | null,
    ZipCode: string | null,
    City: string | null,
    AddressCountry: string | null,
    PlaceSelected: Boolean | null,
}

export interface PrepaidPortalSimDetails {
    PUKCode: string,
    NewIccid: string
}

export interface IsSuccess {
    status: Boolean, 
    msisdn: string, 
    isServiceError: Boolean,
    message: string
}
export enum Pages {
    PrepaidPortalForm = 'PrepaidPortalForm',
    ConfirmPrepaidNumber = 'ConfirmPrepaidNumber',    
    PrepaidNewSimDetails = 'PrepaidNewSimDetails',
    PrepaidPortalResult = 'PrepaidPortalResult'
}

export interface PrepaidPortinState {
    prepaidPortalUserDetails: PrepaidPortalUserDetails,
    prepaidPortalSimDetails: PrepaidPortalSimDetails,
    page: string | null,
    isFetchingSessionId: Boolean | null,
    sessionId: string | null,
    loading: Boolean | null,
    networkOperators: any[],
    nonPortInDates: any[],
    isValidRio: Boolean,
    rioCode: string,
    IsSuccess: IsSuccess,
    errorType: string,
    isGeneratingOtag: Boolean | null,
    isOTAGGenerateFailed:  Boolean | null,
}

const configurePrepaidPortalModule = (services: IServices) => {
    const actions = initActions(services.prepaidPortalService, services.storageService);
    const reducer = initReducer(services.storageService);
  
    return { actions, reducer };
};  
  
export type PrepaidPortalReducer = ReturnType<typeof initReducer>
export type PrepaidPortalActions = ReturnType<typeof initActions>;

export default configurePrepaidPortalModule