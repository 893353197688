import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { Observable } from "rxjs"
import { TopupHistoryDto } from "@src/dtos/transactionHistoryDto"
import { FiltersDto } from "@src/dtos/filtersDto"
import { filterEncodedStr } from "@src/utils"

const TopUpHistoryService = (ajax: ajaxType) => {return {
    
    fetchHistory: (filters: FiltersDto): Observable<TopupHistoryDto> => {
        return ajax.get(`${config.url.API_URL}Transaction/TopupHistory?${filterEncodedStr(filters)}`, 
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))        
    },
    downloadHistory: (filters: FiltersDto, locale: string) => {
        return ajax.get(`${config.url.API_URL}Transaction/TopupHistoryDownload/${locale}?${filterEncodedStr(filters)}`, 
            {"Content-Type": "application/json"}
        ).pipe(map(res => res.response))
    }
}}

export type ITopUpHistoryService = ReturnType<typeof TopUpHistoryService>

export default TopUpHistoryService