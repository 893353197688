import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { Observable } from "rxjs"
import { PrintHistoryByDayDto, PrintHistoryByDateDto } from "@src/dtos/printHistoryDto"
import { EpinNumberType } from "@src/dtos/enums"
import { EpinPrintPopupDto } from "@src/dtos/epinPrintDto"

type PrintHistoryBySerial = PrintHistoryByDateDto[0]

const PrintHistoryService = (ajax: ajaxType) => {return {
    
    fetchHistoryByDay: (range: number): Observable<PrintHistoryByDayDto> => {
        return ajax.get(`${config.url.API_URL}Print/history/Day/${range}`, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    fetchHistoryByDate: (startDate: string, endDate: string): Observable<PrintHistoryByDateDto> => {
        return ajax.get(`${config.url.API_URL}Print/history/Date/${startDate}/${endDate}`, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    fetchHistoryBySerial: (serialnumber: string): Observable<PrintHistoryBySerial> => {
        return ajax.get(`${config.url.API_URL}Print/history/SerialNumber/${serialnumber}`, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    fetchPrintOutInfo: (type: EpinNumberType, number: string, isPrint: boolean = true, Locale: string = 'en-GB'): Observable<EpinPrintPopupDto> => {
        return ajax.post(`${config.url.API_URL}Print/Popup`,
            {
                IsOrderNumberPrint: (type === EpinNumberType.orderNumber),
                IsSerialNumberPrint: (type === EpinNumberType.serialNumber),
                IsBatchNumberPrint: (type === EpinNumberType.batchNumber),
                Number: number,
                isPrint: isPrint,
                Locale
            }, 
            {
                "Content-Type": "application/json"
            }
        ).pipe(map(res => res.response))
    },
    activationStatus: (request: any) => {
        return ajax.post(
            `${config.url.API_URL}Print/history/Activation/status`,
            request,
            {"Content-Type": "application/json"}).pipe(map(res => res.response))
    },
    updateOrderStatus: (orderNumber: string) => {
        return ajax.put(
            `${config.url.API_URL}Print/Update/status/orderNumber?orderNumber=${orderNumber}`,
            {},
            {"Content-Type": "application/json"}).pipe(map(res => res.response))
    }
}}

export type IPrintHistoryService = ReturnType<typeof PrintHistoryService>

export default PrintHistoryService