import React, { ReactNode, CSSProperties } from 'react';
import styles from './style.module.scss'

type Props = {
    content: ReactNode
    label: string
    body?: ReactNode
    bodyStyles?: CSSProperties
}

function OldStyleContainer(prop: Props) {
    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                {prop.content}
            </div>
            {prop.label}
            {prop?.body &&
                <div className={styles.body} style={{...prop?.bodyStyles}}>
                    {prop?.body}
                </div>
            }
        </div>
    );
}

export default OldStyleContainer