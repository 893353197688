import { map } from "rxjs/operators";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { i18n } from "i18next";
import { IAssignBundleHistoryService } from "@src/services/AssignBundleHistoryService";

const initActions = function (ItemService: IAssignBundleHistoryService) {
    
    const fetchHistory = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { filters } = getState().assignBundleHistory
        dispatch({type: ActionType.BundleHistoryRequest, data: filters})
        ItemService.fetchHistory(filters)
            // .pipe(map(data => {
            //     return data
            // }))
            .subscribe(data => {
                dispatch({type: ActionType.BundleHistoryResponse, data: {data}})
            })
    };

    const cleanHistory = (dispatch: AppDispatch, getState: ()=>RootState, i18n: i18n) => () => {
        dispatch({type: ActionType.BundleHistoryClean})
    };
    
    return { fetchHistory, cleanHistory }; 
  };
  
  export default initActions;