import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { InvoiceHistoryDto } from "@src/dtos/invoiceHistoryDto";
import moment from 'moment'

export interface InvoiceHistoryFilter {
    dateFrom?: Date | undefined,
    toDate?: Date | undefined,
    filterBy?: string | unknown,
    orderNo?: string | unknown,
    pageNumber?: number
}

const INITIAL_STATE = {
    fetchInProgress: false,
    invoiceHistory: ([] as InvoiceHistoryDto),
    filters: {
        dateFrom: moment().subtract(3, 'months').toDate(),
        toDate: moment().toDate(),
        // dateFrom: null,
        // dateTo: null,
        filterBy: '0',
        orderNo: '',
        pageNumber: 0
    } as InvoiceHistoryFilter
}

export type InvoiceHistoryState = typeof INITIAL_STATE

const initReducer = function () {

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.InvoiceHistoryUpdateFilter] = (state: InvoiceHistoryState, actionData: InvoiceHistoryFilter) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.InvoiceHistoryRequest] = (state: InvoiceHistoryState) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }

    reducersByActions[ActionType.InvoiceHistoryResponse] = (state: InvoiceHistoryState, actionData: any) => {
        return {
            ...state,
            fetchInProgress: false,
            invoiceHistory: actionData
        }
    }

    reducersByActions[ActionType.CleanInvoiceHistory] = (state: InvoiceHistoryState) => {
        return {
            ...state,
            invoiceHistory: [],
            filters: {...INITIAL_STATE.filters}
        }
    }

    reducersByActions[ActionType.Logout] = () => {
        return INITIAL_STATE
    }

    const reducer = (state = INITIAL_STATE, action: IAction): InvoiceHistoryState => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer;
}

export default initReducer