import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { MyCommissionSetFilters, MyCommissionState } from "./index"
import moment from 'moment';

const initReducer = function () {

    const INITIAL_STATE: MyCommissionState = {
      fetchInProgress: false,
      commissionData: undefined,
      commissionsummaryData: (undefined as any),
      filters:{
        status: 'All',
        dateFrom: moment().subtract(3, 'months').toDate(),
        toDate: moment().toDate(),
      } as MyCommissionSetFilters,
    };

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.CommissionDataRequest] =  (state: MyCommissionState): MyCommissionState => {
      return {
        ...state,
        fetchInProgress: true,
      };
    }

    reducersByActions[ActionType.Logout] =  (): MyCommissionState => {
      return INITIAL_STATE
    }

    reducersByActions[ActionType.CommissionDataResponse] =  (state: MyCommissionState, actionData: any): MyCommissionState => {
      return {
        ...state,
        fetchInProgress: false,
        commissionData: actionData
      };
    }

    reducersByActions[ActionType.CommissionSetFilters] =  (state: MyCommissionState, actionData: any): MyCommissionState => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...actionData
      }
      };
    }

    reducersByActions[ActionType.CommissionSummaryRequest] =  (state: any): MyCommissionState => {
      return {
        ...state,
        fetchInProgress: true,
      };
    }
    reducersByActions[ActionType.CommissionSummaryResponse] =  (state: any, actionData: any): MyCommissionState => {
      return {
        ...state,
        fetchInProgress: false,
        commissionsummaryData: actionData
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): MyCommissionState => {
      return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    
    return reducer;
  };
  
  export default initReducer;