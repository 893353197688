import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { BundleAllocationHistoryDto } from '@src/dtos/transactionHistoryDto';

export type AssignBundleState = {
    fetchInProgress: boolean,
    history: BundleAllocationHistoryDto
    isDownloading: boolean
    filters: {
      dateFrom: string,
      toDate: string
    }
}

const configureAssignBundleHistoryModule = (services: IServices) => {
  const actions = initActions(services.assignBundleHistoryService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type AssignBundleHistoryReducer = ReturnType<typeof initReducer>
export type AssignBundleHistoryActions = ReturnType<typeof initActions>

export default configureAssignBundleHistoryModule;