import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { IPostPayService } from "@src/services/PostPayService";
import { ContractStatusFilter } from "./reducer";
import { IStorageService } from "@src/services/StorageService";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PostpaySteps } from "@src/dtos/enums";
import { ContractStatusSummaryDto } from "@src/dtos/contractStatusDto";
import { stdDateString } from "@src/utils";

const initActions = (PostPayServices: IPostPayService, storageService: IStorageService) => {

    const updateFilters = (dispatch: AppDispatch) => (filters: ContractStatusFilter) => {
        dispatch({ type: ActionType.ContractStatusUpdateFilter, data: filters })
    }

    const getContractStatusList = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { SessionId, filters } = getState().contractStatus
        const { dateFrom, toDate } = stdDateString(filters)
        const observables: Observable<ContractStatusSummaryDto | string>[] = [PostPayServices.fetchContractStatusList(dateFrom as string, toDate as string)]
        if(!SessionId) observables.push(PostPayServices.getSessionId())
        dispatch({ type: ActionType.ContractStatusGetListRequest })
        forkJoin(observables)
            .subscribe(res => {
                dispatch({ type: ActionType.ContractStatusGetListResponse, data: { contractList: res[0], SessionId: SessionId ? SessionId : res[1] } })
            })
    }

    const cleanContractStatusData = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.ContractStatusCleanData })
    }

    const sendContractLinkSMS = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusSendSMSRequest })
        PostPayServices.smsContractLink(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.ContractStatusSendSMSResponse, data: { isSuccess: true } })
            } else {
                dispatch({ type: ActionType.ContractStatusSendSMSResponse, data: { isSuccess: false } })
            }
        })
    }

    const sendContractLinkEmail = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusSendEmailRequest })
        PostPayServices.emailContractSummary(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                dispatch({ type: ActionType.ContractStatusSendEmailResponse, data: { isSuccess: true } })
            } else {
                dispatch({ type: ActionType.ContractStatusSendEmailResponse, data: { isSuccess: false } })
            }
        })
    }

    const resendContractMail = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusResendEmailRequest })
        PostPayServices.resendEmail(payload).subscribe(res => 
            dispatch({ type: ActionType.ContractStatusResendEmailResponse, data: { isSuccess: res } })            
        )
    }

    const resignOnscreen = (dispatch: AppDispatch, getState: () => RootState) => (payload: any) => {
        dispatch({ type: ActionType.ContractStatusResignOnscreenRequest })
        PostPayServices.resignOnscreen(payload).subscribe(res => {
            if (res.ResponseCode === 200) {
                storageService.set('postpayportinstate', JSON.stringify({
                    postPayload: { ...getState().postPay.postPayload, EnvelopeId: payload.EnvelopeId },
                    step: PostpaySteps.MigrationStatus,
                    CartTotal: getState().postPay.CartTotal,
                    productType: getState().postPay.productType,
                }))
                window.location.replace(res.RedirectionURL)
            } else {
                dispatch({ type: ActionType.ContractStatusResignOnscreenResponse, data: { isSuccess: false } })
            }
        })
    }

    const retryContractStatus = (dispatch: AppDispatch, getState: () => RootState) => (envelopId: string) => {
        dispatch({ type: ActionType.ContractStatusRetryRequest })
        PostPayServices.retryContract(envelopId).subscribe(res => {
            dispatch({ type: ActionType.ContractStatusRetryResponse, data: { isSuccess: res } })
            res && setTimeout(() => getContractStatusList(dispatch, getState)(), 1500)
        })
    }

    const getContractStatusInfo = (dispatch: AppDispatch, getState: () => RootState) => (SessionId: string, locale: string, msisdn: string, isPortIn: boolean) => {
        dispatch({ type: ActionType.ContractStatusContractInfoRequest })
        PostPayServices.contractStatusInfo(
            SessionId, locale, msisdn, isPortIn
        ).subscribe(res => {
            dispatch({ type: ActionType.ContractStatusContractInfoResponse, data: { data: res, isSuccess: res.ResponseCode === 200 } })
        })
    }

    const downloadContract = (dispatch: AppDispatch) => (envelopId: string) => {
        dispatch({ type: ActionType.ContractStatusDownloadContractRequest })
        PostPayServices.downloadContract(envelopId)
            .pipe(map(data => {
                if (!data) return false;
                const blob = new Blob([data], { type: 'application/pdf' }); 
                const blobURL = URL.createObjectURL(blob);

                const iframe = document.createElement('iframe'); 
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobURL;
                iframe.onload = () => {
                    setTimeout(() => {
                        iframe.focus();
                        iframe?.contentWindow?.print();                        
                    }, 1);
                };                
                return true;
            })).subscribe(res => {
                dispatch({ type: ActionType.ContractStatusDownloadContractResponse, data: { isSuccess: res } })
            })
    }

    return {
        updateFilters,
        getContractStatusList,
        cleanContractStatusData,
        sendContractLinkSMS,
        sendContractLinkEmail,
        resendContractMail,
        resignOnscreen,
        retryContractStatus,
        getContractStatusInfo,
        downloadContract
    }
}

export default initActions