import { ICommissionService } from "@src/services/CommissionService";
import { map } from "rxjs/operators";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { MyCommissionSetFilters } from ".";
import { stdDateString } from "@src/utils";

const initActions = function (commissionService: ICommissionService) {


  const fetchCommissionInfo = (dispatch: AppDispatch, getState: () => RootState) => () => {
    if (getState().commission?.fetchInProgress) {
      return
    }
    dispatch({ type: ActionType.CommissionDataRequest })
    const filters = getState().commission.filters
    commissionService.fetchCommissionInfo(stdDateString(filters, true)).subscribe(res => {
      dispatch({ type: ActionType.CommissionDataResponse, data: res })
    })
  }

  const fetchCommissionSummary = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.CommissionSummaryRequest })
    commissionService.fetchCommissionSummary()
      .pipe(map(data => {
        if (data) {
          return {
            TotalUsed: data.TotalUsed,
            TotalEarned: data.TotalEarned,
            ReservedCommission: data.ReservedCommission,
            UsedCommission: data.UsedCommission,
            AvailableCommission: data.AvailableCommission,
            AvailableCommissionForCart: data.AvailableCommissionForCart,
            Balance: data.Balance,
            TotalCount: data.TotalCount,
            Timestamp: data.Timestamp
          }
        } else {
          return undefined
        }
      }))
      .subscribe(data => dispatch({ type: ActionType.CommissionSummaryResponse, data: data }));
  };

  const setCommissionFilter = (dispatch: AppDispatch) => (filters: Partial<MyCommissionSetFilters>) => {
    dispatch({ type: ActionType.CommissionSetFilters, data: filters })
  }

  return { fetchCommissionInfo, setCommissionFilter, fetchCommissionSummary };
};

export default initActions;