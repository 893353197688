import { IAlertsService } from "@src/services/AlertsService";
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";

const initReducer = function (alertsService:IAlertsService) {

    const INITIAL_STATE = {
      fetchInProgress: false,
      alertsData: (undefined as any)
    };

    let reducersByActions = {} as Partial<ActionReducer>
    reducersByActions[ActionType.AlertsDataRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
      };
    }

    reducersByActions[ActionType.Logout] =  (state: any, actionData: any) => {
      return INITIAL_STATE
    }

    reducersByActions[ActionType.AlertsDataResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        alertsData: actionData
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    
    return reducer;
  };
  
  export default initReducer;