import { Box, FormHelperText, Input, Select, SelectProps, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import { withStyles } from 'tss-react/mui';

type IStyledSelectProps = SelectProps & {
    placeholder?: string
    helperText?: string
    noOutline: boolean
}

const Text = withStyles(
    Typography,
    () => ({
        root: {
            opacity: 0.4
        }
    })
)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const FormSelect = (props: Partial<IStyledSelectProps>) => {
    const options = useMemo(() => {
        return props.children ? (props.children as Array<any>).map(x => ({ value: x.props?.value, label: x.props?.children })) : []
    }, [props.children])

    const rendered = (selected: unknown) => {
        if (!props.multiple) {
            return (selected == null || selected == undefined) ? <Text>{props.placeholder ?? '----Select----'}</Text> : <>{options?.find(x => x.value === props.value || x.value === props.defaultValue)?.label}</>
        } else {
            if ((selected as Array<string>).length === 0) {
                return <Text>{props.placeholder ?? '----Select----'}</Text>;
            }

            return (selected as Array<string>).join(', ')
        }
    }

    const labelString = useMemo(() => {
        return props.label?.toString()
    }, [props.label])

    const properties = {...props}
    delete properties.noOutline

    return (
        <Box style={props.style}>
            <Select
                {
                ...properties
                }
                // displayEmpty={Object.hasOwn(props, 'displayEmpty') ? props.displayEmpty : true}
                style={{ width: '100%' }}
                renderValue={props.renderValue ?? rendered}
                disableUnderline
                className={props.className ?? ''}
                inputProps={{ 'aria-label': labelString }}
                MenuProps={{
                    disableScrollLock: false,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                        },
                    },
                }}
            >
                {props.children}
            </Select>
            {props.helperText && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
        </Box>
    )
}

const StyledSelect = withStyles(
    FormSelect,
    (theme, { noOutline = false, ...props }, classes) => ({
        root: {
            boxShadow: 'none',
            maxHeight: 40,
            minWidth: 100,
            ['& .MuiOutlinedInput-notchedOutline']: {
                border: noOutline ? 0 : `1px solid ${alpha(theme.palette.common.black, 0.23)}`
            }
        }
    })
)

export default StyledSelect;