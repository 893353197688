import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { PortInResubmitState } from "./index";

const initReducer = function () {
    const INITIAL_STATE: PortInResubmitState = {
        serviceId: '',
        loading: false,
        resubmissionState: null,
        contractDetails: {
            AxCode: null,
            City: null,
            CountryCode: null,
            CrmId: null,
            DOB: null,
            Email: null,
            FirstName: null,
            HouseNo: null,
            LastName: null,
            Locale: null,
            PostCode: null,
            SessionId: null,
            Status: null,
            StreetName: null,
            donorOperator: null,
            isOnContract: null,
            newIccid: null,
            orderId: null,
            portingDate: null,
            requestedMsisdn: null,
            requstedDate: null,
            retailerId: null,
            status: null,
            temporaryMsisdn: null
        }
    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.PortInResubmitServiceIdRequest] = (state: PortInResubmitState, actionData: any) => {
        return {
            ...state,
           // loading: true
        }
    }

    reducersByActions[ActionType.PortInResubmitServiceIdResponse] = (state: PortInResubmitState, actionData: any) => {
        return {
            ...state,
            loading: false,
            serviceId: actionData
        }
    }

    reducersByActions[ActionType.PortInResubmitContractDetailsRequest] = (state: PortInResubmitState, actionData: any) => {
        return {
            ...state,
            loading: true
        }
    }

    reducersByActions[ActionType.PortInResubmitContractDetailsResponse] = (state: PortInResubmitState, actionData: any) => {
        return {
            ...state,
            contractDetails: actionData,
            loading: false
        }
    }

    reducersByActions[ActionType.PortInResubmitFormSubmitRequest] = (state: PortInResubmitState, actionData: any) => {
        return {
            ...state,
            loading: true
        }
    }

    reducersByActions[ActionType.PortInResubmitFormSubmitResponse] = (state: PortInResubmitState, actionData: any) => {
        return {
            ...state,
            loading: false,
            resubmissionState: actionData
        }
    }

    reducersByActions[ActionType.PortInResubmitContractDetailsReset] = (state: PortInResubmitState) => {
        return {
            ...state,
            contractDetails: INITIAL_STATE.contractDetails,
            resubmissionState: null
        }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer;
}

export default initReducer