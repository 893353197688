import React, { useEffect, useRef } from 'react';
import styles from './Home.module.scss'
import { Route, Outlet } from 'react-router-dom'

// import Header from './Header';
const Header = React.lazy(() => import('./Header'));
// import ClientInfoPanel from './ClientInfoPanel';
const ClientInfoPanel = React.lazy(() => import('./ClientInfoPanel'));
// import DashboardTiles from './DashboardTiles';
const DashboardTiles = React.lazy(() => import('./DashboardTiles'));
// import Dashboard from './Dashboard';
const Dashboard = React.lazy(() => import('./Dashboard'));
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
// import TransactionHistory from './TransactionHistory';
const TransactionHistory = React.lazy(() => import('./TransactionHistory'));
// import { UserProfile } from './User';
import Loading from '../Widgets/Loading';
import { RootState } from '@src/modules/configureStore';
// import BuyAndPrintHistory from './BuyAndPrintHistory';
const BuyAndPrintHistory = React.lazy(() => import('./BuyAndPrintHistory'));
// import Cart from './Buy/Cart';
const Cart = React.lazy(() => import('./Buy/Cart'));
// import OrderConfirmation from './Buy/OrderConfirmation';
const OrderConfirmation = React.lazy(() => import('./Buy/OrderConfirmation'));
// import MultiEpinOrderConfirmation from './Buy/MultiEpinOrderConfirmation';
const MultiEpinOrderConfirmation = React.lazy(() => import('./Buy/MultiEpinOrderConfirmation'));
// import OrderComplete from './Buy/OrderComplete';
const OrderComplete = React.lazy(() => import('./Buy/OrderComplete'));
// import AddToCartErrorDialog from './Dashboard/AddToCartErrorDialog';
const AddToCartErrorDialog = React.lazy(() => import('./Dashboard/AddToCartErrorDialog'));
// import AdvancedPayment from './AdvancedPayment';
const AdvancedPayment = React.lazy(() => import('./AdvancedPayment'));
// import AllowedPayments from './AllowedPayments';
const AllowedPayments = React.lazy(() => import('./AllowedPayments'));
// import AdvancedPaymentConfirmation from './AdvancedPayment/PaymentConfirmation';
const AdvancedPaymentConfirmation = React.lazy(() => import('./AdvancedPayment/PaymentConfirmation'));
// import PaymentPage from './AdvancedPayment/PaymentPage';
const PaymentPage = React.lazy(() => import('./AdvancedPayment/PaymentPage'));
// import Footer from './Footer';
const Footer = React.lazy(() => import('./Footer'));

const MyOrders = React.lazy(() => import('./MyAccount/MyOrders'));
const MBEOrders = React.lazy(() => import('./MyAccount/MBEOrders'));
const OrderSummary = React.lazy(() => import('./MyAccount/OrderSummary'));
const MBEOrderSummary = React.lazy(() => import('./MyAccount/MBEOrderSummary'));
const SuperInvoice = React.lazy(() => import('./MyAccount/SuperInvoice'));
const WalletHistory = React.lazy(() => import('./MyAccount/WalletHistory'));
const InvoiceHistory = React.lazy(() => import('./MyAccount/InvoiceHistory'));
const TradeOffers = React.lazy(() => import('./MyAccount/TradeOffers'));
const MyCommissions = React.lazy(() => import('./MyAccount/Commissions'));
const DownloadCentre = React.lazy(() => import('./MyAccount/DownloadCentre'));
const Promotions = React.lazy(() => import('./MyAccount/Promotions'));
const DocumentRepository = React.lazy(() => import('./MyAccount/DocumentRepository'));
const PostPay = React.lazy(() => import('./Postpay'));

import { Page } from '../../Page';
// import KpiReports from './KpiReports';
const KpiReports = React.lazy(() => import('./KpiReports'));
// import ContractStatus from './Postpay/ContractStatus';
const ContractStatus = React.lazy(() => import('./Postpay/ContractStatus'));
// import PortInResubmit from './MyAccount/PortInResubmit';
const PortInResubmit = React.lazy(() => import('./MyAccount/PortInResubmit'));
// import SimRegistrationAssessment from './SimRegistrationAssessment';
const SimRegistrationAssessment = React.lazy(() => import('./SimRegistrationAssessment'));
// import { UserProfile } from './User';
const UserProfile = React.lazy(() => import('./User/MyProfile'));
// import SigaSimRegistrationContainer from './SigaSimRegistration';
const SigaSimRegistrationContainer = React.lazy(() => import('./SigaSimRegistration'));
// import Faq from './Faq';
const Faq = React.lazy(() => import('./Faq'));
// import SimRegistration from './SimRegistration';
const SimRegistration = React.lazy(() => import('./SimRegistration'));
// import EmployeeProfile from './MyAccount/Employees/EmployeeProfile';
const EmployeeProfile = React.lazy(() => import('./MyAccount/Employees/EmployeeProfile'));
// import Employee from './MyAccount/Employees';
const Employee = React.lazy(() => import('./MyAccount/Employees'));
// import EmployeeCreate from './MyAccount/Employees/CreateEmployee';
const EmployeeCreate = React.lazy(() => import('./MyAccount/Employees/CreateEmployee'));
// import EmployeeProfile from './MyAccount/Employees/EmployeeProfile';
// import EmployeeCreateResult from './MyAccount/Employees/EmployeeCreateSuccess';
const EmployeeCreateResult = React.lazy(() => import('./MyAccount/Employees/EmployeeCreateSuccess'));
// import PrepaidPortal from './PrepaidPortal';
const PrepaidPortal = React.lazy(() => import('./PrepaidPortal'));
// import Migration from './Migration';
// import SimOnly from './SimOnly';
const SimOnly = React.lazy(() => import('./SimOnly'));
const Migration = React.lazy(() => import('./Migration'));
// import BundleInitiationHistory from './SimOnly/BundleInitiationHistory';
const BundleInitiationHistory = React.lazy(() => import('./SimOnly/BundleInitiationHistory'));
import { Navigate, Routes, useLocation } from 'react-router';
import { PageName } from '@src/dtos/enums';

const validationPages = {
  [PageName.wallet]: '/wallet',
  [PageName.myOrders]: '/order/myorders',
  [PageName.superInvoice]: '/superinvoice',
  [PageName.invoiceHistory]: '/order/invoiceHistory',
  [PageName.commission]: '/commission',
  [PageName.tradeOffers]: '/offers',
  [PageName.downloadcentre]: '/downloadcentre',
  [PageName.postPayPort]: '/PostPayPortIn',
  [PageName.kpiReport]: 'performancereports',
  [PageName.portInResubmit]: '/PostPayPortIn/Resubmission',
  [PageName.simRegistrationAssessment]: '/SimRegistrationAssessment',
  [PageName.faq]: '/faq',
  [PageName.sigaSimReg]: '/SimRegistration/SigaRegistration',
  [PageName.simReg]: '/SimRegistration',
  [PageName.simRegistrationV2]: '/SimRegistrationV2',
  [PageName.employee]: '/employee/viewprofiles',
  [PageName.createEmployee]: '/employee/create',
  [PageName.employeeProfile]: 'Employee/EmployeeProfile',
  [PageName.prepaidPortal]: '/PrepaidPortal',
  [PageName.migration]: '/migration',
  [PageName.documentRepository]: '/documentRepository',
  [PageName.simonly]: '/simonly',
  [PageName.bundleinitiationhistory]: '/simonly/bundleinitiationhistory'
}

function Home() {
  const location = useLocation()
  const localization = useSelector((state: RootState) => state.localization)
  const wallet = useSelector((state: RootState) => state.wallet)
  const user = useSelector((state: RootState) => state.user)

  const [activeMenu, setActiveMenu] = React.useState(false)
  const oldLocation = useRef<string>()

  useEffect(() => {
    if (oldLocation.current !== location?.pathname) {
      setActiveMenu(false)
    }
    oldLocation.current = location?.pathname
  }, [location?.pathname])
  return (
    <div className={styles.appContainer} style={{ overflow: activeMenu ? "hidden" : "" }}>      
        <Loading inProgress={localization.lngLoading} className={styles.loginSpinner}>
        <Header activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        <div className={styles.bodyRow} style={{ visibility: !activeMenu ? 'visible' : 'hidden' }}>

          <Routes>
            <Route path="/transactionhistory" element={<Page title={PageName.transactionhistory}><TransactionHistory /></Page>} />
            <Route path="/userProfile" element={<Page title={PageName.userProfile}><UserProfile /></Page>} />
            <Route path="/Performancereports" element={<Page isValidate path={validationPages[PageName.kpiReport]} title={PageName.kpiReport}><KpiReports /></Page>} />
            <Route path="/printepinhistory" element={<Page title={PageName.printHistory}><BuyAndPrintHistory /></Page>} />
            <Route path="/order/myorders" element={<Page isValidate path={validationPages[PageName.myOrders]} title={PageName.myOrders}><MyOrders /></Page>} />
            <Route path="/order/mbeorders" element={<Page isValidate path={validationPages[PageName.myOrders]} title={PageName.myOrders}><MBEOrders /></Page>} />
            <Route path="/order/ordersummary/:orderNumber" element={<Page isValidate path={validationPages[PageName.myOrders]} title={PageName.orderSummary}><OrderSummary /></Page>} />
            <Route path="/order/mbeordersummary/:orderNumber" element={<Page isValidate path={validationPages[PageName.myOrders]} title={PageName.orderSummary}><MBEOrderSummary /></Page>} />
            <Route path="/commission" element={<Page isValidate path={validationPages[PageName.commission]} title={PageName.commission}><MyCommissions /></Page>} />
            <Route path="/wallet" element={<Page isValidate path={validationPages[PageName.wallet]} title={PageName.wallet}><WalletHistory /></Page>} />
            <Route path="/superinvoice" element={<Page isValidate path={validationPages[PageName.superInvoice]} title={PageName.superInvoice}><SuperInvoice /></Page>} />
            <Route path="/order/invoiceHistory" element={<Page isValidate path={validationPages[PageName.invoiceHistory]} title={PageName.invoiceHistory}><InvoiceHistory /></Page>} />
            <Route path="/offers" element={<Page isValidate path={validationPages[PageName.tradeOffers]} title={PageName.tradeOffers}><TradeOffers /></Page>} />
            <Route path="/PostPayPortIn" element={<Page isValidate path={validationPages[PageName.postPayPort]} title={PageName.postPayPort}><PostPay /></Page>} />
            <Route path="/PostPayPortIn/Resubmission" element={<Page isValidate path={validationPages[PageName.portInResubmit]} title={PageName.portInResubmit}><PortInResubmit /></Page>} />
            <Route path="/PostPayPortIn/contractstatus" element={<Page isValidate path={validationPages[PageName.postPayPort]} title={PageName.postPayPort}><ContractStatus /></Page>} />
            <Route path="/downloadcentre" element={<Page isValidate path={validationPages[PageName.downloadcentre]} title={PageName.downloadcentre}><DownloadCentre /></Page>} />
            <Route path="/SimRegistrationAssessment" element={<Page isValidate path={validationPages[PageName.simRegistrationAssessment]} title={PageName.simRegistrationAssessment}><SimRegistrationAssessment /></Page>} />
            <Route path="/SimRegistration/SigaRegistration" element={<Page isValidate path={validationPages[PageName.sigaSimReg]} title={PageName.sigaSimReg}><SigaSimRegistrationContainer /></Page>} />
            <Route path="/SimRegistration" element={<Page isValidate path={validationPages[PageName.simReg]} title={PageName.simReg}><SigaSimRegistrationContainer /></Page>} />
            <Route path="/promotions" element={<Page title={PageName.promotions}><Promotions /></Page>} />
            <Route path="/faq" element={<Page isValidate path={validationPages[PageName.faq]} title={PageName.faq}><Faq /></Page>} />
            <Route path="/SimRegistrationV2" element={<Page isValidate path={validationPages[PageName.simRegistrationV2]} title={PageName.simRegistrationV2}><SimRegistration /></Page>} />
            <Route path="/employee/viewprofiles" element={<Page isValidate path={validationPages[PageName.employee]} title={PageName.employee}><Employee /></Page>} />
            <Route path="/employee/create" element={<Page isValidate path={validationPages[PageName.employee]} title={PageName.employee}><EmployeeCreate /></Page>} />
            <Route path="/employee/success" element={<Page isValidate path={validationPages[PageName.employee]} title={PageName.employee}><EmployeeCreateResult /></Page>} />
            <Route path="/Employee/EmployeeProfile" element={<Page isValidate path={validationPages[PageName.employee]} title={PageName.employee}><EmployeeProfile /></Page>} />
            <Route path="/PrepaidPortal" element={<Page isValidate path={validationPages[PageName.prepaidPortal]} title={PageName.prepaidPortal}><PrepaidPortal /></Page>} />
            <Route path="/simonly" element={<Page isValidate path={validationPages[PageName.simonly]} title={PageName.simonly}><SimOnly /></Page>} />
            <Route path="/simonly/bundleinitiationhistory" element={<Page isValidate path={validationPages[PageName.bundleinitiationhistory]} title={PageName.bundleinitiationhistory}><BundleInitiationHistory /></Page>} />
            <Route path="/migration" element={<Page isValidate path={validationPages[PageName.migration]} title={PageName.migration}><Migration /></Page>} />
            <Route path="/documentRepository" element={<Page isValidate path={validationPages[PageName.documentRepository]} title={PageName.documentRepository}><DocumentRepository /></Page>} />
            {/* <Route path="/dashboard" element={<Page title={PageName.dashboard}><Dashboard /></Page>} /> */}

            <Route path="/" element={
              <div className={styles.bodyRowContainer}>
                <Box sx={{ display: { sm: 'block', xs: 'none' } }} className={styles.leftSide}>
                  <div className={styles.leftSideFixed}>
                    {(wallet.walletData || wallet.fetchInProgress) && (user.userData.permissions?.includes('walletstatmentv2') || user.userData.permissions?.includes('employeewallet')) && <ClientInfoPanel />}
                    <DashboardTiles />
                    <AllowedPayments />
                  </div>
                </Box>
                <div className={styles.rightSide}>
                  <Outlet />
                </div>
              </div>
            }>
              <Route path="/dashboard" element={<Page title={PageName.dashboard}><Dashboard /></Page>} />
              <Route path="/advancedpayment" element={<Page title={PageName.advancePayment}><AdvancedPayment /></Page>} />
              <Route path="/walletpayment" element={wallet.selectedDenomination !== undefined ? (<Page title={PageName.advancePayment}><PaymentPage /></Page>) : (<Navigate to="/advancedpayment" />)}>
              </Route>
              <Route path="/cart" element={<Page title={PageName.cart}><Cart /></Page>} />
              <Route path="/cart" element={<Page title={PageName.cart}><Cart /></Page>} />
              <Route path="/reorder/:orderNumber" element={<Page title={PageName.cart}><Cart /></Page>} />
              <Route path="/paymentmethod" element={<Page title={PageName.orderConfirmation}><OrderConfirmation /></Page>} />
              <Route path="/mutliepinpaymentmethod" element={<Page title={PageName.orderConfirmation}><MultiEpinOrderConfirmation /></Page>} />
              <Route path="/ordercomplete/:orderNumber/:paymentMethod/:status" element={<Page title={PageName.orderSummary}><OrderComplete /></Page>} />
              <Route path="/ordercomplete/:orderNumber/:paymentMethod" element={<Page title={PageName.orderSummary}><OrderComplete /></Page>} />
              <Route path="/printcomplete/:orderNumber/:paymentMethod/failure" element={<Page title={PageName.orderSummary}><OrderComplete /></Page>} />
              <Route path="/creditcomplete/:transactionId/online/:status" element={<Page title={PageName.orderConfirmation}><AdvancedPaymentConfirmation /></Page>} />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Route>
          </Routes>

          {/* <div className={styles.bodyRowContainer}>
              <Paper sx={{ display: { sm: 'none' } }}>
                <div className={styles.leftSide}>
                  <div className={styles.leftSideFixed}>
                    {(wallet.walletData || wallet.fetchInProgress) && (user.userData.permissions?.includes('walletstatmentv2') || user.userData.permissions?.includes('employeewallet')) && <ClientInfoPanel />}
                    <DashboardTiles />
                    <AllowedPayments />
                  </div>
                </div>
              </Paper>
              <div className={styles.rightSide}>
                
              </div>
            </div> */}


        </div>
      </Loading>
      <Footer />
      <AddToCartErrorDialog />
    </div>
  );
}

export default Home
