import { IBuyAndPrintService } from "@src/services/BuyAndPrintService";
import { IPrintHistoryService } from "@src/services/PrintHistoryService";
import { delay, repeat, takeWhile, filter } from 'rxjs/operators';
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { replace } from "redux-first-history";
import StorageService from "@src/services/StorageService";
import { trackWalletDebit } from "../analyticsTracking";
import { ActionType, EpinNumberType } from "@src/dtos/enums";

const RetryFetchCount = 8

const initActions = function (buyAndPrintService: IBuyAndPrintService, printHistoryService: IPrintHistoryService) {

  const setQuantity = (dispatch: AppDispatch) => (productId: string, qty: number, price: number = 0, name: string = '', type: string = '', tax: number | undefined = undefined, isSPV: boolean = false, category: string = '') => {
    dispatch({ type: ActionType.setBuyPrintQuantity, data: { qty, productId, name, type, price, tax, isSPV, category } })
  }

  const setInStockQuantity = (dispatch: AppDispatch) => (productId: string, qty: number, price: number, name: string, type: string, isSPV: boolean, category: string = '') => {
    dispatch({ type: ActionType.setBuyPrintInStockQuantity, data: { qty, productId, name, type, price, isSPV, category } })
  }

  const cleanQuantity = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.cleanQtys })
  }

  const fetchInStockInfo = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const qtys = { ...getState().buyAndPrint.qtys }
    for (let key in qtys) {
      if (qtys[key].category === 'Topup') {
        delete qtys[key]
      }
    }
    const lenQtys = Object.values<any>(qtys).reduce((prev, curr) => prev + curr.qty, 0)
    const maxQty = Object.values<any>(qtys).reduce((prev, curr) => prev > curr.qty ? prev : curr.qty, 0)
    const maxQtys = getState().user.countryConfiguration.MultiEpinPrintAllowedQty
    const limitQty = getState().user.countryConfiguration.PerProductPrintAllowedQty
    if (lenQtys <= maxQtys && maxQty <= limitQty) {
      const total = Object.values<any>(qtys).reduce((sum, value) => sum + value.qty * (value.tax ? (value.price + value.tax) : value.price), 0)
      const request = {
        MultiPrintRequestModel: Object.entries<any>(qtys).map(([key, value]) => {
          return {
            ProductId: key,
            Quantity: value.qty,
            Name: value.name,
            Type: value.type
          }
        }),
        Locale: i18n.language,
        IsPrint: true,
        CommissionAmount: total < getState().wallet.walletData.balance ? total : getState().wallet.walletData.balance,
        OnlineAmount: total > getState().wallet.walletData.balance ? (total * 100 - getState().wallet.walletData.balance * 100) / 100 : 0
      }
      dispatch({ type: ActionType.buyPrintInStockRequest, data: request })
      buyAndPrintService.fetchInStockInfo(request).subscribe((res => {
        if (!res?.isStockAvailable) {
          dispatch({ type: ActionType.buyPrintInStockResponse, data: { res } })
        } else {
          buyAndPrintService.fetchWalletInfo(total).subscribe((amountRes => {
            let correctedRes: any = { res, correction: {} }
            correctedRes.correction.OnlineAmount = amountRes.OnlineAmount
            correctedRes.correction.CommissionAmount = amountRes.CommissionAmount
            correctedRes.correction.CreditAmount = amountRes.CreditAmount
            correctedRes.correction.CommissionMismatch = amountRes.CommissionMismatch
            correctedRes.correction.ErrorMessage = amountRes.ErrorMessage
            correctedRes.correction.RedirectToOnlinePayment = amountRes.RedirectToOnlinePayment
            correctedRes.correction.Status = amountRes.Status
            dispatch({ type: ActionType.buyPrintInStockResponse, data: correctedRes })
          }))
        }
      }))
    } else {
      if (maxQty > limitQty) {
        const res = { ErrorDescription: 'multiprint-instockperproductmsg', maxQtys: limitQty, ErrorDescription1: 'mep-instockperproductmsg1' }
        dispatch({ type: ActionType.buyPrintInStockResponse, data: { res } })
      } else {
        const res = { ErrorDescription: 'multiprint-instockoverallqtymsg', maxQtys: maxQtys }
        dispatch({ type: ActionType.buyPrintInStockResponse, data: { res } })
      }
    }
  }

  const fetchInStockVoucherInfo = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const qtys = { ...getState().buyAndPrint.qtys }
    for (let key in qtys) {
      if (qtys[key].category !== 'Topup') {
        delete qtys[key]
      }
    }
    const lenQtys = Object.values<any>(qtys).reduce((prev, curr) => prev + curr.qty, 0)
    const maxQty = Object.values<any>(qtys).reduce((prev, curr) => prev > curr.qty ? prev : curr.qty, 0)
    const maxQtys = getState().user.countryConfiguration.MultiEpinPrintAllowedQty
    const limitQty = getState().user.countryConfiguration.PerProductPrintAllowedQty
    if (lenQtys <= maxQtys && maxQty <= limitQty) {
      const total = Object.values<any>(qtys).reduce((sum, value) => sum + value.qty * (value.tax ? (value.price + value.tax) : value.price), 0)
      const request = {
        MultiPrintRequestModel: Object.entries<any>(qtys).map(([key, value]) => {
          return {
            ProductId: key,
            Quantity: value.qty,
            Name: value.name,
            Type: value.type
          }
        }),
        Locale: i18n.language,
        IsPrint: true,
        CommissionAmount: total < getState().wallet.walletData.balance ? total : getState().wallet.walletData.balance,
        OnlineAmount: total > getState().wallet.walletData.balance ? (total * 100 - getState().wallet.walletData.balance * 100) / 100 : 0
      }
      dispatch({ type: ActionType.buyPrintVoucherInStockRequest, data: request })
      buyAndPrintService.fetchWalletInfo(total).subscribe((amountRes => {
        if(!!amountRes) {
          let correctedRes: any = {
            res: {
              isStockAvailable: true,
              stockAvailability: [],
            },
            correction: {}
          }
          correctedRes.correction.OnlineAmount = amountRes.OnlineAmount
          correctedRes.correction.CommissionAmount = amountRes.CommissionAmount
          correctedRes.correction.CreditAmount = amountRes.CreditAmount
          correctedRes.correction.CommissionMismatch = amountRes.CommissionMismatch
          correctedRes.correction.ErrorMessage = amountRes.ErrorMessage
          correctedRes.correction.RedirectToOnlinePayment = amountRes.RedirectToOnlinePayment
          correctedRes.correction.Status = amountRes.Status
          dispatch({ type: ActionType.buyPrintVoucherInStockResponse, data: correctedRes })
        } else {
          dispatch({ type: ActionType.buyPrintVoucherInStockResponse, data: {
            res: { ErrorDescription: 'topup-epinserror' }
          } })
        }        
      }))
    } else {
      if (maxQty > limitQty) {
        const res = { ErrorDescription: 'multiprint-instockperproductmsg', maxQtys: limitQty, ErrorDescription1: 'mep-instockperproductmsg1' }
        dispatch({ type: ActionType.buyPrintVoucherInStockResponse, data: { res } })
      } else {
        const res = { ErrorDescription: 'multiprint-instockoverallqtymsg', maxQtys: maxQtys }
        dispatch({ type: ActionType.buyPrintVoucherInStockResponse, data: { res } })
      }
    }
  }

  const confirmInStockBuyAndPrint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const qtys = { ...getState().buyAndPrint.inStockQtys }
    for (let key in qtys) {
      if (qtys[key].category === 'Topup') {
        delete qtys[key]
      }
    }
    const lenQtys = Object.values<any>(qtys).reduce((prev, curr) => prev + curr.qty, 0)
    const maxQty = Object.values<any>(qtys).reduce((prev, curr) => prev > curr.qty ? prev : curr.qty, 0)
    const maxQtys = getState().user.countryConfiguration.MultiEpinPrintAllowedQty
    const limitQty = getState().user.countryConfiguration.PerProductPrintAllowedQty
    if (lenQtys <= maxQtys && maxQty <= limitQty) {
      const request = {
        MultiPrintRequestModel: Object.entries<any>(qtys).map(([key, value]) => {
          return {
            ProductId: key,
            Quantity: value.qty,
            Name: value.name,
            Type: value.type
          }
        }),
        Locale: i18n.language,
        IsPrint: true,
        CommissionAmount: 0,
        OnlineAmount: 0
      }
      dispatch({ type: ActionType.inStockBuyPrintConfirm, data: { request, type: 'epin' } })
    } else {
      if (maxQty > limitQty) {
        dispatch({ type: ActionType.inStockBuyPrintResult, data: { ErrorDescription: 'multiprint-instockperproductmsg', maxQtys: limitQty, ErrorDescription1: 'mep-instockperproductmsg1' } })
      } else {
        dispatch({ type: ActionType.inStockBuyPrintResult, data: { ErrorDescription: 'multiprint-instockoverallqtymsg', maxQtys: maxQtys } })
      }
    }
  }

  const confirmInStockBuyAndPrintVoucher = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const qtys = { ...getState().buyAndPrint.inStockQtys }
    for (let key in qtys) {
      if (qtys[key].category !== 'Topup') {
        delete qtys[key]
      }
    }
    const lenQtys = Object.values<any>(qtys).reduce((prev, curr) => prev + curr.qty, 0)
    const maxQty = Object.values<any>(qtys).reduce((prev, curr) => prev > curr.qty ? prev : curr.qty, 0)
    const maxQtys = getState().user.countryConfiguration.MultiEpinPrintAllowedQty
    const limitQty = getState().user.countryConfiguration.PerProductPrintAllowedQty
    if (lenQtys <= maxQtys && maxQty <= limitQty) {
      const request = {
        MultiPrintRequestModel: Object.entries<any>(qtys).map(([key, value]) => {
          return {
            ProductId: key,
            Quantity: value.qty,
            Name: value.name,
            Type: value.type
          }
        }),
        Locale: i18n.language,
        IsPrint: true,
        CommissionAmount: 0,
        OnlineAmount: 0
      }
      dispatch({ type: ActionType.inStockBuyPrintConfirm, data: { request, type: 'digitalpin' } })
    } else {
      if (maxQty > limitQty) {
        dispatch({ type: ActionType.inStockBuyPrintVoucherResult, data: { ErrorDescription: 'multiprint-instockperproductmsg', maxQtys: limitQty, ErrorDescription1: 'mep-instockperproductmsg1' } })
      } else {
        dispatch({ type: ActionType.inStockBuyPrintVoucherResult, data: { ErrorDescription: 'multiprint-instockoverallqtymsg', maxQtys: maxQtys } })
      }
    }
  }

  const cancelInStockBuyAndPrint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    dispatch({ type: ActionType.inStockBuyPrintCancel })
  }

  const inStockBuyAndPrint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const request = { ...getState().buyAndPrint.inStockRequestModel.request }
    dispatch({ type: ActionType.inStockBuyPrintCancel })
    dispatch({ type: ActionType.inStockBuyPrintInProgress, data: request })
    buyAndPrintService.buyAndPrintInstock(request).subscribe((res => {
      dispatch({ type: ActionType.inStockBuyPrintResult, data: res ? res : { ErrorDescription: "topup-epinserror" } })
    }))
  }

  const inStockVoucherBuyAndPrint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const request = { ...getState().buyAndPrint.inStockRequestModel.request }
    dispatch({ type: ActionType.inStockBuyPrintCancel })
    dispatch({ type: ActionType.inStockBuyPrintVoucherInProgress, data: request })
    buyAndPrintService.buyAndPrintInstock(request).subscribe((res => {
      dispatch({ type: ActionType.inStockBuyPrintVoucherResult, data: res ? res : { ErrorDescription: "topup-epinserror" } })
    }))
  }

  const inStockBuyAndPrintReprint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const inStockBuyPrintRequestResult = getState().buyAndPrint.inStockBuyPrintRequestResult
    const request = inStockBuyPrintRequestResult.RePrintEpins
    dispatch({ type: ActionType.inStockBuyPrintInProgressReprint, data: request })
    buyAndPrintService.buyAndPrintStockReprint(request).subscribe((res => {
      dispatch({ type: ActionType.inStockBuyPrintResult, data: { ...inStockBuyPrintRequestResult, RePrintEpins: res } })
    }))
  }

  const inStockBuyAndPrintVoucherReprint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const inStockBuyPrintVoucherRequestResult = getState().buyAndPrint.inStockBuyPrintVoucherRequestResult
    const request = inStockBuyPrintVoucherRequestResult.RePrintEpins
    dispatch({ type: ActionType.inStockBuyPrintVoucherInProgressReprint, data: request })
    buyAndPrintService.buyAndPrintStockReprint(request).subscribe((res => {
      dispatch({ type: ActionType.inStockBuyPrintVoucherResult, data: { ...inStockBuyPrintVoucherRequestResult, RePrintEpins: res } })
    }))
  }

  const buyAndPrintReprint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const inStockBuyPrintMiddleRes = getState().buyAndPrint.inStockBuyPrintMiddleRes
    const buyPrintRequestResult = getState().buyAndPrint.buyPrintRequestResult
    const request = mapEpinsSerialNumber(inStockBuyPrintMiddleRes.RePrintEpins, buyPrintRequestResult.MultipleEPinModelList)
    dispatch({ type: ActionType.buyPrintInProgressReprint })
    buyAndPrintService.buyAndPrintStockReprint(request).subscribe((res => {
      dispatch({ type: ActionType.buyPrintMiddleResult, data: { ...inStockBuyPrintMiddleRes, RePrintEpins: res } })
    }))
  }

  const buyAndPrintVoucherReprint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    const inStockBuyPrintVoucherMiddleRes = getState().buyAndPrint.inStockBuyPrintVoucherMiddleRes
    const buyPrintVoucherRequestResult = getState().buyAndPrint.buyPrintVoucherRequestResult
    const request = mapEpinsSerialNumber(inStockBuyPrintVoucherMiddleRes.RePrintEpins, buyPrintVoucherRequestResult.MultipleEPinModelList)
    dispatch({ type: ActionType.buyPrintVoucherInProgressReprint })
    buyAndPrintService.buyAndPrintStockReprint(request).subscribe((res => {
      dispatch({ type: ActionType.buyPrintVoucherMiddleResult, data: { ...inStockBuyPrintVoucherMiddleRes, RePrintEpins: res } })
    }))
  }

  const mapEpinsSerialNumber = (rePrintEpins: any[], popupData: any[]) => {
    const popupDataNameToSerial: any = {}
    popupData.forEach((val: any) => {
      if (!popupDataNameToSerial[val.ItemName]) {
        popupDataNameToSerial[val.ItemName] = []
      }
      popupDataNameToSerial[val.ItemName].push(val.SerialNumber)
    })
    const rePrintEpinsCopy: any[] = []
    rePrintEpins.forEach((val: any) => {
      for (let i = 0; i < val.SelectedQuantity; i++) {
        rePrintEpinsCopy.push({ ...val, SelectedQuantity: 1 })
      }
    })
    return rePrintEpinsCopy.map((val: any) => {
      const valCopy = { ...val }
      valCopy.SerialNumber = popupDataNameToSerial[val.ProductName]?.pop()
      return valCopy
    })
  }

  const buyAndPrint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    dispatch({ type: ActionType.buyPrintInProgress })
    buyAndPrintService.buyAndPrint(getState().buyAndPrint.inStockFetchInProgress).subscribe(res => {
      if (!res) {
        dispatch({ type: ActionType.buyPrintError, data: { ErrorMessage: "topup-epinserror" } })
        return
      }
      dispatch({ type: ActionType.buyPrintMiddleResult, data: res })

      const fetchResult = (timeToRepeat: number) => {
        setTimeout(() => {
          printHistoryService.fetchPrintOutInfo(EpinNumberType.orderNumber, res.OrderNumber).subscribe(details => {
            if (details?.MultipleEPinModelList || (timeToRepeat <= 1)) {
              if (details?.MultipleEPinModelList) {
                dispatch({ type: ActionType.buyPrintResult, data: details })
              } else {
                dispatch({ type: ActionType.buyPrintError, data: { ErrorMessage: "topup-epinserror" } })
                return
              }
            } else {
              fetchResult(timeToRepeat - 1)
            }
          })
        }, 2000)
      }
      if (res.OnlineAmount) {
        buyAndPrintService.buyAndPrintPayment(res.OrderNumber, res.OnlineAmount, i18n.language).subscribe(res => {
          if (res.Status) {
            const state = getState().buyAndPrint
            window.localStorage.setItem("buyAndPrintTransaction", JSON.stringify({
              inStockFetchInProgress: state.inStockFetchInProgress,
              inStockRequestResult: state.inStockRequestResult,
              inStockBuyPrintMiddleRes: state.inStockBuyPrintMiddleRes,
              qtys: state.qtys
            }))
            dispatch({ type: ActionType.buyMultiEpinPrintResult, data: res });
          } else {
            dispatch({ type: ActionType.buyPrintError, data: { ErrorMessage: "topup-epinserror" } })
            return
          }
        })
      }
      else {
        const { selectedCountry, userData } = getState().user
        trackWalletDebit(userData.AXCode[0], selectedCountry as string)
        fetchResult(RetryFetchCount)
      }
    })

  }

  const fetchPrintVoucherStatus = (orderNumber: string, lang: string) => {
    return printHistoryService.fetchPrintOutInfo(EpinNumberType.orderNumber, orderNumber, true, lang).pipe(
      delay(2000),
      repeat(RetryFetchCount),
      filter((res: any) => res.MultipleEPinModelList !== null),
      takeWhile(((res: any) => res.MultipleEPinModelList === null), true)
    )
  }

  const buyAndPrintVoucher = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    dispatch({ type: ActionType.buyPrintVoucherInProgress })
    buyAndPrintService.buyAndPrintVoucher(getState().buyAndPrint.inStockVoucherFetchInProgress).subscribe(res => {
      if (!res) {
        dispatch({ type: ActionType.buyPrintVoucherError, data: { ErrorMessage: "topup-epinserror" } })
        return
      }
      dispatch({ type: ActionType.buyPrintVoucherMiddleResult, data: res })

      let printSuccess = false

      if (res.OnlineAmount) {
        buyAndPrintService.buyAndPrintPayment(res.OrderNumber, res.OnlineAmount, i18n.language).subscribe(res => {
          if (res.Status) {
            const state = getState().buyAndPrint
            window.localStorage.setItem("buyAndPrintVoucherTransaction", JSON.stringify({
              inStockVoucherFetchInProgress: state.inStockVoucherFetchInProgress,
              inStockVoucherRequestResult: state.inStockVoucherRequestResult,
              inStockBuyPrintVoucherMiddleRes: state.inStockBuyPrintVoucherMiddleRes,
              qtys: state.qtys
            }))
            dispatch({ type: ActionType.buyMultiDigitalpinPrintResult, data: res });
          } else {
            dispatch({ type: ActionType.buyPrintVoucherError, data: { ErrorMessage: "topup-epinserror" } })
            return
          }
        })
      }
      else {
        const { selectedCountry, userData } = getState().user
        trackWalletDebit(userData.AXCode[0], selectedCountry as string)
        fetchPrintVoucherStatus(res.OrderNumber, i18n.language).subscribe({
          next: details => {
            printSuccess = true
            dispatch({ type: ActionType.buyPrintVoucherResult, data: details })                          
          },
          complete: () => {
            if (!printSuccess) {
              dispatch({ type: ActionType.buyPrintVoucherError, data: { ErrorMessage: "digitalpin-status-delay-error", alert: true } })
              printHistoryService.updateOrderStatus(res.OrderNumber).subscribe(data => console.log('updateOrderStatusSuccess', data))
            }
          }
        })
      }
    })

  }

  const restoreBuyTransaction = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderData: any) => {
    dispatch({ type: ActionType.buyPrintRestore, data: orderData })
    setTimeout(() => dispatch(replace('/dashboard')), 10)
    const fetchResult = (timeToRepeat: number) => {
      setTimeout(() => {
        printHistoryService.fetchPrintOutInfo(EpinNumberType.orderNumber, orderData.inStockBuyPrintMiddleRes.OrderNumber).subscribe(details => {
          if (details.MultipleEPinModelList || (timeToRepeat <= 1)) {
            dispatch({ type: ActionType.buyPrintResult, data: details })
          } else {
            fetchResult(timeToRepeat - 1)
          }
        })
      }, 2000)
    }

    fetchResult(RetryFetchCount)
  }

  const restoreBuyVoucherTransaction = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderData: any) => {
    dispatch({ type: ActionType.buyPrintVoucherRestore, data: orderData })
    let printSuccess = false
    setTimeout(() => dispatch(replace('/dashboard')), 10)
    
    fetchPrintVoucherStatus(orderData.inStockBuyPrintVoucherMiddleRes.OrderNumber, i18n.language).subscribe({
      next: details => {
        printSuccess = true
        dispatch({ type: ActionType.buyPrintVoucherResult, data: details })                          
      },
      complete: () => {
        if (!printSuccess) {
          dispatch({ type: ActionType.buyPrintVoucherError, data: { ErrorMessage: "digitalpin-status-delay-error", alert: true } })
          printHistoryService.updateOrderStatus(orderData.inStockBuyPrintVoucherMiddleRes.OrderNumber).subscribe(data => console.log('updateOrderStatusSuccess', data))
        }
      }
    })
  }

  const finishBuyAndPrintTransaction = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.finishBuyAndPrintTransaction })
  }

  const finishInStockBuyAndPrintTransaction = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.finishInStockBuyAndPrintTransaction })
  }

  const finishInStockBuyAndPrintVoucherTransaction = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.finishInStockBuyAndPrintVoucherTransaction })
  }

  const finishBuyAndPrintVoucherTransaction = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.finishBuyAndPrintVoucherTransaction })
  }

  const adyenBuyAndPrint = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (orderNumber: any, onlineAmount: any) => {
    dispatch({ type: ActionType.buyPrintInProgress })
    StorageService().remove('adyenReloadData')
    buyAndPrintService.buyAndPrintPayment(orderNumber, onlineAmount, i18n.language).subscribe(res => {
      dispatch({ type: ActionType.buyMultiEpinPrintResult, data: res });
    })
  }

  return {
    setQuantity,
    fetchInStockInfo,
    fetchInStockVoucherInfo,
    finishBuyAndPrintTransaction,
    finishBuyAndPrintVoucherTransaction,
    buyAndPrint,
    buyAndPrintVoucher,
    inStockBuyAndPrint,
    inStockVoucherBuyAndPrint,
    inStockBuyAndPrintReprint,
    inStockBuyAndPrintVoucherReprint,
    setInStockQuantity,
    finishInStockBuyAndPrintTransaction,
    finishInStockBuyAndPrintVoucherTransaction,
    cleanQuantity,
    buyAndPrintReprint,
    buyAndPrintVoucherReprint,
    restoreBuyTransaction,
    restoreBuyVoucherTransaction,
    adyenBuyAndPrint,
    cancelInStockBuyAndPrint,
    confirmInStockBuyAndPrint,
    confirmInStockBuyAndPrintVoucher
  };
};

export default initActions;