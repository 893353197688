import { Questionnaire } from ".";
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";

const INITIAL_STATE = {
    tdFile: '',
    isFetchingQuestions: false,
    isFetchingTDFile: false,
    isFetchingFlag: false,
    isSubmittingResult: false,
    questions: undefined as undefined | { QuestionnaireId: string; Questionnaire: Questionnaire[] },
    sessionId: undefined as string | undefined,
    result: {} as any,
    logAnswers: {} as any,
    otherFlags: {} as any,
}

export type SimRegAssessmentState = typeof INITIAL_STATE

const initReducer = function () {

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.SimRegAssessmentTDFileRequest] = (state: SimRegAssessmentState): SimRegAssessmentState => {
        return {
            ...state,
            isFetchingTDFile: true
        }
    }
    reducersByActions[ActionType.SimRegAssessmentTDFileResponse] = (state: SimRegAssessmentState, actionData: any): SimRegAssessmentState => {
        return {
            ...state,
            isFetchingTDFile: false,
            tdFile: actionData
        }
    }
    reducersByActions[ActionType.SimRegAssessmentQuestionRequest] = (state: SimRegAssessmentState): SimRegAssessmentState => {
        return {
            ...state,
            isFetchingQuestions: true
        }
    }

    reducersByActions[ActionType.SimRegAssessmentQuestionResponse] = (state: SimRegAssessmentState, actionData: any): SimRegAssessmentState => {
        return {
            ...state,
            isFetchingQuestions: false,
            sessionId: actionData.sessionId,
            questions: actionData.questions
        }
    }
    reducersByActions[ActionType.SimRegAssessmentResultRequest] = (state: SimRegAssessmentState): SimRegAssessmentState => {
        return {
            ...state,
            isSubmittingResult: true
        }
    }
    reducersByActions[ActionType.SimRegAssessmentResultResponse] = (state: SimRegAssessmentState, actionData: any): SimRegAssessmentState => {
        return {
            ...state,
            isSubmittingResult: false,
            result: actionData
        }
    }
    reducersByActions[ActionType.SimRegAssessmentlogResponse] = (state: SimRegAssessmentState, actionData: any): SimRegAssessmentState => {
        return {
            ...state,
            logAnswers: !actionData?.IsError ? {
                ...state?.logAnswers,
                [actionData?.questionId]: [...actionData?.answers]
            } : { ...actionData?.logAnswers }
        }
    }
    reducersByActions[ActionType.SimRegAssessmentFlagRequest] = (state: SimRegAssessmentState): SimRegAssessmentState => {
        return {
            ...state,
            isFetchingFlag: true
        }
    }
    reducersByActions[ActionType.SimRegAssessmentFlagResponse] = (state: SimRegAssessmentState, actionData: any): SimRegAssessmentState => {
        return {
            ...state,
            isFetchingFlag: false,
            otherFlags: actionData
        }
    }

    reducersByActions[ActionType.CleanQuestionnaire] = (state: SimRegAssessmentState): SimRegAssessmentState => {
        return {
            ...state,
            questions: undefined,
            sessionId: '',
            result: {},
            logAnswers: {}

        }
    }

    reducersByActions[ActionType.CleanSimRegAssessment] =
        reducersByActions[ActionType.Logout] = (): SimRegAssessmentState => {
            return {
                ...INITIAL_STATE
            }
        }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer;
}

export default initReducer