import moment from "moment";
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { AssignBundleState } from ".";

const INITIAL_STATE: AssignBundleState = {
  fetchInProgress: false,
  history: [],
  isDownloading: false,
  filters: {
    dateFrom: moment().subtract(7, 'days').toDate().toISOString(),
    toDate: moment().toDate().toISOString()
  }
};

const initReducer = function () {    

    let reducersByActions = {
      [ActionType.BundleHistoryRequest]: (state: AssignBundleState, actionData: any): AssignBundleState => {
        return {
          ...state,
          fetchInProgress: true,
          history: [],
          filters: actionData
        };
      },
      [ActionType.BundleHistoryResponse]: (state: AssignBundleState, actionData: any): AssignBundleState => {
        return {
          ...state,
          fetchInProgress: false,
          history: actionData.data,
        };
      },
      [ActionType.BundleHistoryStartDownload]: (state: AssignBundleState, actionData: any): AssignBundleState => {
        return {
          ...state,
          isDownloading: true
        }
      },
      [ActionType.BundleHistoryEndDownload]: (state: AssignBundleState, actionData: any): AssignBundleState => {
        return {
          ...state,
          isDownloading: false
        }
      },
      [ActionType.BundleHistoryClean]: (state: AssignBundleState, actionData: any): AssignBundleState => {
        return INITIAL_STATE
      },  
      [ActionType.Logout]: (state: AssignBundleState, actionData: any): AssignBundleState => {
        return INITIAL_STATE
      }
    } as Partial<ActionReducer>

    
    const reducer = (state = INITIAL_STATE, action: IAction): AssignBundleState => {
      // console.log("state: ", state)
      // console.log("action: ", action)
      return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    
    return reducer;
  };
  
  export default initReducer;