interface IConfig {
    url: {
        API_URL: string;
        URL: string;
    },
    ga: {
        TRACKING_ID: string;
        SITE_SPEED_SAMPLE_RATE: number;
        GTM: string
    }
    captcha: {
        CAPTCHA_MODE: 'classic' | 'enterprise'
    }
}

const prod: IConfig = {
    url: {
        API_URL: '/api/',
        URL: '/'
    },
    ga: {
        /**
         * For Prod (Disable for UAT)
         */
         TRACKING_ID: 'G-23PQTXKQP0',
         SITE_SPEED_SAMPLE_RATE: 50,
         GTM: 'GTM-NP36TW5B'

        /**
         * For UAT (Disable for Prod)
         */
        // TRACKING_ID: 'G-T5M95WVBGH',
        // SITE_SPEED_SAMPLE_RATE: 100,
        // GTM: 'GTM-P39BS7B'
    },
    captcha: {
        CAPTCHA_MODE: 'enterprise'
    }
};

const dev: IConfig = {    
    url: {
        API_URL: '/api/',
        URL: '/'
    },
    ga: {
        TRACKING_ID: 'G-T5M95WVBGH',
        SITE_SPEED_SAMPLE_RATE: 100,
        GTM: 'GTM-P39BS7B'
    },
    captcha: {
        CAPTCHA_MODE: 'enterprise'
    }
};

// const dev: IConfig = {
//     url: {
//         API_URL: 'http://localhost:8920/api/',
//         URL: 'http://localhost:8920/'
//     },
//     ga: {
//         TRACKING_ID: 'G-S667CCL2G5',
//         SITE_SPEED_SAMPLE_RATE: 100,
//         GTM: 'GTM-P39BS7B'
//     },
//     captcha: {
//         CAPTCHA_MODE: 'enterprise'
//     }
// };


export default (process.env.NODE_ENV === 'development' ? dev : prod);