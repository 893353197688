import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { FiltersDto } from "@src/dtos/filtersDto"
import { filterEncodedStr } from "@src/utils"
import { Observable } from "rxjs"
import { BundleInitiationHistoryDto, SimOnlyProductsDto, SimOnlyPurchasePayloadDto } from "@src/dtos/bundleInitiationHistoryDto"

const SimOnlyService = (ajax: ajaxType) => {
    return {
        getSessionId: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/GetSessionId`, {
                'Content-Type': 'text/plain; charset=utf-8',
            }, 'text')
                .pipe(map(res => res.response));
        },
        validateMsisdn: (sessionId: string, msisdn: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ValidateMsisdn/${msisdn}/${sessionId}`)
                .pipe(map(res => res.response));
        },
        fetchProducts: (locale: string): Observable<SimOnlyProductsDto> => {
            return ajax.get(`${config.url.API_URL}SimoPurchase/Product/${locale}`)
                .pipe(map(res => res.response));
        },
        fetchHistory: (filters: FiltersDto): Observable<BundleInitiationHistoryDto> => {            
            return ajax.get(
                `${config.url.API_URL}SimoPurchase/History?${filterEncodedStr(filters)}`
            ).pipe(map(res => res.response));
        },
        purchaseSim: (payload: SimOnlyPurchasePayloadDto, locale: string) => {
            return ajax.post(
                `${config.url.API_URL}SimoPurchase/Initiate/${locale}`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        downloadHistory: (filters: FiltersDto, locale: string) => {
            return ajax.download(`${config.url.API_URL}SimoPurchase/Download/${locale}?${filterEncodedStr(filters)}`,
                "get"
            ).pipe(map(res => res.response))
        }
    }
}

export type ISimOnlyService = ReturnType<typeof SimOnlyService>

export default SimOnlyService;