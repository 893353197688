
import { IPrepaidPortalService } from "@src/services/PrepaidPortal"
import { ActionType } from "@src/dtos/enums";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { IStorageService } from "@src/services/StorageService";
import { PrepaidPortalUserDetails, PrepaidPortalSimDetails } from ".";

const initActions = (prepaidPortalService: IPrepaidPortalService, storageService: IStorageService) => {

    const getSessionId = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.PrepaidPortalSessionRequest })
        prepaidPortalService.getSessionId().subscribe(res => {
            dispatch({ type: ActionType.PrepaidPortalSessionResponse, data: res })
            const { userData, selectedCountry } = getState().user
            if (selectedCountry === 'DE') {
                prepaidPortalService.fetchOperators(res, i18n.language.toLowerCase()).subscribe(res => {
                    dispatch({ type: ActionType.PrepaidPortalNetworkOperatorResponse, data: res || [] })
                })
            }
        })
    }

    const generalGetRequest = (dispatch: AppDispatch) => (url: string) => {
        return prepaidPortalService.generalGetRequest(url)
    }

    const generalPostRequest = (dispatch: AppDispatch) => (url: string, payload: any) => {
        return prepaidPortalService.generalPostRequest(url, payload)
    }

    const generalPutRequest = (dispatch: AppDispatch) => (url: string, payload: any) => {
        return prepaidPortalService.generalPutRequest(url, payload)
    }

    const verifyRioCode = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (msisdn: string, rioCode: string) => {
        dispatch({ type: ActionType.PrepaidPortalVerifyRioCodeRequest })
        prepaidPortalService.verifyRioCode(msisdn, rioCode, i18n.language).subscribe(data => {
            dispatch({ type: ActionType.PrepaidPortalVerifyRioCodeResponse, data })
        })
    }

    const updateValidRioCode = (dispatch: AppDispatch, getState: () => RootState) => (status: Boolean) => {
        dispatch({ type: ActionType.PrepaidPortalUpdateValidRioCode, data: { status: status } })
    }

    const updateRioCode = (dispatch: AppDispatch, getState: () => RootState) => (value: string) => {
        dispatch({ type: ActionType.PrepaidPortalUpdateRioCode, data: { value: value } })
    }

    const updatePage = (dispatch: AppDispatch, getState: () => RootState) => (value: string) => {
        dispatch({ type: ActionType.PrepaidPortalUpdatePage, data: { value: value } })
    }

    const updateUserDetails = (dispatch: AppDispatch) => (data: Partial<PrepaidPortalUserDetails>) => {
        dispatch({ type: ActionType.PrepaidPortalUpdateUserDetails, data: { ...data } })
    }

    const updateSimDetails = (dispatch: AppDispatch) => (data: Partial<PrepaidPortalSimDetails>) => {
        dispatch({ type: ActionType.PrepaidPortalUpdateSimDetails, data: { ...data } })
    }

    const updateErrorType = (dispatch: AppDispatch, getState: () => RootState) => (value: string) => {
        dispatch({ type: ActionType.PrepaidPortalUpdateErrorType, data: { value: value } })
    }

    const validateSim = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (pukCode: string, iccid: string, userCountry: string) => {
        dispatch({ type: ActionType.PreapidPortalValidateSimRequest })

        prepaidPortalService.validateSim(pukCode, iccid, i18n.language).subscribe(data => {
            const { prepaidPortalUserDetails, prepaidPortalSimDetails, rioCode } = getState().prepaidPortal
            const { userData, selectedCountry } = getState().user
            if (data) {
                if (userCountry === 'DE' || selectedCountry === 'DE') {
                    prepaidPortalService.validateCustomer(iccid, prepaidPortalUserDetails.FirstName, prepaidPortalUserDetails.LastName, prepaidPortalUserDetails.DOB, prepaidPortalUserDetails.Email).subscribe(data => {
                        if (data?.Status) {
                            const tempMsisdn = data?.TemporaryMsisdn
                            prepaidPortalService.submitPortInDE({
                                Locale: i18n.language,
                                requestedMsisdn: prepaidPortalUserDetails?.MSISDN,
                                newIccid: iccid,
                                donorOperator: prepaidPortalUserDetails.Operator?.split('-')[0],
                                isOnContract: prepaidPortalUserDetails?.IsOnContract,
                                requestedDate: prepaidPortalUserDetails?.RequestDate,
                                retailerId: userData?.AXCode[0],
                                crmId: data?.CustomerInfo?.crmId,
                                orderId: "",
                                useTempSimByIccid: true,
                                TemporaryMsisdn: data?.TemporaryMsisdn
                            }).subscribe({
                                next: data => {
                                    if (data?.Status) {
                                        prepaidPortalService.updateCustomerDetails(tempMsisdn, prepaidPortalUserDetails.Email).subscribe(res => {
                                            if (res) {
                                                dispatch({
                                                    type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: true, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: '' }
                                                })
                                            }
                                            else {
                                                dispatch({
                                                    type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' }
                                                })
                                            }
                                        })
                                    }
                                    else if (!data?.Status && data?.Message === 'PORTIN_ALREADY_REQUESTED_FOR_MSISDN') {
                                        dispatch({
                                            type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: data?.Message }
                                        })
                                    }
                                    else {
                                        dispatch({
                                            type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: '' }
                                        })
                                    }
                                },
                                error: () => { dispatch({ type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' } }) }
                            })
                        }
                        else if (!data?.Status && data?.Message === 'crm_mismatch') {
                            dispatch({
                                type: ActionType.PrepaidPortalValidateSimErrorResponse, data: { errorType: 'personalDetailsError' }
                            })
                        }
                        else {
                            dispatch({
                                type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' }
                            })
                        }
                    })
                }
                else if (userCountry === 'NL' || selectedCountry === 'NL') {
                    if (data) {
                        prepaidPortalService.submitPortInNL({
                            Locale: i18n.language,
                            CrmId: "",
                            RequestedMsisdn: prepaidPortalUserDetails?.MSISDN,
                            TemporaryMsisdn: "",
                            NewIccid: iccid,
                            RequestedDate: prepaidPortalUserDetails?.RequestDate,
                            IsOnContract: prepaidPortalUserDetails?.IsOnContract,
                            OrderId: "",
                            PukCode: pukCode,
                            RetailerId: userData?.AXCode[0],
                            FirstName: prepaidPortalUserDetails?.FirstName,
                            LastName: prepaidPortalUserDetails?.LastName,
                            Street: prepaidPortalUserDetails?.Street,
                            HouseNumber: prepaidPortalUserDetails?.HouseNumber,
                            HouseNumberExt: "",
                            ZipCode: prepaidPortalUserDetails?.ZipCode,
                            City: prepaidPortalUserDetails?.City,
                            Email: prepaidPortalUserDetails?.Email
                        }).subscribe({
                            next: res => {
                                if (res?.Status) {
                                    dispatch({
                                        type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: true, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: '' }
                                    })
                                }
                                else if (!res?.Status && res?.Message === 'PORTIN_ALREADY_REQUESTED_FOR_MSISDN') {
                                    dispatch({
                                        type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: data?.Message }
                                    })
                                }
                                else {
                                    dispatch({
                                        type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: '' }
                                    })
                                }
                            },
                            error: () => { dispatch({ type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' } }) }
                        })
                    }
                    else if (!data?.Status && data?.Message === 'crm_mismatch') {
                        dispatch({
                            type: ActionType.PrepaidPortalValidateSimErrorResponse, data: { errorType: 'personalDetailsError' }
                        })
                    }
                    else {
                        dispatch({
                            type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' }
                        })
                    }
                }
                else {

                    if (data) {
                        prepaidPortalService.submitPortInFr({
                            Locale: i18n?.language,
                            requestedMsisdn: `33${prepaidPortalUserDetails?.MSISDN?.split('')[0] === '0' ? prepaidPortalUserDetails?.MSISDN.substring(1) : prepaidPortalUserDetails?.MSISDN}`,
                            Iccid: iccid,
                            orderId: "",
                            retailerId: userData?.AXCode[0],
                            crmId: "",
                            Riocode: rioCode
                        }).subscribe({
                            next: data => {
                                if (data?.Status) {
                                    dispatch({
                                        type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: true, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: '' }
                                    })
                                }
                                else if (!data?.Status && data?.Message === 'PORTIN_ALREADY_REQUESTED_FOR_MSISDN') {
                                    dispatch({
                                        type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: data?.Message }
                                    })
                                }
                                else {
                                    dispatch({
                                        type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: false, message: '' }
                                    })
                                }
                            },
                            error: () => { dispatch({ type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' } }) }

                        })
                    }
                    else if (!data?.Status && data?.Message === 'crm_mismatch') {
                        dispatch({
                            type: ActionType.PrepaidPortalValidateSimErrorResponse, data: { errorType: 'personalDetailsError' }
                        })
                    }
                    else {
                        dispatch({
                            type: ActionType.PreapidPortalSubmitPortinDEResponse, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true, message: '' }
                        })
                    }
                }
            }
            else if (data === false) {
                dispatch({
                    type: ActionType.PrepaidPortalValidateSimErrorResponse, data: { errorType: 'pukError' }
                })
            }
            else {
                dispatch({
                    type: ActionType.PrepaidPortalInternalServiceError, data: { status: false, msisdn: prepaidPortalUserDetails?.MSISDN, isServiceError: true }
                })
            }
        })
    }


    const generateOTAG = (dispatch: AppDispatch, getState: () => RootState) => () => {
        //code need to modify as per required data field like: SessionId, Msisdn, etc..
        const { prepaidPortalUserDetails, sessionId } = getState().prepaidPortal
        const { userData, selectedCountry } = getState().user
        dispatch({ type: ActionType.PrepaidPortalGenerateOTAGRequest })
        prepaidPortalService.generateOTAG({
            SessionId: sessionId as string,
            CountryCode: selectedCountry as string,
            MSISDN: prepaidPortalUserDetails?.MSISDN,
            AxCode: userData?.AXCode[0]
        }).subscribe(res => {
            if (res) {
                dispatch({ type: ActionType.PrepaidPortalGenerateOTAGResponse, data: { assignedOTAG: 'Success', isOTAGGenerateFailed: false } })
            } else {
                dispatch({
                    type: ActionType.PrepaidPortalGenerateOTAGResponse, data: {
                        assignedOTAG: 'Failed',
                        isOTAGGenerateFailed: true
                    }
                })
            }
        })
    }

    const resetOTAG = (dispatch: AppDispatch) => () =>
        dispatch({ type: ActionType.PostPayGenerateOTAGResponse, data: {} })

    const validateOTAG = (dispatch: AppDispatch, getState: () => RootState) => (msisdn: string, otp: string, onComplete: (arg: any) => void) => {
        const { sessionId } = getState().prepaidPortal
        const { countryConfiguration } = getState().user
        //dispatch({ type: ActionType.PrepaidPortalValidateOTAGRequest })
        prepaidPortalService.validateOTAG(
            sessionId as string,
            `${countryConfiguration.InternationalDialingCode}${msisdn?.startsWith('0') ? msisdn.substring(1) : msisdn}`,
            otp
        ).subscribe(res => {
            //dispatch({ type: ActionType.PrepaidPortalValidateOTAGResponse, data: { assignedOTAG: 'Success' } })
            onComplete(res.Message)
        })
    }

    const cleanData = (dispatch: AppDispatch) => () => dispatch({ type: ActionType.PrepaidPortalCleanData })

    return { 
        getSessionId, 
        verifyRioCode, 
        updateValidRioCode, 
        updateRioCode, updatePage, 
        updateUserDetails, updateSimDetails, 
        updateErrorType, generateOTAG, 
        validateOTAG, resetOTAG, 
        validateSim, cleanData,
        generalGetRequest, generalPostRequest,
        generalPutRequest 
    }
}

export default initActions