import { AppDispatch, RootState } from "../configureStore";
import { map } from "rxjs/internal/operators/map";
import { i18n } from "i18next";
import { ActionType } from "@src/dtos/enums";
import { IMyOrdersService } from "@src/services/MyOrdersService";
import { stdDateString } from "@src/utils";
import { MyOrdersFilters } from ".";


const initActions = (myOrdersService: IMyOrdersService) => {

    const fetchMyOrders = (dispatch: AppDispatch, getState: () => RootState) => () => {
        if(getState().myOrders.fetchInProgress) {
            return
        }
        dispatch({ type: ActionType.MyOrdersGetOrdersRequest })
        const { filters } = getState().myOrders
        myOrdersService.fetchOrders(stdDateString(filters, true)).subscribe(res => {
            dispatch({ type: ActionType.MyOrdersGetOrdersResponse, data: res })
        })
    }

    const fetchMBEOrders = (dispatch: AppDispatch, getState: () => RootState) => () => {

        if(getState().myOrders.fetchInProgress) {
            return
        }
        dispatch({ type: ActionType.MyOrdersGetOrdersRequest })
        const { filters } = getState().myOrders
        delete filters.productId
        delete filters.orderBy
        myOrdersService.fetchMBEOrders(stdDateString(filters, true)).subscribe(res => {
            dispatch({ type: ActionType.MyOrdersGetOrdersResponse, 
                data: !!res ? res.map((item: any) => ({ ...item, Description: item.ProductName, CreatedDate: item.OrderCreatedDate })) : []
            })
        })       
    }

    // const fetchMBEOrders = (dispatch: AppDispatch, getState: () => RootState) => () => {
    //     if(getState().myOrders.fetchInProgress) {
    //         return
    //     }
    //     dispatch({ type: ActionType.MyOrdersGetOrdersRequest })
    //     const filters = {...getState().myOrders.filters}
    //     delete filters.productId
    //     myOrdersService.fetchMBEOrders({
    //         ...filters,
    //         dateFrom: filters.dateFrom && `${filters.dateFrom.getMonth() < 9 && '0' || ''}${filters.dateFrom.getMonth() + 1}/${filters.dateFrom.getFullYear()}`,
    //         toDate: filters.toDate && `${filters.toDate.getMonth() < 9 && '0' || ''}${filters.toDate.getMonth() + 1}/${filters.toDate.getFullYear()}`
    //     }).subscribe(res => {
    //         dispatch({ 
    //             type: ActionType.MyOrdersGetOrdersResponse,
    //             data: !!res ? res.map((item: any) => ({ ...item, Description: item.ProductName, CreatedDate: item.OrderCreatedDate })) : []
    //         })
    //     })
    // }


    const fetchMBEOrderSummary = (dispatch: AppDispatch) => (orderNumber: string) => {
        dispatch({ type: ActionType.OrderSummaryRequest })
        myOrdersService.mbeOrderSummary(orderNumber).subscribe(res => {
            dispatch({ 
                type: ActionType.OrderSummaryResponse, 
                data: {
                    ...res?.Order,
                    OrderItemList: res?.Order.OrderLines.map((item: any) => ({ ...item, Description: item.ProductName, Status: item.Status || res?.Order.Status }))
                }
            })
        })
    }

    const getProducts = (dispatch: AppDispatch) => () => {
        myOrdersService.getAllProducts().subscribe(res=> {
            dispatch({ type: ActionType.MyOrdersGetProductsResponse, data: [
                { Name: 'myorders-producttypeoption_all', Id: '' },
                ...res
            ] })
        })
    }

    const downloadMyOrders = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.MyOrdersDownloadMyOrdersStart })
        const filters = getState().myOrders.filters
        myOrdersService.downloadMyOrders({
            ...filters,
            dateFrom: filters.dateFrom && `${filters.dateFrom.getMonth() < 9 && '0' || ''}${filters.dateFrom.getMonth() + 1}/${filters.dateFrom.getFullYear()}`,
            toDate: filters.toDate && `${filters.toDate.getMonth() < 9 && '0' || ''}${filters.toDate.getMonth() + 1}/${filters.toDate.getFullYear()}`
        }).pipe(map(data => {
            const typeFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            window.location.href = `data:${typeFile};base64,${data}`;
        })).subscribe(() => {
            dispatch({type: ActionType.MyOrdersDownloadMyOrdersEnd})
        })
    }

    const setMyOrdersFilter = (dispatch: AppDispatch) => (filters: MyOrdersFilters) => {
        dispatch({ type: ActionType.MyOrdersSetFilters, data: filters })
    }

    const cleanMyOrdersData = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.MyOrdersCleanData })
    }

    const fetchOrderSummary = (dispatch: AppDispatch) => (orderNumber: string) => {
        dispatch({ type: ActionType.OrderSummaryRequest })
        myOrdersService.getSummary(orderNumber).subscribe(res => {
            dispatch({ type: ActionType.OrderSummaryResponse, data: res })
        })
    }

    const clearOrderSummary = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.OrderSummaryClear })
    }

    return {
        fetchMyOrders,
        setMyOrdersFilter,
        getProducts,
        cleanMyOrdersData,
        downloadMyOrders,
        fetchOrderSummary,
        clearOrderSummary,
        fetchMBEOrders,
        fetchMBEOrderSummary
    }
}

export default initActions