import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { Observable } from "rxjs"
import { DownloadCentreDto } from "@src/dtos/downloadCentreDto"

const DownloadCentreService = (ajax: ajaxType) => {
    return {
        fetchHistory: (): Observable<DownloadCentreDto> => {
            return ajax.get(`${config.url.API_URL}DownloadCentre/History`)
                .pipe(map(res => res.response));
        },
        downloadRecord: (fileName: string, kpi: string) => {
            const url = `${config.url.API_URL}DownloadCentre/Download/${fileName}/${kpi}`;
            window.location.href = url;
            const headers = {
                // 'responseType': 'arraybuffer',
                'Content-Type': 'application/octet-stream'
            }
            return ajax.get(
                url,
                headers
            ).pipe(map(res => {
                console.log(res)
                return res.response
            }))
        },
        deleteRecord: (fileName: string) =>
            ajax.del(
                `${config.url.API_URL}DownloadCentre/Delete/${fileName}`,
            ).pipe(map(res => res.response))
    }
}

export type IDownloadCentreService = ReturnType<typeof DownloadCentreService>

export default DownloadCentreService