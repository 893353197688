import { IDashboardTilesService } from "@src/services/DashboardTilesService";
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";

const initReducer = function (dashboardTilesService:IDashboardTilesService) {

    const INITIAL_STATE = {
      fetchInProgress: false,
      tilesData: (undefined as any)
    };

    let reducersByActions = {} as Partial<ActionReducer>
    reducersByActions[ActionType.FetchTilesRequest] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: true,
        tilesData: undefined
      };
    }

    reducersByActions[ActionType.FetchTilesResponse] =  (state: any, actionData: any) => {
      return {
        ...state,
        fetchInProgress: false,
        tilesData: actionData
      };
    }
    
    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
      return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    
    return reducer;
  };
  
  export default initReducer;