import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { PostPayDocumentsDto, PostPayloadDto, PostPayProductsDto, PostPayPromotionsDto } from '@src/dtos/postpayDto';
import { PostpaySteps, SimProductType } from '@src/dtos/enums';

export interface IPostPayConfig {
  CreditCheckEnabled: boolean
  ActivationFee: number
  IsCustomerProfileEnabled: boolean
  MsisdnLength: number
  IsPromotionApplicable: boolean
  IsActivateProfileEnabled: boolean
  IsActivateProductEnabled: boolean
  IBANLength: number
}

interface RetentionStatus {
  loading: boolean,
  status: boolean | null,
  message: string,
  eligible: boolean,
  retentionEligibilityDate: string | null,
  retentionEligibilityResponse: { contractId: number } | undefined,
}

export interface PostPayState {
  productsFetchInProgress: boolean
  isProfileCheckInProgress: boolean
  isSubmittingPostPay: string | null
  isDigitalSigining: boolean
  isCheckingEnvelopeStatus: boolean
  isFetchingNetworkOperators: boolean
  isFetchingNonPortInDates: boolean
  isCapturePortInSuccess: boolean
  envelopeStatusChecked: boolean
  isValidatingMsisdnCustomer: boolean
  isCustomerProfileValid: boolean
  isGeneratingOtag: boolean
  isOTAGGenerateFailed: boolean
  isFetchingPromotions: boolean
  isPromotionApplicable: boolean
  profileCheckFailMessage: string
  numberDetailsErrorMessage: string
  consentError: string
  envelopeStatusError: string
  msisdnValidationError: string
  assignedOTAG: string
  // capturePortInError: string
  products: PostPayProductsDto
  boltons: PostPayProductsDto
  networkOperators: any[],
  nonPortInDates: any[],
  promotionsList: PostPayPromotionsDto
  documentList: PostPayDocumentsDto
  postPayload: PostPayloadDto
  step: PostpaySteps
  CartTotal: number
  cartTotalReqInProgress: boolean
  productType: SimProductType | undefined
  contractUrl: string | undefined
  discountedActivationFee: number | undefined
  activationFee: number | undefined
  appliedPromotion: any
  retentionNumber: number | string | undefined
  retentionStatus: RetentionStatus
  postpayConfig: IPostPayConfig | null
}

const configurePostPayModule = (services: IServices) => {
  const actions = initActions(services.postPayService, services.storageService);
  const reducer = initReducer(services.storageService);

  return { actions, reducer };
};

export type PostPayReducer = ReturnType<typeof initReducer>
export type PostPayActions = ReturnType<typeof initActions>

export default configurePostPayModule;