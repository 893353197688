import config from '@src/env'
import { useEffect, useState } from 'react'

declare global {
    interface Window {
        grecaptcha: any;
    }
}

// const { SITE_KEY, SECRET_KEY } = config.captcha

const showBadge = (isEnterprise: boolean) => {
    if (!window.grecaptcha) return
    if (isEnterprise) {
        window.grecaptcha.enterprise.ready(() => {
            const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement
            if (!badge) return
            badge.style.display = 'block'
            badge.style.zIndex = '1'
        })
    } else {
        window.grecaptcha.ready(() => {
            const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement
            if (!badge) return
            badge.style.display = 'block'
            badge.style.zIndex = '1'
        })
    }    
}

const hideBadge = (isEnterprise: boolean) => {
    if (!window.grecaptcha) return
    if (isEnterprise) {
        window.grecaptcha.enterprise.ready(() => {
            const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement
            if (!badge) return
            badge.style.display = 'none'
        })
    } else {
        window.grecaptcha.ready(() => {
            const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement
            if (!badge) return
            badge.style.display = 'none'
        })
    }    
}

const useReCaptcha = (grecaptchaKey: string): { reCaptchaLoaded: boolean; generateReCaptchaToken: (action: string) => Promise<string>} => {
    const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false)
    const isEnterprise = config.captcha.CAPTCHA_MODE === 'enterprise'

    // Load ReCaptcha script
    useEffect(() => {
        if (typeof window === 'undefined' || reCaptchaLoaded) return
        if (window.grecaptcha) {
            // showBadge()
            setReCaptchaLoaded(true)
            return
        }
        const script = document.createElement('script')
        script.async = true
        script.src = `https://www.google.com/recaptcha/${isEnterprise ? 'enterprise' : 'api'}.js?render=${grecaptchaKey ?? ''}`
        script.addEventListener('load', () => {
            setReCaptchaLoaded(true)
            // showBadge()
        })
        document.body.appendChild(script)
    }, [reCaptchaLoaded])

    // Hide badge when unmount
    // useEffect(() => hideBadge, [])
    // Hide badge always
    useEffect(() => {
        hideBadge(isEnterprise)
    }, [window.grecaptcha])

    // Get token
    const generateReCaptchaToken = (action: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (!reCaptchaLoaded) return reject(new Error('ReCaptcha not loaded'))
            if (typeof window === 'undefined' || !window.grecaptcha) {
                setReCaptchaLoaded(false)
                return reject(new Error('ReCaptcha not loaded'))
            }
            if (isEnterprise) {
                window.grecaptcha.enterprise.ready(() => {
                    window.grecaptcha.enterprise.execute(grecaptchaKey ?? '', { action }).then((token: string) => {
                        resolve(token)
                    })
                })
            } else {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(grecaptchaKey ?? '', { action }).then((token: string) => {
                        resolve(token)
                    })
                })
            }            
        })
    }

    return { reCaptchaLoaded, generateReCaptchaToken }
}

export default useReCaptcha