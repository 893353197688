import { AppDispatch } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { IDownloadCentreService } from "@src/services/DownloadCentreService";
import { map } from "rxjs/operators";

const initActions = (downloadCentreServices: IDownloadCentreService) => {

    const getDownloadHistory = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.DownloadCentreHistoryRequest })
        downloadCentreServices.fetchHistory().subscribe(res => {
            dispatch({
                type: ActionType.DownloadCentreHistoryResponse, data: res ? [...res] : []
            })
        })
    }

    const downloadRecord = (dispatch: AppDispatch) => (fileName: string, kpi: string) => {
        downloadCentreServices.downloadRecord(fileName, kpi)
            .subscribe((res) => {
                const fileName = res?.content?.headers?.[1]?.value[0]?.split('filename=')[1] ?? "file.xls";
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
    }

    const deleteRecord = (dispatch: AppDispatch) => (fileName: string) => {
        downloadCentreServices.deleteRecord(fileName)
            .pipe(map(data => {
            }))
            .subscribe((res: any) => {
                getDownloadHistory(dispatch)
            })
    }
    const cleanDownloadCentre = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanDownloadCentre })
    }

    return {
        getDownloadHistory,
        downloadRecord,
        deleteRecord,
        cleanDownloadCentre,
    }
}

export default initActions