import { forkJoin } from "rxjs";
import { AppDispatch } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { ISimRegAssessmentService } from "@src/services/SimRegAssessmentService";

const initActions = (SimRegAssessmentServices: ISimRegAssessmentService) => {
    const getTDfile = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimRegAssessmentTDFileRequest })
        SimRegAssessmentServices.downloadTD().subscribe((res: any) => {
            const file = new Blob([res], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            dispatch({ type: ActionType.SimRegAssessmentTDFileResponse, data: fileURL })
        })
    }
    const getQuestions = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimRegAssessmentQuestionRequest })
        forkJoin([
            SimRegAssessmentServices.getSessionId(),
            SimRegAssessmentServices.fetchQuestions()
        ]).subscribe(([sessionId, questions]) => {
            dispatch({ type: ActionType.SimRegAssessmentQuestionResponse, data: { sessionId, questions } })
        })
        // SimRegAssessmentServices.fetchQuestions().subscribe((res: any) => {
        //     dispatch({ type: ActionType.SimRegAssessmentQuestionResponse, data: res })
        // })
    }   

    const logProgress = (dispatch: AppDispatch) => (payload: any) => {
        const { questionId, answers, sessionId } = payload
        const path = `${answers?.join(',')}/${questionId}/${sessionId}`
        SimRegAssessmentServices.logProgress(path).subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentlogResponse, data: { ...res, questionId, answers } })
        })
    }
    const submitTest = (dispatch: AppDispatch) => (sessionId: string) => {
        dispatch({ type: ActionType.SimRegAssessmentResultRequest })
        SimRegAssessmentServices.getResult(sessionId).subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentResultResponse, data: res })
        })
    }
    const getOtherFlags = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimRegAssessmentFlagRequest })
        SimRegAssessmentServices.getOtherFlags().subscribe((res: any) => {
            dispatch({ type: ActionType.SimRegAssessmentFlagResponse, data: res })
        })
    }
    const cleanQuestionnaire = (dispatch: AppDispatch) => () => {
        dispatch({type: ActionType.CleanQuestionnaire})
    }

    const cleanSimRegAssessment = (dispatch: AppDispatch) => () => dispatch({ type: ActionType.CleanSimRegAssessment })

    return {
        getTDfile,
        getQuestions,
        logProgress,
        submitTest,
        getOtherFlags,
        cleanQuestionnaire,
        cleanSimRegAssessment
    }
}

export default initActions