import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { DownloadCentreState } from './index'

const initReducer = function () {
    const INITIAL_STATE: DownloadCentreState = {
        fetchInProgress: false,
        downloadHistory: [],
    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.DownloadCentreHistoryRequest] = (state: DownloadCentreState) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }

    reducersByActions[ActionType.DownloadCentreHistoryResponse] = (state: DownloadCentreState, actionData: any) => {
        return {
            ...state,
            downloadHistory: actionData,
            fetchInProgress: false
        }
    }

    reducersByActions[ActionType.CleanDownloadCentre] = (state: DownloadCentreState, actionData: any) => {
        return {
            ...state,
            downloadHistory: []
        }
    }


    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer;
}

export default initReducer