import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { forkJoin, Observable, of } from "rxjs";
import { IStorageService } from "@src/services/StorageService";
import { map } from "rxjs/internal/operators/map";
import { ISimOnlyService } from "@src/services/SimOnlyService";
import { stdDateString } from "@src/utils";

const initActions = (simonlyService: ISimOnlyService, storageService: IStorageService) => {

    const getProducts = (dispatch: AppDispatch, i18n: i18n) => () => {
        dispatch({ type: ActionType.SimOnlyProductsRequest })
        simonlyService.fetchProducts(i18n.language).subscribe(res => {
            dispatch({ type: ActionType.SimOnlyProductsResponse, data: res })
        })
    }

    const getSessionId = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SimOnlySessionRequest })
        simonlyService.getSessionId().subscribe(res => dispatch({ type: ActionType.SimOnlySessionResponse, data: res }))
    }

    const validateMsisdn = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.SimOnlyValidateMsisdnRequest })
        const { sessionId, purchasePayload } = getState().simonly
        simonlyService.validateMsisdn(sessionId as string, purchasePayload.MSISDN as string).subscribe(res => {
            if (res.Status) {
                dispatch({
                    type: ActionType.SimOnlyValidateMsisdnResponse, data: {
                        success: true,
                        errorCode: undefined
                    }
                })
            } else {
                dispatch({
                    type: ActionType.SimOnlyValidateMsisdnResponse, data: {
                        success: false,
                        errorCode: res.ErrorCode ?? 'genericerror'
                    }
                })
            }
        })
    }

    const updatePurchasePayload = (dispatch: AppDispatch, getState: () => RootState) => {
        const { purchasePayload } = getState().simonly
        type PurchasePayload = typeof purchasePayload
        return (payload: Partial<PurchasePayload>) => dispatch({ type: ActionType.SimOnlyUpdatePurchasePayload, data: payload })
    }

    const purchaseSimo = (_:any, getState: () => RootState) => (postPurchase: (arg: boolean) => void) => {
        const { purchasePayload } = getState().simonly
        const payload = {...purchasePayload}
        delete payload.Language
        simonlyService.purchaseSim(payload, purchasePayload.Language as string)
            .subscribe(res => {
                postPurchase(res.isSucccess)
            })
    }

    const fetchHistory = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { historySearchParams } = getState().simonly
        dispatch({ type: ActionType.SimOnlyHistoryRequest })
        simonlyService.fetchHistory(stdDateString(historySearchParams)).subscribe(res => {
            dispatch({ type: ActionType.SimOnlyHistoryResponse, data: res })
        })
    }

    const updateHistoryParams = (dispatch: AppDispatch, getState: () => RootState) => {
        const { historySearchParams } = getState().simonly
        return (params: Partial<typeof historySearchParams>) => dispatch({ type: ActionType.SimOnlyUpdateHistorySearchParams, data: params })
    }

    const cleanHistory = (dispatch: AppDispatch) => () => dispatch({ type: ActionType.SimOnlyCleanHistoryData })

    const cleanData = (dispatch: AppDispatch) => () => dispatch({ type: ActionType.SimOnlyCleanData })

    const downloadHistory = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (filters: any) => {
        dispatch({ type: ActionType.SimOnlyStartDownload })
        simonlyService.downloadHistory(filters, i18n.language)
            .subscribe((data) => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }));
                link.setAttribute('download', `${filters?.dateFrom }-${filters.toDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch({ type: ActionType.SimOnlyEndDownload })
            })
    };

    return { getProducts, getSessionId, validateMsisdn, cleanData, updatePurchasePayload, purchaseSimo, fetchHistory, updateHistoryParams, cleanHistory, downloadHistory }
}

export default initActions