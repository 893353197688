import { ITopUpHistoryService } from "@src/services/TopUpHistoryService";
import { map } from "rxjs/operators";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { i18n } from "i18next";
import { FiltersDto } from "@src/dtos/filtersDto";

const initActions = function (ItemService: ITopUpHistoryService) {

    const fetchHistory = (dispatch: AppDispatch, getState: () => RootState) => () => {
        const { filters } = getState().topUpHistory
        dispatch({ type: ActionType.TopHistoryRequest, data: filters })
        ItemService.fetchHistory(filters)
            // .pipe(map(data => {
            //     return data
            // }))
            .subscribe(data => {
                dispatch({ type: ActionType.TopHistoryResponse, data: { data } })
            })
    };

    const downloadHistory = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        const { filters } = getState().topUpHistory        
        dispatch({ type: ActionType.TopHistoryStartDownload })
        ItemService.downloadHistory(filters, i18n.language)
            .pipe(map(data => {
                const typeFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                window.location.href = `data:${typeFile};base64,${data}`;
            }))
            .subscribe(() => {
                dispatch({ type: ActionType.TopHistoryEndDownload })
            })
    };

    const setTopHistoryFilter = (dispatch: AppDispatch) => (filters: Partial<FiltersDto>) => {
        dispatch({ type: ActionType.TopHistorySetFilters, data: { ...filters } })
    }

    const cleanTopUpHistory = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.TopHistoryClean })
    };

    return { fetchHistory, downloadHistory, setTopHistoryFilter, cleanTopUpHistory };
};

export default initActions;