import { IServices } from '@src/services';
import initReducer from './reducers';
import initActions from './actions';

const configureSimOnlyModule = (services: IServices) => {
  const actions = initActions(services.simonlyService, services.storageService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type SimOnlyReducer = ReturnType<typeof initReducer>
export type SimOnlyActions = ReturnType<typeof initActions>

export default configureSimOnlyModule;