import { IStorageService } from "@src/services/StorageService";
import { ActionReducer, IAction } from "../types";
import { Pages } from ".";
import Loading from "@src/View/Widgets/Loading";
import { ActionType } from "@src/dtos/enums";

const initReducer = function (storageService: IStorageService) {
    let INITIAL_STATE = {
        prepaidPortalUserDetails: {
            FirstName: '',
            LastName: '',
            DOB: null,
            Email: '',
            MSISDN: '',
            Operator: null as string | null,
            RequestDate: null,
            IsOnContract: false,
            HouseNumber: '',
            Street: '',
            ZipCode: '',
            City: '',
            AddressCountry: '',
            PlaceSelected: false,
        },
        prepaidPortalSimDetails: {
            PUKCode: '',
            NewIccid: '',
        },
        page: Pages.PrepaidPortalForm,
        isFetchingSessionId: false,
        sessionId: '',
        loading: false,
        isValidRio: true,
        rioCode: '',
        IsSuccess: { status: false, msisdn: '', isServiceError: false, message: '' },
        errorType: '',
        networkOperators: new Array<any>(),
        nonPortInDates: new Array<any>(),
        isGeneratingOtag: false,
        isOTAGGenerateFailed: false
    }
    type PrepaidPortalState = typeof INITIAL_STATE
    type PrepaidPortalUserDetails = typeof INITIAL_STATE.prepaidPortalUserDetails
    type PrepaidPortalSimDetails = typeof INITIAL_STATE.prepaidPortalSimDetails
    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.PrepaidPortalSessionRequest] = (state: PrepaidPortalState): PrepaidPortalState => {
        return {
            ...state,
            isFetchingSessionId: true
        }
    }

    reducersByActions[ActionType.PrepaidPortalSessionResponse] = (state: PrepaidPortalState, actionData: string): PrepaidPortalState => {
        return {
            ...state,
            isFetchingSessionId: false,
            sessionId: actionData
        }
    }

    reducersByActions[ActionType.PrepaidPortalNetworkOperatorResponse] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            networkOperators: actionData
        }
    }

    reducersByActions[ActionType.PrepaidPortalNonPortinDatesResponse] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            nonPortInDates: actionData
        }
    }

    reducersByActions[ActionType.PrepaidPortalVerifyRioCodeRequest] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            loading: true
        }
    }

    reducersByActions[ActionType.PrepaidPortalVerifyRioCodeResponse] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            loading: false,
            isValidRio: actionData ? true : false,
            page: actionData ? Pages.PrepaidNewSimDetails : Pages.PrepaidPortalForm,
        }
    }


    reducersByActions[ActionType.PreapidPortalValidateSimRequest] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            loading: true
        }
    }

    reducersByActions[ActionType.PreapidPortalValidateSimResponse] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            loading: false,
            isValidRio: actionData.any() ? true : false,
            page: actionData.any() ? Pages.PrepaidNewSimDetails : Pages.PrepaidPortalForm,

        }
    }

    reducersByActions[ActionType.PrepaidPortalInternalServiceError] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            loading: false,
            IsSuccess: {
                status: actionData?.status,
                msisdn: actionData?.msisdn,
                isServiceError: actionData?.isServiceError
            }
        }
    }

    reducersByActions[ActionType.PrepaidPortalValidateSimErrorResponse] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            loading: false,
            errorType: actionData?.errorType
        }
    }


    reducersByActions[ActionType.PreapidPortalSubmitPortinDEResponse] = (state: PrepaidPortalState, actionData: any) => {        
        return {
            ...state,            
            IsSuccess: { ...state.IsSuccess, ...actionData },
            loading: false,
            page: Pages.PrepaidPortalResult
        }
    }


    reducersByActions[ActionType.PrepaidPortalUpdateValidRioCode] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            isValidRio: actionData.status
        }
    }

    reducersByActions[ActionType.PrepaidPortalUpdateRioCode] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            rioCode: actionData.value
        }
    }

    reducersByActions[ActionType.PrepaidPortalUpdatePage] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            page: actionData.value
        }
    }

    reducersByActions[ActionType.PrepaidPortalUpdateErrorType] = (state: PrepaidPortalState, actionData: any) => {
        return {
            ...state,
            errorType: actionData.value
        }
    }


    reducersByActions[ActionType.PrepaidPortalUpdateUserDetails] = (state: PrepaidPortalState, actionData: PrepaidPortalUserDetails) => {
        return {
            ...state,
            prepaidPortalUserDetails: {
                ...state?.prepaidPortalUserDetails,
                ...actionData
            },
        }
    }
    reducersByActions[ActionType.PrepaidPortalUpdateSimDetails] = (state: PrepaidPortalState, actionData: PrepaidPortalSimDetails) => {
        return {
            ...state,
            prepaidPortalSimDetails: {
                ...state?.prepaidPortalSimDetails,
                ...actionData
            },
        }
    }

    reducersByActions[ActionType.PrepaidPortalGenerateOTAGRequest] = (state: PrepaidPortalState) => {
        return {
            ...state,
            loading: true,
            isGeneratingOtag: true,
            isOTAGGenerateFailed: false
        }
    }

    reducersByActions[ActionType.PrepaidPortalGenerateOTAGResponse] = (state: PrepaidPortalState, actionData: any): PrepaidPortalState => {
        return {
            ...state,
            loading: false,
            isGeneratingOtag: false,
            isOTAGGenerateFailed: !!actionData.isOTAGGenerateFailed,
            page: Pages.ConfirmPrepaidNumber
            //page: !actionData.isOTAGGenerateFailed? Pages.ConfirmPrepaidPortInNumber : Pages.PrepaidPortalForm,
            // assignedOTAG: actionData.assignedOTAG ?? '',            
            // numberDetailsErrorMessage: actionData.numberDetailsErrorMessage ?? ''
        }
    }

    // reducersByActions[ActionType.PrepaidPortalValidateOTAGRequest] = (state: PrepaidPortalState, actionData: any) => {
    //     return {
    //         ...state,
    //         loading: true
    //     }
    // }

    // reducersByActions[ActionType.PrepaidPortalValidateOTAGResponse] = (state: PrepaidPortalState, actionData: any) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         //isValidRio: actionData.any() ? true : false,
    //         page: actionData.any() ? Pages.PrepaidNewSimDetails : Pages.PrepaidPortalForm,

    //     }
    // }


    reducersByActions[ActionType.PrepaidPortalCleanData] =
        reducersByActions[ActionType.Logout] = (): PrepaidPortalState => {
            return {
                ...INITIAL_STATE,
                prepaidPortalUserDetails: { ...INITIAL_STATE.prepaidPortalUserDetails },
                prepaidPortalSimDetails: { ...INITIAL_STATE.prepaidPortalSimDetails },
                page: Pages.PrepaidPortalForm,
                isFetchingSessionId: false,
                sessionId: '',
                loading: false,
                isValidRio: true,
                rioCode: '',
                IsSuccess: { status: false, msisdn: '', isServiceError: false, message: '' },
                errorType: '',
                networkOperators: new Array<any>(),
                nonPortInDates: new Array<any>(),
                isGeneratingOtag: false,
                isOTAGGenerateFailed: false
            }
        }



    const reducer = (state = JSON.parse(JSON.stringify((INITIAL_STATE))), action: IAction): PrepaidPortalState => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    return reducer;
}

export default initReducer
