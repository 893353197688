import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { EpinPrintPopupDto } from '@src/dtos/epinPrintDto';
import { PrintHistoryByDayDto } from '@src/dtos/printHistoryDto';

let x: PrintHistoryByDayDto[number]

export interface PrintHistory {
  id: number,
  ActivatedDate: string;
  Denomination: string;
  DisplayName: string;
  Name: string;
  PrintedDateTime: string;
  RePrintedDateTime: null | string;
  Type: string;
  PrintBatchId: string;
  SerialNumber?: string;
  TransactionDoneBy: string;
  Quantity: number;
  ProductName: string;
  ExpiryDate: string;
  Epins?: typeof x.Epins;
}

export type IPrintHistoryState = {
  history: PrintHistory[]
  fetchInProgress: boolean
  printDataFetchInProgress: boolean
  lastPrintData: undefined | EpinPrintPopupDto,
  isPrinting: boolean,
  epinsInRecord: any[],
}

const configurePrintHistoryModule = (services: IServices) => {
  const actions = initActions(services.printHistoryService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type PrintHistoryReducer = ReturnType<typeof initReducer>
export type PrintHistoryActions = ReturnType<typeof initActions>

export default configurePrintHistoryModule;