import { IStorageService } from "@src/services/StorageService";
import { IAction, ActionReducer } from "../types";
import { PostPayState, IPostPayConfig } from './index'
import { PostpaySteps, SignatureTypes, SimProductType, ActionType } from "@src/dtos/enums";

const initReducer = function (storageService: IStorageService) {
    let INITIAL_STATE: PostPayState = {
        productsFetchInProgress: false,
        cartTotalReqInProgress: false,
        isProfileCheckInProgress: false,
        isSubmittingPostPay: null,
        isDigitalSigining: false,
        isCheckingEnvelopeStatus: false,
        isFetchingNetworkOperators: false,
        isFetchingNonPortInDates: false,
        envelopeStatusChecked: false,
        isCapturePortInSuccess: false,
        isGeneratingOtag: false,
        isOTAGGenerateFailed: false,
        isValidatingMsisdnCustomer: false,
        isCustomerProfileValid: true,
        isFetchingPromotions: false,
        isPromotionApplicable: false,
        profileCheckFailMessage: '',
        numberDetailsErrorMessage: '',
        consentError: '',
        envelopeStatusError: '',
        msisdnValidationError: '',
        assignedOTAG: '',
        productType: undefined,
        products: [],
        boltons: [],
        networkOperators: [],
        nonPortInDates: [],
        documentList: [],
        promotionsList: [],
        CartTotal: 0,
        step: PostpaySteps.Home,
        contractUrl: undefined,
        retentionNumber: undefined,
        discountedActivationFee: undefined,
        activationFee: undefined,
        appliedPromotion: undefined,
        retentionStatus: {
            loading: false,
            message: '',
            status: null,
            eligible: false,
            retentionEligibilityDate: null,
            retentionEligibilityResponse: undefined 
        },
        postpayConfig: null,
        postPayload: {
            AxCode: null,
            CountryCode: null,
            ClientIP: null,
            CompanyId: null,
            PortInType: null,
            CardHolderName: null,
            Product: undefined,
            FirstName: null,
            LastName: null,
            SessionId: null,
            DOB: null,
            Email: null,
            Title: null,
            PostCode: null,
            HouseNo: null,
            IsOnContract: false,
            Locale: null,
            CurrencyCode: null,
            City: null,
            AddressCountry: null,
            StreetName: null,
            IBAN: null,
            NewMSISDN: null,
            NonLebaraNumber: null,
            CrmId: null,
            MSISDN: null,
            ICCID: null,
            ActivationFee: 0,
            Operator: '',
            ContractUrl: null,
            ActivationType: null,
            ActivationDate: '0001-01-01T00:00:00',
            IsLebaraSubPreference: false,
            IsAffiliatePrefernce: false,
            ActivateProfileStatus: false,
            CreateCustomerStatus: false,
            AddSubscriptionStatus: false,
            SubmitOrderStatus: false,
            Status: null,
            ErrorDescription: null,
            OrderNumber: null,
            TransactionId: null,
            UpdateCustomerStatus: false,
            PUKCode: null,
            PostPayType: '',
            FreeMSISDN: null,
            Boltons: [],
            SignatureType: SignatureTypes.onscreen,
            EnvelopeId: null,
            EnvelopeStatus: null,
            DisplayName: null,
            SignerClientId: null,
            IsPortInSuccess: false,
            CreatedDate: "0001-01-01T00:00:00",
            IsEnvelopePurged: false,
            DocumentName: null,
            epcPromotionId: undefined,
            IsBusinessCustomer: false,
            CompanyName: undefined,
            KvkNumber: undefined,
            VatNumber: undefined,
            RequestType: undefined,
            SubscriptionStartType: undefined
        }
    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.PostPayRestorePortInRequest] = (state: PostPayState): PostPayState => {
        const postpayCache = storageService.get('postpayportinstate')
        if(postpayCache) {
            storageService.remove('postpayportinstate')
            return {
                ...INITIAL_STATE,
                ...JSON.parse(postpayCache)
            }
        } else {
            window.history.replaceState(null, '', '/PostPayPortIn')
            return { ...state }
        }        
    }

    reducersByActions[ActionType.PostPayProductRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            productsFetchInProgress: true
        }
    }

    reducersByActions[ActionType.PostPayProductResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            products: actionData.products,
            boltons: actionData.boltons,
            productsFetchInProgress: false
        }
    }

    reducersByActions[ActionType.PostPayDocumentsResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            documentList: actionData,
        }
    }

    reducersByActions[ActionType.PostPayPromotionsRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isFetchingPromotions: true
        }
    }

    reducersByActions[ActionType.PostPayPromotionsResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            promotionsList: actionData,
            isFetchingPromotions: false
        }
    }

    reducersByActions[ActionType.PostPayLoadUpdate] = (state: PostPayState, actionData: typeof INITIAL_STATE.postPayload): PostPayState => {
        return {
            ...state,
            postPayload: {
                ...state?.postPayload,
                ...actionData
            },
        }
    }

    reducersByActions[ActionType.PostPayCartTotalRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            cartTotalReqInProgress: true
        }
    }

    reducersByActions[ActionType.PostPayCartTotalResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            cartTotalReqInProgress: false,
            CartTotal: actionData.CartTotal,
            activationFee: actionData.activationFee,
            isPromotionApplicable: actionData.isPromotionApplicable,
            discountedActivationFee: actionData.discountedActivationFee,
            appliedPromotion: actionData.appliedPromotion
        }
    }

    reducersByActions[ActionType.PostPayCartClear] = (state: PostPayState): PostPayState => {
        return {
            ...INITIAL_STATE,
            products: state.products,
            boltons: state.boltons,
            documentList: state.documentList,
            postPayload: {...INITIAL_STATE.postPayload}
        }
    }

    reducersByActions[ActionType.PostPaySetStep] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            step: actionData
        }
    }

    reducersByActions[ActionType.PostPayProductTypeUpdate] = (state: PostPayState, actionData: SimProductType | undefined): PostPayState => {
        return {
            ...state,
            productType: actionData
        }
    }

    reducersByActions[ActionType.PostPayCustProfileCheckRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isProfileCheckInProgress: true,
            isCustomerProfileValid: true,
            msisdnValidationError: '',
            postPayload: {
                ...state.postPayload,
                MSISDN: null
            }
        }
    }

    reducersByActions[ActionType.PostPayCustProfileCheckResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isProfileCheckInProgress: false,
            profileCheckFailMessage: actionData
        }
    }

    reducersByActions[ActionType.FMVNONewLebaraSubmitRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: 'FMVNONewLebara',
            numberDetailsErrorMessage: ''
        }
    }    

    reducersByActions[ActionType.FMVNONewLebaraSubmitResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: null,
            numberDetailsErrorMessage: actionData.error || '',
            consentError: actionData.consentError || '',
            contractUrl: actionData.ContractSummaryUrl,
            postPayload: {
                ...state.postPayload,
                OrderNumber: actionData.OrderNumber || null,
                ContractUrl: actionData.Url || null
            }
        }
    }

    reducersByActions[ActionType.LMVNONewLebaraSubmitRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: 'LMVNONewLebara',
            numberDetailsErrorMessage: ''
        }
    }

    reducersByActions[ActionType.LMVNONewLebaraSubmitResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: null,
            numberDetailsErrorMessage: actionData.error || '',
            consentError: actionData.consentError || '',
            contractUrl: actionData.ContractSummaryUrl,
            postPayload: {
                ...state.postPayload,
                OrderNumber: actionData.OrderNumber || null,
                ContractUrl: actionData.Url || null
            }
        }
    }

    reducersByActions[ActionType.PostPayValidateSubscriptionRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: 'ValidateSubscription',
            numberDetailsErrorMessage: ''
        }
    }

    reducersByActions[ActionType.PostPayValidateSubscriptionResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: null,
            ...actionData
        }
    }

    reducersByActions[ActionType.CreateConsentRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: 'FMVNONonLebara',
            numberDetailsErrorMessage: ''
        }
    }

    reducersByActions[ActionType.CreateConsentResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: null,
            consentError: actionData.consentError || '',
            isCapturePortInSuccess: !actionData.consentError,
            postPayload: {
                ...state.postPayload,
                OrderNumber: actionData.OrderNumber || null,
                ContractUrl: actionData.Url || null
            }
        }
    }

    reducersByActions[ActionType.ValidateMsisdCustomerRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isValidatingMsisdnCustomer: true
        }
    }

    reducersByActions[ActionType.ValidateMsisdCustomerResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isValidatingMsisdnCustomer: false,
            msisdnValidationError: actionData.msisdnError || '',
            isCustomerProfileValid: actionData.hasOwnProperty('isCustomerProfileValid') ? actionData.isCustomerProfileValid : state.isCustomerProfileValid
        }
    }

    reducersByActions[ActionType.PostPayGenerateOTAGRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isGeneratingOtag: true,
            isOTAGGenerateFailed: false
        }
    }

    reducersByActions[ActionType.PostPayGenerateOTAGResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isGeneratingOtag: false,
            assignedOTAG: actionData.assignedOTAG ?? '',
            isOTAGGenerateFailed: !!actionData.isOTAGGenerateFailed,
            numberDetailsErrorMessage: actionData.numberDetailsErrorMessage ?? ''
        }
    }

    reducersByActions[ActionType.ExistingLebaraSubmitRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: 'ExistingLebara',
            numberDetailsErrorMessage: ''
        }
    }

    reducersByActions[ActionType.ExistingLebaraSubmitResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: null,
            numberDetailsErrorMessage: actionData.error || '',
            consentError: actionData.consentError || '',
            postPayload: {
                ...state.postPayload,
                OrderNumber: actionData.OrderNumber || null,
                ContractUrl: actionData.Url || null
            }
        }
    }

    reducersByActions[ActionType.PostPayDigitalSignRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isDigitalSigining: true
        }
    }

    reducersByActions[ActionType.PostPayDigitalSignResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isDigitalSigining: false,
            postPayload: {
                ...state.postPayload,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.PostPayNetworkOperatorsRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isFetchingNetworkOperators: true
        }
    }

    reducersByActions[ActionType.PostPayNetworkOperatorsResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isFetchingNetworkOperators: false,
            networkOperators: actionData
        }
    }

    reducersByActions[ActionType.PostPayEnvelopeStatusRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isCheckingEnvelopeStatus: true
        }
    }

    reducersByActions[ActionType.PostPayEnvelopeStatusResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            isCheckingEnvelopeStatus: false,
            envelopeStatusChecked: true,
            envelopeStatusError: actionData.error || '',
            postPayload: actionData.postPayload ? { ...state.postPayload, ...actionData.postPayload } : {...state.postPayload}
        }
    }

    reducersByActions[ActionType.PostpayCleanCustomerDetailsError] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            profileCheckFailMessage: ''
        }
    }
    reducersByActions[ActionType.PostPaySetRetentionNumber] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            retentionNumber: actionData,
            postPayload: {
                ...state.postPayload,
                MSISDN: actionData
            }
        }
    }

    reducersByActions[ActionType.PostPayRetentionStatusRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            retentionStatus: {
                ...state.retentionStatus,
                loading: true,
                message: 'postpay-retention-validating'
            }
        }
    }

    reducersByActions[ActionType.PostPayRetentionStatusResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            numberDetailsErrorMessage: '',
            retentionStatus: {
                loading: false,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.PostPayCreateRetentionRequest] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: 'LebaraRetention'
        }
    }

    reducersByActions[ActionType.PostPayCreateRetentionResponse] = (state: PostPayState): PostPayState => {
        return {
            ...state,
            isSubmittingPostPay: null
        }
    }

    reducersByActions[ActionType.PostPayConfigResponse] = (state: PostPayState, actionData: any): PostPayState => {
        return {
            ...state,
            postpayConfig: actionData as IPostPayConfig
        }
    }

    reducersByActions[ActionType.CleanPostPay] = (): PostPayState => {
        return {
            ...INITIAL_STATE,
            postPayload: { ...INITIAL_STATE.postPayload }
        }
    }


    const reducer = (state = JSON.parse(JSON.stringify((INITIAL_STATE))), action: IAction): typeof INITIAL_STATE => {        
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer;
}

export default initReducer