import { IStorageService } from "@src/services/StorageService";
import moment from 'moment';
import { ActionReducer, IAction } from "../types";
import { ActionType } from "@src/dtos/enums";
import { WalletHistoryDto } from "@src/dtos/walletHistoryDto";

const INITIAL_STATE = {
    isFetchingSummary: false,
    isFetchingHistory: false,
    walletHistory: ([] as WalletHistoryDto),
    walletSummary: (undefined as any),
    isDownloading: false,
    isRecordOverload: false,
    filters: {
        dateFrom: moment().format('MM-YYYY') as string,
        toDate: moment().format('MM-YYYY') as string
    }
}

export type WalletHistoryState = typeof INITIAL_STATE
export type WalletHistoryFilter = typeof INITIAL_STATE.filters

export default function initReducer() {   

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.WalletHistoryUpdateFilter] = (state: WalletHistoryState, actionData: WalletHistoryFilter) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.WalletSummaryRequest] = (state: WalletHistoryState) => {
        return {
            ...state,
            isFetchingSummary: true,
        }
    }

    reducersByActions[ActionType.WalletSummaryResponse] = (state: WalletHistoryState, actionData: any) => {
        return {
            ...state,
            isFetchingSummary: false,
            walletSummary: actionData
        }
    }

    reducersByActions[ActionType.WalletHistoryRequest] = (state: WalletHistoryState) => {
        return {
            ...state,
            isFetchingHistory: true,
        }
    }

    reducersByActions[ActionType.WalletHistoryResponse] = (state: WalletHistoryState, actionData: any) => {
        return {
            ...state,
            isFetchingHistory: false,
            walletHistory: actionData.history,
            isRecordOverload: actionData.isRecordOverload
        }
    }

    reducersByActions[ActionType.WalletHistoryStartDownload] = (state: any, actionData: any) => {
        return {
          ...state,
          isDownloading: true
        }
      }
      reducersByActions[ActionType.WalletHistoryEndDownload] = (state: any, actionData: any) => {
        return {
          ...state,
          isDownloading: false
        }
      }

    reducersByActions[ActionType.CleanWalletHistory] = (state: WalletHistoryState) => {
        return {
            ...state,
            walletHistory: undefined,
            walletSummary: undefined
        }
    }

    reducersByActions[ActionType.Logout] = (state: WalletHistoryState) => {
        return INITIAL_STATE
    }

    const reducer = (state = INITIAL_STATE, action: IAction): WalletHistoryState => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    }


    return reducer;
}