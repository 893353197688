
import { useTranslation } from 'react-i18next';
import { Input, MenuItem } from '@mui/material';
import classNames from 'classnames';
import styles from './style.module.scss'
import { RootState } from '@src/modules/configureStore';
import { useSelector } from 'react-redux';
import StyledSelect from '../StyledSelect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LngSelect(props: { className?: string, IconComponent?: (arg: any) => JSX.Element, showAbbreviation?: boolean, noOutline?: boolean }) {
  const { i18n } = useTranslation('translations')
  const localization = useSelector(( state: RootState ) => state.localization)
  const { IconComponent } = props

  return (
    <StyledSelect
      className={props.className ?? ''}
      noOutline={props.noOutline}
      style={{ display: 'inline-block', width: 'auto'}}
      value={i18n.language || localization.language}
      onChange={(ev, val: any) => i18n.changeLanguage(val.props.value)}
      IconComponent={prop => IconComponent 
        ? <IconComponent {...prop} /> 
        : <ArrowDropDownIcon style={{ 
            position: 'absolute',
            right: '7px',
            top: 'calc(50% - .5em)',
            zIndex: '-1' 
          }} />}
      renderValue={Boolean(props.showAbbreviation) ? (value: any) => localization.lngList[value].abbreviation : undefined}
      // input={
      //   <Input
      //     className = "languageInput"
      //     name="age"
      //     id="outlined-age-simple"
      //   />
      // }
    >
    {localization&&Object.keys(localization.lngList).map((lng: any) =>
      <MenuItem value={lng} key={lng} className={styles.languageItem}>{localization.lngList[lng].name}</MenuItem>
    )}
    </StyledSelect>
  );
}

export default LngSelect