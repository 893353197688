import { ITopUpService } from "@src/services/TopUpService";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { IStandaloneTopUpService } from "@src/services/StandaloneTopUpService";
import { trackAssignBundle, trackWalletDebit } from "../analyticsTracking";

const initActions = function (topUpService: ITopUpService, standaloneTopUpService: IStandaloneTopUpService) {

    const setMsisdn = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (number: string) => {
        dispatch({ type: ActionType.MsisdnDataRequest, data: { number: number } })
        fetchMsisdnInfo(dispatch, getState().user.countryConfiguration.InternationalDialingCode + number, getState().user.countryConfiguration.InternationalDialingCode, i18n.language)
    }

    const setIccid = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (number: string, pretext: string) => {
        dispatch({ type: ActionType.IccidData, data: { number: number, pretext: pretext } })
    }

    const setDirectTopUpType = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (type: string) => {
        dispatch({ type: ActionType.DirectTopUpType, data: { type: type } })
    }

    const updateMsisdnInfo = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.MsisdnDataRequest, data: { number: getState().topUp.number } })
        fetchMsisdnInfo(dispatch, getState().user.countryConfiguration.InternationalDialingCode + getState().topUp.number, getState().user.countryConfiguration.InternationalDialingCode, i18n.language)
    }

    const fetchMsisdnInfo = (dispatch: AppDispatch, number: string, localeCode: string, locale: string) => {
        topUpService.fetchMsisdnBalance(number, locale)
            .subscribe(data => {
                if (data.ErrorCode) {
                    dispatch({
                        type: ActionType.MsisdnDataError, data: {
                            error: data.ErrorCode,
                            number: number
                        }
                    })
                } else {
                    dispatch({
                        type: ActionType.MsisdnDataResponse, data: {
                            balance: data.CurrentBalance,
                            balances: data.msisdnBalance ? data.msisdnBalance.balances : undefined,
                            products: data.msisdnBalance ? data.msisdnBalance.products : undefined,
                            queuedBundle: data.queuedBundle,
                            number: number,
                            localeCode: localeCode
                        }
                    })
                }
            }
            );
    };

    const msisdnValidationError = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (errorCode: string, number: string) => {
        dispatch({
            type: ActionType.MsisdnDataError, data: {
                error: errorCode,
                number: number
            }
        })
    }

    const applyDirectTopUp = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (inProgressTopUpId: string, inProgressTopUpName: string, inProgressTopUpCost: number, inProgressTopUpType: string, isLebaraOne: boolean, isBolton: boolean, basePrice: string, productType?: number) => {
        const body = {
            "ProductId": inProgressTopUpId,
            "Msisdn": getState().user.countryConfiguration.InternationalDialingCode + getState().topUp.number,
            "RequestedDateTime": (new Date()).toISOString(),
            "Denomination": basePrice,
            "Type": inProgressTopUpType,
            "IsStockEpin": false,
            "IssBuyAndPrint": false,
            "IsDirectTopUp": true,
            "IsLebaraOne": isLebaraOne,
            "IsBolton": isBolton,
            "ProductName": inProgressTopUpName
        }
        dispatch({ type: ActionType.ApplyTopUpRequest, data: { body, inProgressTopUpName, productType } })
        topUpRequest(dispatch, body, i18n.language, getState().topUp.topUpType, getState)
    }

    const applyICCIDDirectTopUp = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (inProgressTopUpId: string, inProgressTopUpName: string, inProgressTopUpCost: number, inProgressTopUpType: string, isLebaraOne: boolean, isBolton: boolean, basePrice: string, productType?: number) => {
        const body = {
            "ProductId": inProgressTopUpId,
            "ICCID": getState().topUp.iccidPretext + getState().topUp.iccidNumber,
            "RequestedDateTime": (new Date()).toISOString(),
            "Denomination": basePrice,
            "Type": inProgressTopUpType,
            "IsStockEpin": false,
            "IssBuyAndPrint": false,
            "IsDirectTopUp": true,
            "IsLebaraOne": isLebaraOne,
            "IsBolton": isBolton,
            "ProductName": inProgressTopUpName
        }
        dispatch({ type: ActionType.ApplyTopUpRequest, data: { body, inProgressTopUpName, productType } })
        topUpRequest(dispatch, body, i18n.language, getState().topUp.topUpType, getState)
    }

    const topUpRequest = (dispatch: AppDispatch, body: any, locale: string, topupType: string, getState: () => RootState) => {
        if (topupType === "MSISDN") {
            topUpService.applyTopUp(locale, JSON.stringify(body))
                .subscribe(
                    data => topUpResponseHandler(dispatch, getState)(data, topupType, body.Msisdn),
                    err => dispatch({ type: ActionType.ApplyTopUpResponse, data: { ErrorDescription: "topup-epinserror" } })
                );
        }
        else if (topupType === "ICCID") {
            topUpService.applyIccidTopUp(locale, JSON.stringify(body))
                .subscribe(
                    data => topUpResponseHandler(dispatch, getState)(data, topupType, body.Msisdn),
                    err => dispatch({ type: ActionType.ApplyTopUpResponse, data: { ErrorDescription: "topup-epinserror" } })
                );
        }
    };

    const applyInStockVoucher = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (StockEPIN: string, VoucherPIN: string, ProductName: string, Type: string, inProgressTopUpName: string, Denomination: string, productType?: number) => {
        const body = {
            StockEPIN,
            VoucherPIN,
            ProductName,
            Type,
            Denomination
        }
        dispatch({
            type: ActionType.ApplyVoucherStart, data: {
                data: body,
                inProgressTopUpName
            }
        })
        voucherRequest(dispatch, {
            ...body,
            MSISDN: getState().user.countryConfiguration.InternationalDialingCode + getState().topUp.number,
            Locale: i18n.language
        }, productType)
    }

    const voucherRequest = (dispatch: AppDispatch, request: any, productType?: number) => {
        topUpService.applyVoucher(request)
            .subscribe(
                data => dispatch({ type: ActionType.ApplyVoucherResponse, data: { ...data, productType } }),
                err => dispatch({ type: ActionType.ApplyVoucherResponse, data: { ErrorDescription: "topup-epinserror" } })
            );
    };

    const topUpResponseHandler = (dispatch: AppDispatch, getState: () => RootState) => (data: any, topUpType: string, msisdn: string) => {
        const { selectedCountry, userData } = getState().user

        if (data.State === 2) {
            if (topUpType === "MSISDN") {
                topUpService.getTransactionData(data.TransactionId, msisdn)
                    .subscribe(data => topUpResponseHandler(dispatch, getState)(data, topUpType, msisdn))
            }
            else if (topUpType === "ICCID") {
                topUpService.getTopupStatus(data.TransactionId)
                    .subscribe(data => topUpResponseHandler(dispatch, getState)(data, topUpType, msisdn))
            }
        }
        else if (data.State === 32) {
            dispatch({ type: ActionType.ApplyTopUpResponse, data: { ErrorDescription: data.ErrorDescription ? data.ErrorDescription : 'instanttopup-topupunsuccessful' } })
        }
        else if (data.State === 8) {
            dispatch({
                type: ActionType.ApplyTopUpResponse, data: {
                    creditUsed: data.CommissionUsed + data.CreditUsed,
                    msisdnBalance: data.MsisdnBalance,
                    transactionId: data.TransactionId,
                    errorDescription: data.ErrorDescription,
                    productAcquiredTime: data.ProductAcquiredTime
                }
            })
            trackWalletDebit(userData.AXCode[0], selectedCountry as string)
        }
    }

    const assignOptInBundle = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) =>
        (bundleId: string, alertNote: string | undefined | null, name: string | undefined | null, keyword?: string, id?: string, price?: any) => {
            if (!bundleId) {
                return;
            }
            dispatch({ type: ActionType.AssignBundleStart, data: { bundleId, alertNote, name, keyword, id, price } })
            const country = getState().user.countryConfiguration.CountryRegionId
            if (country === "DE" || country === "ES") {
                dispatch({ type: ActionType.InConfirmationMsisdn })
            } else if (alertNote) {
                dispatch({ type: ActionType.InAlertBundle })
            } else {
                sendAssignBundleRequest(dispatch, getState, i18n);
            }
        }

    const confirmMsisdn = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        if (getState().topUp.bundleAssignItem.alertNote) {
            dispatch({ type: ActionType.InAlertBundle });
        } else {
            sendAssignBundleRequest(dispatch, getState, i18n);
        }
    }

    const closeConfirmationMsisdn = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.CancelConfirmationMsisdn })
    }

    const confirmAlertBundle = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        sendAssignBundleRequest(dispatch, getState, i18n);
    }

    const sendAssignBundleRequest = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => {
        dispatch({ type: ActionType.AssignBundleRequest });
        const { bundleId: BundleId, price: Cost, keyword: Keyword, id: ProductId } = getState().topUp.bundleAssignItem
        assignBundleRequest(dispatch, getState, {
            MSISDN: getState().user.countryConfiguration.InternationalDialingCode + getState().topUp.number,
            Locale: i18n.language,
            BundleId, Keyword, Cost, ProductId
        });
    }

    const assignBundleRequest = (dispatch: AppDispatch, getState: () => RootState, request: any) => {        
        topUpService.assignBundle(request)
            .subscribe(data => {
                if (!data.ErrorCode) {
                    const { selectedCountry, userData } = getState().user
                    trackAssignBundle(userData.AXCode[0], selectedCountry as string)
                }
                dispatch({ type: ActionType.AssignBundleResponse, data: { ...data, MSISDN: request.MSISDN } })
            });
    };

    const closeAlertBundle = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.CloseAlertBundle })
    }

    const finishAssignBundleTransaction = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
        dispatch({ type: ActionType.FinishAssignBundleTransaction })
    }

    const finishTopUpTransaction = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.FinishTopUpTransaction })
    }

    const cleanError = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.TopUpCleanErorr })
    }

    const cleanClientData = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanClientData })
    }
    const finishVoucherTransaction = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.FinishVoucherTransaction })
    }

    const inTopUpVoucher = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.InTopUpVoucher })
    }

    const closeTopUpVoucher = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CloseTopUpVoucher })
    }

    const applyVoucherNumber = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (voucherNumber: number) => {
        dispatch({ type: ActionType.TopUpVoucherNumberRequest })
        topUpService.topUpVoucherNumber(voucherNumber, getState().user.countryConfiguration.InternationalDialingCode + getState().topUp.number, i18n.language)
            .subscribe(
                data => dispatch({ type: ActionType.TopUpVoucherNumberResponse, data }),
                err => dispatch({ type: ActionType.TopUpVoucherNumberResponse, data: { ErrorDescription: "topup-epinserror" } })
            );
    }

    const applyDigitalPin = (dispatch: AppDispatch, getState: () => RootState) => (digitalpin: string) => {
        const data = {
            MSISDN: getState().user.countryConfiguration.InternationalDialingCode + getState().topUp.number,
            PIN: digitalpin,
            Type: 'LR'
        }

        dispatch({ type: ActionType.TopUpVoucherNumberRequest })

        standaloneTopUpService.applyVoucher(data)
            .subscribe(
                data => dispatch({ type: ActionType.TopUpVoucherNumberResponse, data: { ...data, ErrorDescription: data.Status ? '' : `digitalpin_${data.Message.toLowerCase()}`, type: 'digitalpin' } }),
                err => dispatch({ type: ActionType.TopUpVoucherNumberResponse, data: { ErrorDescription: "topup-epinserror" } })
            )
    }

    const finishTopUpVoucherNumber = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.TopUpVoucherNumberFinish })
    }

    return {
        setMsisdn,
        setIccid,
        setDirectTopUpType,
        updateMsisdnInfo,
        msisdnValidationError,
        applyDirectTopUp,
        applyICCIDDirectTopUp,
        finishTopUpTransaction,
        applyInStockVoucher,
        finishVoucherTransaction,
        cleanClientData,
        cleanError,
        assignOptInBundle,
        confirmMsisdn,
        closeConfirmationMsisdn,
        confirmAlertBundle,
        closeAlertBundle,
        finishAssignBundleTransaction,
        inTopUpVoucher,
        closeTopUpVoucher,
        applyVoucherNumber,
        finishTopUpVoucherNumber,
        applyDigitalPin
    };
};

export default initActions;