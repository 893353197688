import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from '../interceptor'
import { CountryListDto } from '@src/dtos/countryListDto'
import { Observable } from 'rxjs'


const UserService = (ajax: ajaxType) => {return {
    login: ( login:string, password:string, country: string, captchaToken: string, siteKey: string) => {
        return ajax.post(`${config.url.API_URL}account/login`, {
            UserName:login,
            Password:password,
            CountryCode:country,
            captchaToken,
            CaptchaSiteKey: siteKey,
            CaptchaAction: 'LOGIN',
            IsEnterprise: config.captcha.CAPTCHA_MODE === 'enterprise'
        },
        {"Content-Type": "application/json"}).pipe(map(res => res.response))
    },

    resetPassword: ( login:string, country: string ) => {
        return ajax.post(`${config.url.API_URL}account/resetpassword`, {
            UserName:login,
            CountryCode:country
        },
        {"Content-Type": "application/json"}).pipe(map(res => res.response))
    },

    getCountries: (): Observable<CountryListDto> => {
        return ajax.get(`${config.url.API_URL}country/getall`).pipe(map(res => res.response))
    },
    getUserProfile: () => {
        return ajax.get(`${config.url.API_URL}Profile/Get`).pipe(map(res => res.response))
    },
    changePassword: () => {
        return ajax.get(`${config.url.API_URL}Profile/ResetPassword?isForceReset=false`).pipe(map(res => res.response))
    },
    getCountryConfiguration: (country: string) => {
        return ajax.get(`${config.url.API_URL}country/configuration/${country}`).pipe(map(res => res.response))
    },
    
    logout: () => {
        return ajax.post(`${config.url.API_URL}Account/LogOut`).pipe(map(res => res.response))
    },
    updateProfile: (data: any) => {
        return ajax.put(
           `${config.url.API_URL}Profile/Update`, 
            data, 
            {"Content-Type": "application/json"}).pipe(map(res => res.response))
    },
    downloadTc: (locale: string) => {
        return ajax.get(`${config.url.API_URL}Profile/TC`).pipe(map(res => res.response))
    },
    downloadCTC: (locale: string) => {
        const url = `${config.url.API_URL}Profile/DownloadCTC`;
        const headers = {
            'Content-Type': 'application/octet-stream'
        }
        return ajax.get(
            url,
            headers
        ).pipe(map(res => {
            return res.response
        }))
    },
    paymentLogo: () => {
        return ajax.get(`${config.url.API_URL}Payment/Logo`).pipe(map(res => res.response))
    },
    recaptchaSiteKey: () => {
        return ajax.get(`${config.url.API_URL}account/SiteKey`).pipe(map(res => res.response))
    }
}}

export type IUserService = ReturnType<typeof UserService>


export default UserService