// import { map } from 'rxjs/operators'
// import { ajaxType } from '../interceptor'


// const PrepaidPortalService = (ajax: ajaxType) => {
//     return {
//         generalGetRequest: (url: string) => {
//             return ajax.get(url)
//                 .pipe(map(res => res?.response))
//         },
//         generalPostRequest: (url: string, payload: any) => {
//             return ajax.post(url, payload, { "Content-Type": "application/json" })
//                 .pipe(map(res => res?.response))
//         },
//         generalPutRequest: (url: string, payload: any) => {
//             return ajax.put(url, payload, { "Content-Type": "application/json" })
//                 .pipe(map(res => res?.response))
//         }
//     }
// }

// export type IPrepaidPortalService = ReturnType<typeof PrepaidPortalService>
// export default PrepaidPortalService


import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"

const PrepaidPortalService = (ajax: ajaxType) => {
    return {
        getSessionId: () => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/GetSessionId`, {
                'Content-Type': 'text/plain; charset=utf-8',
            }, 'text')
                .pipe(map(res => res?.response));
        },
        generalGetRequest: (url: string) => {
            return ajax.get(url)
                .pipe(map(res => res?.response))
        },
        generalPostRequest: (url: string, payload: any) => {
            return ajax.post(url, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res?.response))
        },
        generalPutRequest: (url: string, payload: any) => {
            return ajax.put(url, payload, { "Content-Type": "application/json" })
                .pipe(map(res => res?.response))
        },

        fetchOperators: (sessionId: string, locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/NetworkOperators/${sessionId}/${locale}`)
                .pipe(map(res => res.response));
        },
        fetchNonPortInDates: (sessionId: string, locale: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/NonPortInDates/${sessionId}/${locale}`)
                .pipe(map(res => res.response));
        },
        verifyRioCode: (msisdn: string, rioCode: string, language: string) => {
            return ajax.post(
                `${config.url.API_URL}Transaction/VerifyRIOCode`,
                {
                    MSISDN: msisdn,
                    RioCode: rioCode,
                    Locale: language
                },
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },
        validateSim: (pukCode: string, iccid: string, locale: string) => {
            return ajax.post(
                `${config.url.API_URL}Transaction/ValidateSIM`,
                {
                    PUKCode: pukCode,
                    Iccid: iccid,
                    Locale: locale
                },
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },
        validateCustomer: (ICCID: string, FirstName: string, LastName: string, DOB: string | null, EmailAddress: string) => {
            return ajax.post(
                `${config.url.API_URL}Transaction/Customer/validate`,
                {
                    ICCID: ICCID,
                    FirstName: FirstName,
                    LastName: LastName,
                    DOB: DOB,
                    EmailAddress: EmailAddress
                },
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },
        submitPortInDE: (payload: {
            Locale: string,
            requestedMsisdn: string,
            newIccid: string,
            donorOperator: string | undefined,
            isOnContract: Boolean,
            requestedDate: string | null,
            retailerId: string,
            crmId: string,
            orderId: string,
            useTempSimByIccid: Boolean,
            TemporaryMsisdn: string
        }) => {
            return ajax.post(
                `${config.url.API_URL}Transaction/SubmitPortIn`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },
        submitPortInFr: (payload: {
            Locale: string,
            requestedMsisdn: string,
            Iccid: string,
            orderId: string,
            retailerId: string,
            crmId: string,
            Riocode: string
        }) => {
            return ajax.post(
                `${config.url.API_URL}Transaction/SubmitPortIn/fr`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },
        updateCustomerDetails: (subscriberId: string, emailAddress: string) => {
            return ajax.put(
                `${config.url.API_URL}Transaction/UpdateCustomer`,
                {
                    SubscriberId: subscriberId,
                    EmailAddress: emailAddress
                },
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },

        generateOTAG: (payload: any) => {
            return ajax.post(`${config.url.API_URL}PostPayPortIn/GenerateOTAG`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res.response));
        },
        validateOTAG: (sessionId: string, msisdn: string, otp: string) => {
            return ajax.get(`${config.url.API_URL}PostPayPortIn/ValidateOTAG/${sessionId}/${msisdn}/${otp}`)
                .pipe(map(res => res.response));
        },

        submitPortInNL: (payload: {
            Locale : string | null ,
            CrmId : string | null ,
            RequestedMsisdn : string | null ,
            TemporaryMsisdn : string | null ,
            NewIccid : string | null ,
            RequestedDate : string | null ,
            IsOnContract : Boolean | null ,
            OrderId : string | null ,
            PukCode : string | null ,
            RetailerId : string | null ,
            FirstName : string | null ,
            LastName : string | null ,
            Street : string | null ,
            HouseNumber : string | null ,
            HouseNumberExt : string | null ,
            ZipCode : string | null ,
            City : string | null ,
            Email : string | null            
        }) => {
            return ajax.post(
                `${config.url.API_URL}Transaction/SubmitPortIn/nl`,
                payload,
                { "Content-Type": "application/json" }
            ).pipe(map(res => res?.response));
        },
    }
}

export type IPrepaidPortalService = ReturnType<typeof PrepaidPortalService>
export default PrepaidPortalService;