import { IWalletService } from "@src/services/WalletService";
import { map } from "rxjs/operators";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";

let walletDataFetchInProgress = false

const initActions = function (walletService: IWalletService) {
  const fetchWalletInfo = (dispatch: AppDispatch) => () => {
    if (walletDataFetchInProgress) return
    else walletDataFetchInProgress = true
    dispatch({ type: ActionType.WalletDataRequest })
    var walletData: any;
    walletService.fetchWalletInfo()
      .pipe(map(data => {
        if (data) {
          walletData = data;
          return {
            balance: data.Balance,
            lastUpdated: data.LastUpdated,
            isLocked: data.IsLocked,
            commission: data.Commission,
            credit: data.Credit,
            isCreditFreezed: data.IsCreditFreezed
          }
        } else {
          return undefined
        }
      }))
      .subscribe({
        next: data => {
          dispatch({ type: ActionType.WalletDataResponse, data: { data: data, walletData: walletData } })
          walletDataFetchInProgress = false
        },
        error: err => {
          console.log(err)
          walletDataFetchInProgress = false
        }
      });
  };

  const fetchCreditTiles = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.WalletCreditTilesRequest })
    walletService.fetchCreditTiles()
      .pipe(map(data => {
        if (data) {
          return {
            Denominations: data.Denominations,
            MaxLimitToAddCredit: data.Configuration.MaxCreditToAdd,
            MinLimitToAddCredit: data.Configuration.MinCreditToAdd,
            IsCreditFreezed: data.IsCreditFreezed,
            APDiscountMessage: data.APDiscountMessage,
            ShowCreditTiles: data.IsShowCreditTiles,
            ShowAddCreditText: data.Configuration.ShowAddCreditText,
            MaxWalletCreditLimit: data.Configuration.MaxWalletCreditLimit
          }
        } else {
          return undefined
        }
      }))
      .subscribe(data => dispatch({ type: ActionType.WalletCreditTilesResponse, data: data }));
  };

  const addWalletDetails = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (denomination: number) => {
    dispatch({ type: ActionType.AddWalletDetailsRequest, data: denomination })

    const advancePaymentDiscount = getState().user.countryConfiguration.AdvancePaymentDiscount
    let discountValue = 0;
    if (advancePaymentDiscount) {
      discountValue = denomination * advancePaymentDiscount / 100
      denomination = denomination - discountValue
    }

    walletService.paymentCreditDetails(Number(denomination.toFixed(2)), discountValue, i18n.language)
      .pipe(map(data => {
        if (data) {
          return data
        } else {
          return undefined
        }
      }))
      .subscribe(data => dispatch({ type: ActionType.AddWalletDetailsResponse, data: data }));
  };

  const proceeedCreditPayment = (dispatch: AppDispatch, getState: () => RootState) => () => {
    const OnlinePaymentDetails = getState().wallet.creditDetails.OnlinePaymentDetails
  }

  const getCreditComplete = (dispatch: AppDispatch) => (transactionId: string) => {
    dispatch({ type: ActionType.CreditCompleteRequest })
    walletService.creditComplete(transactionId)
      .pipe(map(data => {
        if (data) {
          return {
            ActualAmount: data.ActualAmount,
            Amount: data.Amount,
            OldBalance: data.OldBalance,
            TransactionID: data.TransactionID
          }
        } else {
          return undefined
        }
      }))
      .subscribe(data => {
        fetchWalletInfo(dispatch)()
        dispatch({ type: ActionType.CreditCompleteResponse, data: data })
      });
  }
  const lockEmployeeWallet = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.WalletDataRequest })
    walletService.employeeWalletLock(true)
    .pipe(map((data) => {
      return data
    }))
    .subscribe(res => {
      dispatch({type: ActionType.EmployeeWalletLock, data: res});
    }
    )
  }
  const unLockEmployeeWallet = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.WalletDataRequest })
    return new Promise((resolve) => {
      walletService.employeeWalletLock(false)
      .pipe(map((data) => {
        return data
      }))
      .subscribe(res => {
        dispatch({type: ActionType.EmployeeWalletLock, data: res});
        resolve(res);
      })
    })
  }

  return { fetchWalletInfo, fetchCreditTiles, addWalletDetails, proceeedCreditPayment, getCreditComplete, lockEmployeeWallet, unLockEmployeeWallet };
};

export default initActions;