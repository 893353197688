import { AlertTitle, styled } from "@mui/material"
import { Alert, AlertProps } from "@mui/lab"
import InfoIcon from '@src/res/info.svg?react'
import { withStyles } from "tss-react/mui"

const StyledAlert = withStyles(
  Alert,
  {
    root: {
      padding: '6px 7px',
      border: 0
    },
    message: {
      padding: '2px 0 2px 18px',
      position: 'relative',
      color: '#0094F6'
    },
    icon: {
      padding: 0,
      margin: 0
    }
  })

type IInfo = {
  children: string
} & AlertProps

const Info = (props: IInfo) => {
  return (

    (<StyledAlert
      {...props}
      severity="info"
      variant={props.variant ?? "outlined"}
      // icon={false}
      iconMapping={{
        // info: <img src={infoIcon} />
        info: <InfoIcon />
      }}
    >
      {props.children}
    </StyledAlert>)
  );
}

export default Info