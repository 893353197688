import React from 'react';
import styles from './style.module.scss'
import spinner from '@src/res/loading.svg?url'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  inProgress: boolean
  text?: string
  children?: React.ReactNode
  className?: string
  containerStyle?: React.CSSProperties
}

function Loading(prop: Props) {
  const { t } = useTranslation('translations')

  return (
    <div className={styles.container}>
      <div className={styles.content} style={{...prop.containerStyle}}>
        {prop.children}
      </div>
      {prop.inProgress&&
        <div className={classNames(prop.className, styles.spinner)}>
          {prop.text && <span className={styles.loadingLabel}>{prop.text}</span>}          
          <img src={spinner}  alt="..."/>
          {/* <Spinner /> */}
          <div>{t('logon-submit-loading')}</div>
        </div>
      }
    </div>
  );
}

export default Loading
