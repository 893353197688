import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
// import { i18n } from "i18next"


const initActions = function () {

    const pageview = (dispatch: AppDispatch, getState: () => RootState) => (pathname: string, search: string | undefined) => {
        const router = getState().router
        dispatch({ type: ActionType.GAPageview, data: { pathname, search, history: router } })
    }

    return { pageview };
};

export default initActions;