import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { Observable } from "rxjs"
import { ConsumerPromotionDto } from "@src/dtos/consumerPromotionDto"

const PromotionService = (ajax: ajaxType) => {
    return {
        fetchPromotionInfo: (): Observable<ConsumerPromotionDto> => {
            return ajax.get(`${config.url.API_URL}ConsumerPromotion/Promotion`).pipe(map(res => res.response))
        }
    }
}

export type IPromotionService = ReturnType<typeof PromotionService>

export default PromotionService