
import { Route, Routes } from 'react-router' // react-router v4/v5
import Home from './View/Home'
import TermsAndConditions from './View/TermsAndConditions'
import MarketingPopup from './View/MarketingPopup'
import Login from './View/Login/Login'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from './modules/configureStore'
// import Topup from './View/Topup/Topup'
import { Page } from './Page'
import TagManager from 'react-gtm-module'
import config from './env'
import { useMemo } from 'react'
import { PageName } from './dtos/enums'

const tagManagerArgs = {
	gtmId: config.ga.GTM
}
TagManager.initialize(tagManagerArgs)

function App() {
	// const { t } = useTranslation('translation')

	const user = useSelector((state: RootState) => state.user)
	const { location } = useSelector((state: RootState) => state.router)
	console.log('location =>', location)
	const RedirectToLogin = useMemo(() => {
		return !user.token ? <Route path="*" element={<Navigate to="/login" state={{ from: location }} replace />} /> : <></>
	}, [user.token])

	const RedirectToDashboard = useMemo(() => {
		console.log('rendering RedirectToDashboard')
		if (user.token && user.termsAndConditionsData && !user.termsAndConditionsData.IsFileAvailable && user.marketingPopupData && !user.marketingPopupData.IsFileAvailable) {
			return ["/", "/login", "/login/*", "/termsandconditions", "/marketing"].map(path => (
				<Route path={path} key={"dashboard"} element={<Navigate to="/dashboard" replace />} />
			))
		} else {
			return null
		}
	}, [user.token, user.termsAndConditionsData, user.marketingPopupData])

	const RedirectToTnC = useMemo(() => {
		console.log('rendering RedirectToTnC')
		if (user.token && user.termsAndConditionsData && user.termsAndConditionsData.IsFileAvailable) {
			return ["/", "/login", "/login/*"].map(path => (
				<Route path={path} key="termsandconditions" element={<Navigate to="/termsandconditions" replace />} />							
			))
		} else {
			return null
		}
	}, [user.token, user.termsAndConditionsData])

	const RedirectToMarketing = useMemo(() => {
		console.log('rendering RedirectToMarketing')
		if (user.token && user.marketingPopupData && user.marketingPopupData.IsFileAvailable && user.termsAndConditionsData && !user.termsAndConditionsData.IsFileAvailable) {
			return ["/", "/login", "/login/*", "/termsandconditions"].map(path => (
				<Route path={path} key="marketing" element={<Navigate to="/marketing" replace />} />
			))
		} else {
			return null
		}
	}, [user.token, user.marketingPopupData, user.termsAndConditionsData])

	// const LoginRoutes = useMemo(() => ["/login", "/login/*"].map(path => (<Route path={path} key="login" element={<Page title={PageName.login}><Login /></Page>} />)), [])

	return (
		<>
			{/* <a className="start_chat_button" href="#"><img id="BotImgId" style={{ border: 0 }} /></a> */}
			<Routes>
				{/* {
					["/topup", "/topup/*"].map(path => (
						<Route path={path} element={<Page title={PageName.topup}><Topup /></Page>} />
					))
				} */}
				
				{RedirectToDashboard}
				{RedirectToTnC}
				{RedirectToMarketing}
				{/* <Route path={["/login", "/login/*"]} component={Login} /> */}
				{/* {LoginRoutes} */}
				<Route path={"/login"} key="login" element={<Page title={PageName.login}><Login /></Page>} />
				<Route path={"/login/*"} key="login" element={<Page title={PageName.login}><Login /></Page>} />
				<Route path={"/account/*"} key="login" element={<Page title={PageName.resetPassword}><Login /></Page>} />
				{user.token && user.termsAndConditionsData && user.termsAndConditionsData.IsFileAvailable && <Route path="/termsandconditions" element={<Page title={PageName.termsAndConditions}><TermsAndConditions /></Page>} />}
				{user.token && user.marketingPopupData && user.marketingPopupData.IsFileAvailable && <Route path="/marketing" element={<Page title={PageName.marketing}><MarketingPopup /></Page>} />}
				{RedirectToLogin}
				{user.token && <Route path="*" element={<Home />} />}
			</Routes>
		</>
	)
}

export default App
