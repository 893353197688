import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { CommissionDto } from '@src/dtos/comissionDto';

export interface MyCommissionSetFilters {
  status: 'All' | 'Mobile' | 'Play' | 'Talk'
  dateFrom?: Date | null
  toDate?: Date | null
}

export interface MyCommissionState {
  fetchInProgress: boolean,
  commissionData: CommissionDto | undefined,
  filters: MyCommissionSetFilters,
  commissionsummaryData: any,
}

const configureCommissionModule = (services: IServices) => {
  const actions = initActions(services.commissionService);
  const reducer = initReducer();

  return { actions, reducer };
};

export type CommissionReducer = ReturnType<typeof initReducer>
export type CommissionActions = ReturnType<typeof initActions>

export default configureCommissionModule;