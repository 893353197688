import { dispatch } from "rxjs/internal/observable/pairs";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { ISimRegistrationService } from "@src/services/SimRegistration";
import SimRegistrationService from "@src/services/SimRegistration";
import { i18n } from "i18next";

const initActions = (SimRegistrationService: ISimRegistrationService) => {

    // const getDoc = (dispatch: AppDispatch) => {
    //     dispatch({ type: ActionType.SigaSimRegGetDocumentRequest })
    //     SimRegistrationService.getDocument().subscribe(data => {
    //         dispatch({ type: ActionType.SigaSimRegGetDocumentResponse,data })
    //     })
    // }

    const validatePuk = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (pukCode: string, iccid: string) => {
        dispatch({ type: ActionType.SigaSimRegValidatePukRequest })        
        SimRegistrationService.validatePuk(pukCode, iccid, i18n.language).subscribe(data => {
            dispatch({ type: ActionType.SigaSimRegValidatePukResponse, data })
        })
    }

    const submitSigaSimReg = (dispatch: AppDispatch) => (payload: any) => {
        dispatch({ type: ActionType.SigaSimRegFormSubmitRequest })        
        SimRegistrationService.submitSigaSimReg(payload).subscribe(data => {    
            if(data === null)
            {
                dispatch({ type: ActionType.SigaSimRegFormSubmitResponse, data: {
                    isSuccess: false,
                    errorMessage: ''
                } })
            }
            else
            {
                dispatch({ type: ActionType.SigaSimRegFormSubmitResponse, data })
            }
            
        })
    }

    const resetValidPukState = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.SigaSimRegResetValidPuk })
    }

    const resetSigSimFormState = (dispatch: AppDispatch) => () => {  
        setTimeout(() => {
            dispatch({ type: ActionType.SigaSimRegResetRegister });
          }, 500);
    }

    const generalGetRequest = (dispatch: AppDispatch) => (url: string) => {
        return SimRegistrationService.generalGetRequest(url)
    }

    return {
        validatePuk,
        submitSigaSimReg,
        resetValidPukState,
        resetSigSimFormState,
        generalGetRequest
        // getDoc
    }
}

export default initActions