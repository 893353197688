import { ActionType } from "@src/dtos/enums";
import { IPrintHistoryState } from ".";
import { ActionReducer, IAction } from "../types";

const INITIAL_STATE: IPrintHistoryState = {
  history: [],
  fetchInProgress: false,
  printDataFetchInProgress: false,
  lastPrintData: undefined,
  isPrinting: false,
  epinsInRecord: [],
};

const initReducer = function () {

  let reducersByActions = {} as Partial<ActionReducer>

  reducersByActions[ActionType.PrintHistoryRequest] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      fetchInProgress: true,
      history: []
    };
  }
  reducersByActions[ActionType.PrintHistoryResponse] = (state: IPrintHistoryState, actionData: { data: typeof INITIAL_STATE.history }): IPrintHistoryState => {
    return {
      ...state,
      fetchInProgress: false,
      history: actionData.data,
    };
  }
  reducersByActions[ActionType.PrintHistoryPrintOutRequest] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      printDataFetchInProgress: true,
      lastPrintData: undefined
    };
  }
  reducersByActions[ActionType.PrintHistoryPrintOutResponse] = (state: IPrintHistoryState, actionData: any): IPrintHistoryState => {
    return {
      ...state,
      printDataFetchInProgress: false,
      lastPrintData: actionData.data,
      isPrinting: false
    };
  }
  reducersByActions[ActionType.CleanPrintHistory] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      fetchInProgress: false,
      history: [],
      lastPrintData: undefined
    };
  }
  reducersByActions[ActionType.CleanPrintData] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      lastPrintData: undefined
    };
  }
  reducersByActions[ActionType.PrintHistoryReprintEpinRequest] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      isPrinting: true
    };
  }
  reducersByActions[ActionType.PrintHistoryReprintEpinRequestCompletion] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      isPrinting: false
    };
  }
  reducersByActions[ActionType.AddRecordEpinsPrintHistory] = (state: IPrintHistoryState, actionData: any): IPrintHistoryState => {
    return {
      ...state,
      epinsInRecord: actionData
    }
  }
  reducersByActions[ActionType.RemoveRecordEpinsPrintHistory] = (state: IPrintHistoryState): IPrintHistoryState => {
    return {
      ...state,
      epinsInRecord: []
    }
  }
  reducersByActions[ActionType.Logout] = (): IPrintHistoryState => {
    return INITIAL_STATE
  }
  const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
    return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
  };


  return reducer;
};

export default initReducer;