import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { FiltersDto } from "@src/dtos/filtersDto"
import { filterEncodedStr } from "@src/utils"
import { Observable } from "rxjs"
import { MonthlyInvoiceDto } from "@src/dtos/monthlyInvoiceDto"

const SuperInvoiceService = (ajax: ajaxType) => {return{
    fetchSuperInvoice: (filters: FiltersDto): Observable<MonthlyInvoiceDto> => {
        return ajax.get(`${config.url.API_URL}Order/Invoices?${filterEncodedStr(filters)}`)
        // return ajax.get(`${config.url.API_URL}Order/Invoices?startdate=${filters.dateFrom}&enddate=${filters.toDate}&type=${filters.type}`)
            .pipe(map(res => res.response))
    }
}}

export type ISuperInvoiceService = ReturnType<typeof SuperInvoiceService>

export default SuperInvoiceService