import { IAlertsService } from "@src/services/AlertsService";
import { IStorageService } from "@src/services/StorageService";
import { map } from "rxjs/operators";
import { AppDispatch } from "../configureStore";
import { ActionType } from "@src/dtos/enums";


const initActions = function (alertsService: IAlertsService, storageService: IStorageService) {

  const fetchAlerts = (dispatch: AppDispatch) => () => {

    const alertData = storageService.get('alertData')
    if(alertData){
      dispatch({type: ActionType.AlertsDataResponse, data: JSON.parse(alertData)})
      return
    }

    dispatch({type: ActionType.AlertsDataRequest})
    alertsService.fetchAlerts()
      .pipe(map((data: any[]) => {
        if (data){
          return data.map((alertItem: any) => {
            return {
              BodyText: (alertItem.BodyText && alertItem.BodyText.includes('AlertImage')) ? alertItem.BodyText : null,
              NavigateUrlData: alertItem.NavigateUrlData,
              Title: alertItem.Title,
              ModalPopupData: alertItem.ModalPopupData,
              UploadedDocUrl: alertItem.UploadedDocUrl
            }            
          }).filter((alertItem: any) => alertItem.BodyText)
           
        } else {
          return null
        }
      }))
      .subscribe(data => {
        window.localStorage.setItem('alertData', JSON.stringify(data))
        dispatch({type: ActionType.AlertsDataResponse, data: data})
      });
    };
    
    return { fetchAlerts }; 
  };
  
  export default initActions;