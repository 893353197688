import { History, createBrowserHistory  } from 'history'
import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext } from "redux-first-history";
import { Reducers } from '.';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: createBrowserHistory(),
  //other options if needed 
});

const configureStore = (serviceReducers: Reducers) => {
  // const store = createStore(
  //   createRootReducer(history, serviceReducers), // root reducer with router state
  //   {},
  //   compose(
  //     applyMiddleware(routerMiddleware),
  //   ),
  // )
  const store = createStore(
    combineReducers({
      router: routerReducer,      
      ...serviceReducers
    }),
    {},
    composeWithDevTools(
      applyMiddleware(routerMiddleware),
    )
  );

  const reduxHistory = createReduxHistory(store as any)

  return { store, reduxHistory }
}

type ConfigStoreType = ReturnType<typeof configureStore>
let configStore: ConfigStoreType
export type StoreType = typeof configStore.store
export type RootState = ReturnType<typeof configStore.store.getState>
export type AppDispatch = typeof configStore.store.dispatch

export default configureStore