import { map } from "rxjs/operators";
import { i18n } from "i18next";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { ISuperInvoiceService } from "@src/services/SuperInvoiceService";
import { SuperInvoiceFilter, SuperInvoiceState } from './reducer'
import { stdDateString } from "@src/utils";

export default function initActions (superInvoiceService: ISuperInvoiceService) {

    const setInvoiceFilter = (dispatch: AppDispatch, getState: () => RootState) => (filters: SuperInvoiceFilter) => {
        dispatch({ type: ActionType.SuperInvoiceUpdateFilter, data: filters })
        fetchSuperInvoice(dispatch, getState)()
    }

    const fetchSuperInvoice = (dispatch: AppDispatch, getState: () => RootState) => () => {
        dispatch({ type: ActionType.SuperInvoiceRequest })
        superInvoiceService.fetchSuperInvoice(
            stdDateString(getState().superInvoice.filters)
        ).subscribe({
            next: res => dispatch({ type: ActionType.SuperInvoiceResponse, data: res }),
            error: err => dispatch({ type: ActionType.SuperInvoiceResponse, data: undefined })
        })
    }

    const cleanSuperInvoice = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.CleanSuperInvoice })
    }

    return { setInvoiceFilter, fetchSuperInvoice, cleanSuperInvoice }
}