import { IServices } from "@src/services";
import initActions from "./actions";
import initReducer from "./reducer";

export interface Permissions {
        employeePermission: string[],
        parentPermission: string[]
}

export interface CreateEmpSuccess{
    email: string,
    name: string,
    accId: string
}

export interface EmployeeState {
    allEmployees: any[],
    loading: boolean,
    employeeDetail: any,
    createEmployeePayload: any,
    employeeChangeStatus: any,
    updateEmployee: any,
    permissions: Permissions,
    updatePermissionStatus: any,
    employeeCreateSuccessDetails: any
}

const configureEmployeeModule = (services: IServices) => {
    const actions = initActions(services.employeeService);
    const reducer = initReducer();

    return { actions, reducer };
}

export type EmployeeReducer = ReturnType<typeof initReducer>
export type EmployeeActions = ReturnType<typeof initActions>
export default configureEmployeeModule