import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { FaqState } from './index'

const initReducer = function () {
    const INITIAL_STATE: FaqState = {
        fetchInProgress: false,
        faq: [],
    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.FaqRequest] = (state: FaqState) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }
    reducersByActions[ActionType.FaqResponse] = (state: FaqState, actionData: any) => {
        return {
            ...state,
            fetchInProgress: false,
            faq: actionData
        }
    }



    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer;
}

export default initReducer