import moment from "moment";
import { FiltersDto } from "./dtos/filtersDto";

export const isNullorUndefined = (d: any) => d === undefined || d === null

export const stdDateString = (obj: any, excludeDay?: boolean) => {
    const returnData = {...obj}
    for(let key in returnData) {
        if ((new RegExp('date', 'i')).test(key) || returnData[key] instanceof Date) {
            returnData[key] = moment(returnData[key]).format(excludeDay ? 'YYYY-MM' : 'YYYY-MM-DD')
        }
    }

    return returnData as FiltersDto
}

export const filterEncodedStr = (filters: FiltersDto) => Object.keys(filters).reduce((acc, curr) => {
    if (!isNullorUndefined(filters[curr as keyof typeof filters])) {
        acc += `${!!acc ? '&' : ''}${curr}=${encodeURIComponent(filters[curr as keyof typeof filters] ?? '')}`
    }
    return acc
}, '')