import React from 'react';
import './index.scss';
import '@adyen/adyen-web/dist/adyen.css';
import { Provider } from 'react-redux';
import './i18n';
import configureModules from './modules';
import configureServices from './services';
import i18n from './i18n';
import ServiceRegistry, { HttpService } from './context/serviceRegistry';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { HistoryRouter as ConnectedRouter } from "redux-first-history/rr6";
import { createRoot } from 'react-dom/client'
import { Route, Routes } from 'react-router-dom';
import App from './App';


(async function init() {
  const services = configureServices()
  const { store, reduxHistory } = await configureModules(services, i18n)
  
  createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={reduxHistory}>
          <ServiceRegistry.Provider value={{ httpService: new HttpService(services) }}>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="*" element={<App />} />
              </Routes>
            </ThemeProvider>
          </ServiceRegistry.Provider>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
  )
})()
