import React from 'react';
import styles from './style.module.scss'
import OldStyleContainer from '@src/View/Widgets/OldStyleContainer';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState } from '@src/modules/configureStore';

import docIcon from '@src/res/file-text.svg?url'
import logo from '@src/res/printLogo.png'
import classnames from 'classnames';
import { useAction } from '../Hooks/useAction';

function MarketingPopup() {
  const user = useSelector((state: RootState) => state.user)

  const { t } = useTranslation('translations')

  const read = useAction(actions => actions.user.readMarketingPopup)
  const readLater = useAction(actions => actions.user.readLaterMarketingPopup)

  return (
    <div className={styles.appContainer}>     
      <div className={styles.header}>
        <img src={logo} alt="" />
        <span className={styles.userInfo}>{`${user.userData.userName} (${user.userData.AXCode})`}</span>
      </div>       
      <div className={styles.contentContainer}>        
        <OldStyleContainer 
          label = {t('marketingalert-headermessage')}
          content = {<img src={docIcon}  alt="..."/>}
        />
        <span className={styles.welcomeText} dangerouslySetInnerHTML={{__html: t('marketingalert-mainmessage')}}></span>
        <div className={styles.iframeContainer}>
          <iframe title='trems & conditions' src={user.marketingPopupData.FilePath} height="100%" width="100%"></iframe>
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.rightButtonsContainer}>
            <div className={classnames(styles.button, styles.primary)} onClick={read} >{t('useralert-read')}</div>
            <div className={classnames(styles.button, styles.secondary)} onClick={readLater} >{t('useralert-readlater')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingPopup
