
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";

const initReducer = function () {
    const INITIAL_STATE = {
        sessionId: ''
    }

    let reducersByActions:  any = []

    reducersByActions[ActionType.MigrationGetSessionId] = (state = INITIAL_STATE, actionData:any) => {
        return{
            ...state,
           loading: true
        }
    }

    reducersByActions[ActionType.MigrationGetSessionIdResponse] = (state = INITIAL_STATE, actionData:any) => {
        return{
            ...state,
           loading: false,
           sessionId: actionData
        }
    }

    reducersByActions[ActionType.MigrationResetSessionId] = (state = INITIAL_STATE, actionData:any) => {
        return{
            ...state,
           loading: false,
           sessionId: ''
        }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    return reducer
}

export default initReducer