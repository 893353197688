import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import { EmployeeState } from ".";

const initReducer = function () {
    const INITIAL_STATE: EmployeeState = {
        allEmployees: [],
        loading: false,
        employeeDetail: {},
        createEmployeePayload: {},
        employeeChangeStatus: null,
        updateEmployee: null,
        permissions: {
            employeePermission: [],
            parentPermission: []
        },
        updatePermissionStatus: null,
        employeeCreateSuccessDetails: null
    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.EmployeesGetAllEmployeeRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            allEmployees: [],
        }
    }
    reducersByActions[ActionType.EmployeesGetAllEmployeeResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            allEmployees: actionData,
        }
    }

    reducersByActions[ActionType.EmployeesGetEmployeeDetailRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            employeeDetail: {},
        }
    }

    reducersByActions[ActionType.EmployeesGetEmployeeDetailResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            employeeDetail: actionData,
        }
    }

    reducersByActions[ActionType.EmployeeChangeStatusRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            employeeChangeStatus: null,
        }
    }

    reducersByActions[ActionType.EmployeeChangeStatusResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            employeeChangeStatus: actionData,
        }
    }

    reducersByActions[ActionType.EmployeeCreateRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            createEmployeePayload: {},
        }
    }

    reducersByActions[ActionType.EmployeeCreateResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            createEmployeePayload: actionData,
        }
    }

    reducersByActions[ActionType.EmployeeUpdateRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            updateEmployee: null,
        }
    }

    reducersByActions[ActionType.EmployeeUpdateResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            updateEmployee: actionData,
        }
    }

    reducersByActions[ActionType.EmployeeGetPermissionRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            permissions: {
                employeePermission: [],
                parentPermission: []
            }
        }
    }

    reducersByActions[ActionType.EmployeeGetPermissionResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            permissions: actionData,
        }
    }

    reducersByActions[ActionType.EmployeeUpdatePermissionRequest] = (state: any) => {
        return {
            ...state,
            loading: true,
            updatePermissionStatus: null,
        }
    }

    reducersByActions[ActionType.EmployeeUpdatePermissionResponse] = (state: any, actionData: any) => {
        return {
            ...state,
            loading: false,
            updatePermissionStatus: actionData,
        }
    }

    reducersByActions[ActionType.EmployeeChangeStatusReset] = (state: any) => {
        return {
            ...state,
            employeeChangeStatus: null,
            employeeCreateSuccessDetails: null,
            updateEmployee: null
        }
    }

    reducersByActions[ActionType.EmployeeSuccessCreateDetails] = (state: any, actionData: any) => {
        return {
            ...state,
            employeeCreateSuccessDetails: actionData
        }
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    }

    return reducer
}

export default initReducer