import { IServices } from "@src/services";
import initActions from "./actions";
import initReducer from "./reducer";
import { Action } from "rxjs/internal/scheduler/Action";

interface ValidateResponse {
    code: boolean | null,
    errormsg: string | null
}

interface sigaRegisterResponseInterface {
    isSuccess: boolean | null,
    iccId: string | null,
    subscriptionId: string | null,
    errorMessage: string | null,
    isFMVNO: boolean | null
}

export interface SigaSimRegState {
    loading: boolean,
    documentData: Array<string>,
    validatePukStatus: ValidateResponse | null,
    sigaRegisterResponse: sigaRegisterResponseInterface
}

const configureSigaSimRegModule = (services: IServices) => {
    const actions = initActions(services.simRegistrationService)
    const reducer = initReducer()

    return { actions, reducer }
}

export type SigaSimRegReducer = ReturnType<typeof initReducer>
export type SigaSimRegAction = ReturnType<typeof initActions>

export default configureSigaSimRegModule