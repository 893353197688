import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ChiswickRegular from './res/fonts/chiswick-grotesque-app-regular.ttf';
import ChiswickSemibold from './res/fonts/chiswick-grotesque-app-semibold.ttf';
import Chiswickbold from './res/fonts/chiswick-grotesque-app-bold.ttf';
import GothamLight from './res/fonts/gotham-light.woff';
import { blue, grey } from '@mui/material/colors';
import type { } from '@mui/x-data-grid/themeAugmentation';

const ChiswickGrotesque700: any = {
    fontFamily: 'Chiswick Grot Lebara App',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
        local(Chiswick Grot Lebara App),
        local(Chiswick Grot Lebara App Bold),
        url(${Chiswickbold}) format('truetype')
    `
};

const ChiswickGrotesque600 = {
    fontFamily: 'Chiswick Grot Lebara App',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
        local(Chiswick Grot Lebara App),
        local(Chiswick Grot Lebara App Semi Bold),
        url(${ChiswickSemibold}) format('truetype')
    `
};

const ChiswickGrotesque400 = {
    fontFamily: 'Chiswick Grot Lebara App',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
        local(Chiswick Grot Lebara App),
        local(Chiswick Grot Lebara App Regular),
        url(${ChiswickRegular}) format('truetype')
    `
};

const Gotham = {
    fontFamily: 'Gotham Light Lebara App',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
        local(Gotham Light Lebara App),
        local(Gotham Light Lebara App Regular),
        url(${GothamLight}) format('woff')
    `
};

let theme = createTheme({
    cssVariables: true,
    palette: {
        primary: {
            main: "#0094F6"
        },
        secondary: {
            main: "#b91866"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            'Chiswick Grot Lebara App',
            'Gotham Light Lebara App',
            '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif'
        ].join(','),
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MuiInputLabel: {
            defaultProps: {},
            styleOverrides: {
                'root': {
                    '&.Mui-focused': {
                        color: grey['900']
                    },
                    overflow: 'visible',
                    textOverflow: 'initial'
                },
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '&.MuiDataGrid-overlay': {
                        height: 'auto !important',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    overflow: 'hidden'
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0 100px ${blue[50]} inset`,
                        WebkitTextFillColor: 'black',
                        caretColor: 'black'
                    },
                },
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '@font-face': [ChiswickGrotesque700, ChiswickGrotesque600, ChiswickGrotesque400, Gotham],
                    html: {
                        WebkitFontSmoothing: 'antialiased',
                    }
                },
            },
        },
    }
})

theme = responsiveFontSizes(theme)

export { theme }