import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';

const configureSimRegAssessmentModule = (services: IServices) => {
  const actions = initActions(services.simRegAssessmentService);
  const reducer = initReducer();

  return { actions, reducer };
};

export interface Questionnaire {
  QuestionnaireId: string
  QuestionId: string
  QuestionText: string
  ReadSection: string
  Answers: Answer[]
  isMultipleAnswer: boolean
}

export interface Answer {
  QuestionId: string
  AnswerId: string
  AnswerText: string
  IsChecked: boolean
  IsCorrect: boolean
}

export type SimRegAssessmentReducer = ReturnType<typeof initReducer>
export type SimRegAssessmentActions = ReturnType<typeof initActions>

export default configureSimRegAssessmentModule;