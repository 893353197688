import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import moment from 'moment'
import { MyOrdersState } from ".";
import { filter } from "rxjs/operators";


const initReducer = function () {
    const INITIAL_STATE: MyOrdersState = {
        fetchInProgress: false,
        myOrders: [],
        isFetchingSummary: false,
        orderSummary: (undefined as any),
        OrderTotalCount: 0,
        isDownloading: false,
        products: [],
        filters: {
            dateFrom: moment().subtract(3, 'months').toDate(),
            toDate: moment().toDate(),
            // dateFrom: null,
            // dateTo: null,
            status: '',
            productId: '',
            orderNumber: '',
            orderBy: 'orderDate',
            orderDesc: true,
            pageNumber: 0,
        }
    }

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.MyOrdersGetProductsResponse] = (state: MyOrdersState, actionData: any) => {
        return {
            ...state,
            products: actionData
        }
    }

    reducersByActions[ActionType.MyOrdersGetOrdersRequest] = (state: MyOrdersState) => {
        return {
            ...state,
            fetchInProgress: true
        }
    }

    reducersByActions[ActionType.MyOrdersGetOrdersResponse] = (state: MyOrdersState, actionData: any) => {
        return {
            ...state,
            fetchInProgress: false,
            OrderTotalCount: actionData && actionData.length > 0 ? actionData[0].OrderCount : 0,
            myOrders: actionData ? actionData.map((val: any, i: number) => {return {...val, id: i}}) : []
        }
    }

    reducersByActions[ActionType.MyOrdersSetFilters] = (state: MyOrdersState, actionData: any) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.MyOrdersDownloadMyOrdersStart] = (state: MyOrdersState) => {
        return {
            ...state,
            isDownloading: true
        }
    }

    reducersByActions[ActionType.MyOrdersDownloadMyOrdersEnd] = (state: MyOrdersState) => {
        return {
            ...state,
            isDownloading: false
        }
    }

    reducersByActions[ActionType.MyOrdersCleanData] = (state: MyOrdersState) => {
        return {
            ...state,
            products: [],
            filters: {
                ...INITIAL_STATE.filters
            },
            myOrders: undefined,
            OrderTotalCount: 0
        }
    }

    reducersByActions[ActionType.OrderSummaryRequest] = (state: MyOrdersState) => {
        return {
            ...state,
            isFetchingSummary: true
        }
    }

    reducersByActions[ActionType.OrderSummaryResponse] = (state: MyOrdersState, actionData: any) => {
        return {
            ...state,
            isFetchingSummary: false,
            orderSummary: actionData
        }
    }

    reducersByActions[ActionType.OrderSummaryClear] = (state: MyOrdersState) => {
        return {
            ...state,
            orderSummary: undefined
        }
    }

    reducersByActions[ActionType.Logout] = () => {
        return INITIAL_STATE
    }

    const reducer = (state = INITIAL_STATE, action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };


    return reducer; 
}

export default initReducer