import { ContractInfoDto, ContractStatusSummaryDto } from "@src/dtos/contractStatusDto";
import { IAction, ActionReducer } from "../types";
import { ActionType } from "@src/dtos/enums";
import moment from 'moment';

const INITIAL_STATE = {
    contractList: [] as ContractStatusSummaryDto,
    SessionId: undefined as string | undefined,
    isFetchingContracts: false,
    isSendingSMS: false,
    isSendingEmail: false,
    isResendingEmail: false,
    isResigningOnscreen: false,
    isSuccess: true,
    isRetryingContract: false,
    isFetchingMoreDetails: false,
    isDownloadingContract: false,
    contractInfo: null as ContractInfoDto | null,
    filters: {
        dateFrom: moment().subtract(7, 'days').toDate() as Date | undefined,
        toDate: moment().toDate() as Date | undefined
    }
}

export type ContractStatusState = typeof INITIAL_STATE
export type ContractStatusFilter = typeof INITIAL_STATE.filters

const initReducer = function () {    

    let reducersByActions = {} as Partial<ActionReducer>

    reducersByActions[ActionType.ContractStatusUpdateFilter] = (state: ContractStatusState, actionData: ContractStatusFilter): ContractStatusState => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.ContractStatusGetListRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isFetchingContracts: true
        }
    }

    reducersByActions[ActionType.ContractStatusGetListResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isFetchingContracts: false,
            contractList: actionData.contractList || [],
            SessionId: actionData.SessionId
        }
    }

    reducersByActions[ActionType.ContractStatusSendSMSRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isSendingSMS: true
        }
    }

    reducersByActions[ActionType.ContractStatusSendSMSResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isSendingSMS: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusSendEmailRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isSendingEmail: true
        }
    }

    reducersByActions[ActionType.ContractStatusSendEmailResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isSendingEmail: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusResendEmailRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isResendingEmail: true
        }
    }

    reducersByActions[ActionType.ContractStatusResendEmailResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isResendingEmail: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusResignOnscreenRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isResigningOnscreen: true
        }
    }

    reducersByActions[ActionType.ContractStatusResignOnscreenResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isResigningOnscreen: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusRetryRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isRetryingContract: true
        }
    }

    reducersByActions[ActionType.ContractStatusRetryResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isRetryingContract: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusDownloadContractRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isDownloadingContract: true
        }
    }

    reducersByActions[ActionType.ContractStatusDownloadContractResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isDownloadingContract: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusContractInfoRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isFetchingMoreDetails: true
        }
    }

    reducersByActions[ActionType.ContractStatusContractInfoResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isFetchingMoreDetails: false,
            contractInfo: actionData.data,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusCleanData] = (state: ContractStatusState): ContractStatusState => {
        return JSON.parse(JSON.stringify(INITIAL_STATE))
    }

    reducersByActions[ActionType.Logout] = (state: ContractStatusState): ContractStatusState => {
        return JSON.parse(JSON.stringify(INITIAL_STATE))
    }

    const reducer = (state = JSON.parse(JSON.stringify((INITIAL_STATE))), action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] ? (reducersByActions[action.type] as Function)(state, action.data) : state;
    };

    return reducer;
}

export default initReducer