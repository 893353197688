import React, { useReducer } from 'react';
import { useTranslation  } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Input, InputAdornment } from '@mui/material';

import styles from './style.module.scss'
import Loading from '@src/View/Widgets/Loading';

import eyeIcon from '@src/res/eye.svg?url';
import closedEyeIcon from '@src/res/closed_eye.svg?url';
import { RootState } from '@src/modules/configureStore';
import { useAction } from '@src/View/Hooks/useAction';
import { useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router';


function ChangeForgottenPassword() {
  const { t } = useTranslation('translations')
  const location = useLocation()
  const dispatch = useDispatch()
  
  const [formInput, setFormInput] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      newPassword: "",
      repeatNewPassword: ""
    }
  );

  const [newPasswordShown, setNewPasswordShown] = React.useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false)
  const { 
    dataRequestError, dataRequestInProgress, updateRequestInProgress, 
    data, updateResult, newPasswordError, requestError,
    passwordFormatError, repeatNewPasswordError, passwordsShouldBeEqualError,

  } = useSelector((state: RootState) => state.forgottenPassword)
  const getPasswordChangeInfo = useAction(actions => actions.forgottenPassword.getPasswordChangeInfo)
  const updatePassword = useAction(actions => actions.forgottenPassword.updatePassword)
  const cleanError = useAction(actions => actions.forgottenPassword.cleanError)

  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    updatePassword(formInput.newPassword, formInput.repeatNewPassword, data)
  }

  const onCancel = () => {
    setFormInput({newPassword: "", repeatNewPassword: ""})
    cleanError()
  }
  
  const handleInput = (evt: any) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  if (!dataRequestError && !data && !dataRequestInProgress) {
    const found = /[?:|&|;]token=([^&|;]+)/.exec(location.search)
    if (found){
      getPasswordChangeInfo(found[1]);
    } else {
      dispatch(push('/login'))
    }
  }

  return (
    <Loading inProgress={dataRequestInProgress||updateRequestInProgress}>
      {updateResult &&<div className={styles.simpleMessage}>
          {t('resetpwdmessage-success')}
          <Button
            className={styles.hereToLoginButton}
            variant="contained" 
            color="primary" 
            onClick = {() => dispatch(push(`/login/${data.CountryCode}`))}
          >
            {t('resetpassword-login')}
          </Button>
        </div>}
      {dataRequestError&&<div className={styles.simpleMessage}>{t(dataRequestError)}</div>}
      {!updateResult&&data&&<div className={styles.body}>
        <div className={styles.bodyContainer}>
            <div className={styles.loginContainer}>
              <div className={styles.formRow}>
                <div className={styles.formContainer}>
                <div className={styles.selectSubheader}>{t('login-username')}: {data.UserName}</div>
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className={styles.formText}>{t('new-pwd')}*</div>
                    <Input 
                      className={styles.formField}
                      value = {formInput.newPassword}
                      id="Password" 
                      name="newPassword" 
                      placeholder={t('profile-newpassword')}
                      type={newPasswordShown?"text":"password"}
                      onChange={handleInput}
                      endAdornment={
                        <InputAdornment position="end" className={styles.showHideIcon} onClick={()=>setNewPasswordShown(!newPasswordShown)}>
                          <img src={newPasswordShown?eyeIcon:closedEyeIcon}  alt="..."/>
                        </InputAdornment>
                      }
                    />
                    {newPasswordError&&!formInput.newPassword&&<div className={styles.error}>{t('profile-newpasswordstrengthmsg')}</div>}
                    {passwordFormatError&&<div className={styles.error}>{t('profile-newpasswordminlengthmsg')}</div>}
                    <div className={styles.formText}>{t('profile-confirmpassword')}*</div>
                    <Input 
                      className={styles.formField}
                      value = {formInput.repeatNewPassword}
                      id="Password" 
                      name="repeatNewPassword" 
                      placeholder={t('enter-confirm-password')}
                      type={confirmPasswordShown?"text":"password"}
                      onChange={handleInput}
                      endAdornment={
                        <InputAdornment position="end" className={styles.showHideIcon} onClick={()=>setConfirmPasswordShown(!confirmPasswordShown)}>
                          <img src={confirmPasswordShown?eyeIcon:closedEyeIcon}  alt="..."/>
                        </InputAdornment>
                      }
                    />
                    {repeatNewPasswordError&&formInput.newPassword&&!formInput.repeatNewPassword&&<div className={styles.error}>{t('new-pwd-mismatch')}</div>}
                    
                    {passwordsShouldBeEqualError&&formInput.newPassword&&formInput.repeatNewPassword&&formInput.repeatNewPassword!==formInput.newPassword&&<div className={styles.error}>{t('new-pwd-mismatch')}</div>}
                    <div className={styles.buttonContainer}>
                      <Button 
                        className={styles.formButton}
                        variant="contained" 
                        color="primary" 
                        type="submit"
                      >
                        {t('userprofile-submit')}
                      </Button>
                      <Button 
                        className={styles.formButton}
                        variant="contained" 
                        color="primary" 
                        onClick = {() => onCancel()}
                      >
                        {t('userprofile-cancel')}
                      </Button>
                    </div>
                    {requestError&&<div className={styles.loginError}>{t(requestError)}</div>}
                  </form>
                </div>
                <div className={styles.newsContainer} dangerouslySetInnerHTML={{__html: t('reset-password-information').replaceAll("&#013;", "<br/>")}}></div>
              </div>
            </div>
        </div>
      </div>}
    </Loading>
  );
}

export default ChangeForgottenPassword
