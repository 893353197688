import { AppDispatch } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { IFaqService } from "@src/services/FaqService";
import { map } from "rxjs/operators";

const initActions = (faqServices: IFaqService) => {



    const faqRecordFetch = (dispatch: AppDispatch) => () => {
        dispatch({ type: ActionType.FaqRequest })
        faqServices.fetchFaq()
            .subscribe((res) => {
                dispatch({
                    type: ActionType.FaqResponse,
                    data: res
                })
            })
    }

    return {
        faqRecordFetch,
    }
}

export default initActions