import { IServices } from '@src/services';
import initActions from './actions';
import initReducer from './reducer';
import { History } from 'history';

export interface IUserDetails {
  Role: string;
  AccountName: string;
  UserId: string;
  Email: string;
  Address?: any;
  BillingAddress: BillingAddress;
  ShippingAddress: BillingAddress;
  Phone: string;
  Mobile: string;
  UserName: string;
  ImageUrl: string;
  IsUpdatePending: boolean;
  IsBlock: boolean;
  CountryRegionId: string;
  Language?: any;
  UserNotificationPreference: string;
  RevShareCommissionPercentage: string;
  SalesAgent?: any;
  SalesRegion?: any;
  ExpirationDate: string;
  SirenNo?: any;
  GATarget?: any;
  SubAccountID: string;
  SubAccountAxCode: string;
  SubAccountRole?: any;
  IsControlsAllowed: boolean;
  IsOwnerLogin: boolean;
  SalesAgentsList?: any;
  SalesRegionsList?: any;
  EffectiveDateCommission: string;
  EffectiveDateGA: string;
  IsRibbon: boolean;
  FirstName: string;
  LastName: string;
  Level: number;
  DisplayName: string;
  AXCode: string;
  EmployeeId: string;
  IsFirstTimeLogin?: any;
  IsAcceptedTermsAndConditions: boolean;
  TermsAndConditionsAcceptedTime: string;
  IsAssessmentCompleted: boolean;
  AssessmentTakenDate: string;
  IsSimregCertified: boolean;
  AssessmentStatus: string;
  EmployeeBonus: boolean;
  IsCTCAccepted: boolean;
}
export interface BillingAddress {
  AddressId?: any;
  AddressType: number;
  CustomerId?: any;
  Name?: any;
  Telephone: string;
  Email: string;
  Street: string;
  City: string;
  County: string;
  State: string;
  ZipCode: string;
  Address1?: any;
  Address2?: any;
  Country: string;
}

const configureUserModule = (services: IServices) => {
  const actions = initActions(services.userService, services.termsAndConditionsService, services.marketingPopupService);
  const reducer = initReducer(services.userService, services.storageService);

  return { actions, reducer };
};

export type UserReducer = ReturnType<typeof initReducer>
export type UserActions = ReturnType<typeof initActions>

export default configureUserModule;