import React from 'react';
import styles from './style.module.scss'// react-router v4/v5
import OldStyleContainer from '@src/View/Widgets/OldStyleContainer';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState } from '@src/modules/configureStore';

import docIcon from '@src/res/file-text.svg?url'
import checkIcon from '@src/res/green-check.svg?url'
import logo from '@src/res/printLogo.png'
import classnames from 'classnames';
import { useAction } from '../Hooks/useAction';
import Loading from '../Widgets/Loading';

function TermsAndConditions() {
  const user = useSelector((state: RootState) => state.user)

  const { t } = useTranslation('translations')

  const termsDownload = useAction(actions => actions.user.termsDownload)
  const termsSendEmail = useAction(actions => actions.user.termsSendEmail)
  const logout = useAction(actions => actions.user.logout)
  const accept = useAction(actions => actions.user.acceptTerms)


  return (
    <div className={styles.appContainer}>     
      <div className={styles.header}>
        <img src={logo} alt="" />
        <span className={styles.userInfo}>{`${user.userData.userName} (${user.userData.AXCode})`}</span>
      </div>       
      <div className={styles.contentContainer}>        
        <OldStyleContainer 
          label = {t('register-termscondition')}
          content = {<img src={docIcon}  alt="..."/>}
        />
        <span className={styles.welcomeText} dangerouslySetInnerHTML={{__html: t('commercial-termsandconditions-welcome-text')}}></span>
        <div className={styles.iframeContainer}>
          <iframe title='trems & conditions' src={user.termsAndConditionsData.FilePath} height="100%" width="100%"></iframe>
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.linksContainer}>
            <span onClick={termsDownload}>{t('termsandconditions-downloadaspdf')}</span>
            <span onClick={termsSendEmail}><Loading inProgress={user.termsAndConditionsEmailSending}>{t('termsandconditions-emailacopy')}</Loading></span>
            <span><a href={user.termsAndConditionsData.FilePath} target='_blanc'>{t('termsandconditions-print')}</a></span>
          </div>
          <div className={styles.rightButtonsContainer}>
            <div className={classnames(styles.button, styles.primary)} onClick={accept}>{t('termsandconditions-iagree')}</div>
            <div className={classnames(styles.button, styles.secondary)} onClick={logout}>{t('termsandconditions-cancel')}</div>
          </div>
        </div>
        {user.termsAndConditionsEmailSendingResult !== undefined && <>
          {user.termsAndConditionsEmailSendingResult ? 
          <div className={styles.emailResultContainer}>
            <img src={checkIcon} alt="" />
            <span>{t('termsandconditions-email-success')}</span>
          </div> 
          : <span>{t('error')}</span>}
        </>}
      </div>
    </div>
  );
}

export default TermsAndConditions
