import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { CommissionDto, CommissionSummaryDto } from "@src/dtos/comissionDto"
import { Observable } from "rxjs"
import { FiltersDto } from "@src/dtos/filtersDto"

const CommissionService = (ajax: ajaxType) => {return{
    
    fetchCommissionInfo: (filters: FiltersDto): Observable<CommissionDto> => ajax.get(
        `${config.url.API_URL}commission/history/${filters?.status}/${filters?.dateFrom}/${filters?.toDate}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),

    fetchCommissionSummary: (): Observable<CommissionSummaryDto> => {
        return ajax.get(`${config.url.API_URL}commission/summary`).pipe(map(res => res.response))
    },
}}

export type ICommissionService = ReturnType<typeof CommissionService>

export default CommissionService