import { Route, Routes } from 'react-router';
import { RootState } from '@src/modules/configureStore';

import styles from './Login.module.scss'
import Loading from '../Widgets/Loading';
import MainLogin from './MainLogin';

import loginLogo from '@src/res/login_logo.png';
import logo5g from '@src/res/logo_5G.png';
import ChangeForgottenPassword from './ChangeForgottenPassword';
import { useSelector } from 'react-redux'

const EmptyComponent = () => <></>

function Login() {
  const { selectedCountry, grecaptchaKey, countryConfigurationInProgress, loginInProgress } = useSelector((state: RootState) => state.user)
  const { lngLoading } = useSelector((state: RootState) => state.localization)
  const LoginComponent = grecaptchaKey ? MainLogin : EmptyComponent

  return (
    <div className={styles.appContainer}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div >
            <img src={loginLogo} alt="..." style={{ height: 46 }} />
            {!!selectedCountry && selectedCountry !== "NL" &&
              <img src={logo5g} alt="..." style={{ height: 46, width: 'auto' }} />
            }
          </div>
        </div>
      </div>
      <Loading inProgress={lngLoading || countryConfigurationInProgress}>
        <Loading
          inProgress={loginInProgress}
          className={styles.loginSpinner}
        >

          <Routes>
            <Route path="/:country" element={<LoginComponent />} />
            <Route path="/" element={<LoginComponent />} />
            <Route path="/passwordchange" element={<ChangeForgottenPassword />} />
          </Routes>

        </Loading>
      </Loading>
    </div>
  );
}

export default Login
