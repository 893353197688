import { Suspense, useEffect } from "react";
import { useSelector } from 'react-redux';
import { RootState } from '@src/modules/configureStore';
import { useNavigate } from 'react-router-dom';
import Loading from "./View/Widgets/Loading";
import { PageName } from "./dtos/enums";

interface IProps {
    title?: string;
    children?: React.ReactNode;
    isValidate?: boolean;
    path?: string;
}

export const Page = (props: IProps) => {
    const navigate = useNavigate()
    const items = useSelector((state: RootState) => state.items)
    const user = useSelector((state: RootState) => state.user)
    if (user.termsAndConditionsData && user.termsAndConditionsData.IsFileAvailable 
        && !user.termsAndConditionsData.IsAccepted && !props.title?.includes(PageName.termsAndConditions)
    ) {
        navigate('/termsandconditions')
    } else if (
        user.marketingPopupData && user.marketingPopupData.IsFileAvailable 
        && user.termsAndConditionsData && !user.termsAndConditionsData.IsFileAvailable
        && !props.title?.includes(PageName.marketing)
    ) {
        navigate('/marketing')
    }
    let displayPage: JSX.Element = ((!props.isValidate) || (props.isValidate && !!items.menuByUrl)) ? props.children as JSX.Element : <></>
    let title = ((!props.isValidate) || (props.isValidate && !!items.menuByUrl)) ? props.title : 'Lebara'
    if (props.isValidate && props.path && !!items.menuByUrl) {
        let page = items.menuByUrl?.get(props.path.toLocaleLowerCase())
        if (!page && !props.title?.includes(PageName.dashboard)) {
            navigate('/dashboard')
        }
    }

    useEffect(() => {
        document.title = title || "";
    }, [props.title])

    return <Suspense fallback={<Loading inProgress={true} />}>{displayPage}</Suspense>;
}
