import { map } from 'rxjs/operators'
import config from '../../env'
import { ajaxType } from '../interceptor'
import { Observable } from 'rxjs'

const SimRegistrationService = (ajax: ajaxType) => {
    return {
        getDocument: () => {
            return ajax.get(`${config.url.API_URL}Documents`).pipe(map(res => res.response))
        },
        validatePuk: (pukCode: string, iccid: string, locale: string) => {
            return ajax.get(`${config.url.API_URL}SigaRegistration/ValidateSubscription/${iccid}/${pukCode}/${locale}`)
                .pipe(map(res => res.response))
        },
        submitSigaSimReg: (payload: any) => {
            return ajax.post(`${config.url.API_URL}SigaRegistration/Register`, payload,{'Content-Type':'application/json'}).pipe(map(res => res.response))
        },
        fetchRegistrationDetails: (): Observable<{ redirectTo: string; isSuccess: boolean; }> => {
            return ajax.get(`${config.url.API_URL}CustomerRegistration/Details`).pipe(map(res => res.response))
        },
        generalGetRequest: (url: string) => {
            return ajax.get(url)
            .pipe(map(res => res.response))
        },
    }
}

export type ISimRegistrationService = ReturnType<typeof SimRegistrationService>
export default SimRegistrationService