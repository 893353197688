import { IServices } from '@src/services';
import React from 'react';

export default React.createContext({} as { httpService: HttpService });

type Immutable<T> = Readonly<{
    [K in keyof T]: T[K] extends Function ? T[K] : Immutable<T[K]>;
}>

function deepFreeze<T>(obj: T) {
    var propNames = Object.getOwnPropertyNames(obj);
    for (let name of propNames) {
        let value = (obj as any)[name];
        if (value && typeof value === "object") {
            deepFreeze(value)
        }
    }
    return Object.freeze(obj)
}

export class HttpService {
    _srv: Immutable<IServices>
    constructor(srv: IServices) {
        this._srv = deepFreeze(srv)
    }
    getService() {
        return this._srv
    }
}