import { Actions } from "@src/modules";
import { AppDispatch, RootState } from "@src/modules/configureStore";
import { useDispatch, useStore } from "react-redux";
import { i18n } from 'i18next';
import { useTranslation } from "react-i18next";


export function useAction <TSelected = unknown> (
    selector: (actions: Actions) => ((dispatch: AppDispatch, state: () => RootState, i18n: i18n) => TSelected)
): TSelected {
    return selector(window.serviceActions)(useDispatch(), useStore().getState as () => RootState, useTranslation('translations').i18n)
}