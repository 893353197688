import { IUserService } from "@src/services/UserService";
import { ITermsAndConditionsService } from "@src/services/TermsAndConditionsService";
import { IMarketingPopupService } from "@src/services/MarketingPopupService";
import { map } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { AppDispatch, RootState } from "../configureStore";
import { ActionType } from "@src/dtos/enums";
import { i18n } from "i18next";
import { isBrowser } from 'react-device-detect'
import { push } from "redux-first-history";

declare const window: any;

const initActions = function (userService: IUserService, termsAndConditionsService: ITermsAndConditionsService, marketingPopupService: IMarketingPopupService) {

  const countrySelect = (dispatch: AppDispatch) => (country: string) => {
    dispatch({ type: ActionType.CountrySelect, data: { country: country } });
    getCountryConfiguration(dispatch, country)
  };

  const logout = (dispatch: AppDispatch) => () => {
    userService.logout().subscribe(() => { })
    dispatch({ type: ActionType.Logout })
  };

  const resetPassword = (dispatch: AppDispatch) => (login: string, country: string) => {
    dispatch({
      type: ActionType.ResetPasswordRequest
    })
    userService.resetPassword(login, country).subscribe(
      data => {
        if (data.Status) {
          dispatch({ type: ActionType.ResetPasswordResponse, data: data })
        }
        else {
          dispatch({ type: ActionType.ResetPasswordError, data: data.ErrorMessage })
        }
      }
    );
  };

  const login = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => (login: string, password: string, country: string, captchaToken: string) => {
    if (!login) {
      dispatch({
        type: ActionType.SetIdError
      })
    }
    if (!password) {
      dispatch({
        type: ActionType.SetPasswordError
      })
    }
    if (login && password) {
      dispatch({
        type: ActionType.LoginRequest
      })
      userService.login(login, password, country, captchaToken, getState().user.grecaptchaKey as string).subscribe({
        error: () => dispatch({ type: ActionType.LoginError, data: { message: 'genericerror' } }),
        next: data => {
          let reg = new RegExp("[^.]*$");
          if (data.IsSucceed) {
            if (window?.dataLayer) {
              const eventObj = {
                event: 'device_login',
                userId: reg.exec(data.UserInfo.UserName)?.[0],
                device: isBrowser ? 'browser' : 'mobile',
                user_country: getState().user.selectedCountry
              }
              window?.dataLayer?.push(eventObj)
            }
            dispatch({
              type: ActionType.LoginResponse,
              data: {
                data: {
                  userName: data.UserInfo.DisplayName,
                  fullName: data.UserInfo.FullName,
                  employeeId: data.UserInfo.EmployeeId,
                  paymentMode: data.UserInfo.PaymentMode,
                  AXCode: reg.exec(data.UserInfo.UserName),
                  Addresses: JSON.parse(data.UserInfo.Addresses),
                  Level: data.UserInfo.Level,
                  permissions: data.UserInfo.Permissions,
                  isRibbon: data.UserInfo.IsRibbon,
                  CountryMOV: data.UserInfo.CountryMOV,
                  CountryMaxOV: data.UserInfo.CountryMaxOV,
                  ActivationId: data.UserInfo.ActivationId
                },
                token: data.LoginToken,
                loginScore: data.LoginScore,
                lowScoreReason: data.LowScoreReason
              }
            })
            forkJoin([termsAndConditionsService.fetchTermsAndConditions(i18n.language), marketingPopupService.fetchMarketingPopup(i18n.language)])
              .subscribe(data => {
                const termsResponse = data[0]
                const marketingResponse = data[1]
                dispatch({ type: ActionType.PostLoginResponse, data: { termsResponse, marketingResponse } })
              })
          }
          else {
            dispatch({ type: ActionType.LoginError, data: { message: data.ErrorMessage, loginScore: data.LoginScore, lowScoreReason: data.LowScoreReason } })
          }
        }
      });
    }
  };

  const getCountries = (dispatch: AppDispatch) => async () => {
    dispatch({
      type: ActionType.CountriesRequest
    })
    const captchaKey = await userService.recaptchaSiteKey().toPromise()
    userService.getCountries()
      .pipe(map(list =>
        list?.map(i => {
          return {
            id: i.CountryName,
            fullName: i.CountryFullName,
            language: i.Language
          }
        })
      ))
      .subscribe(list => {
        dispatch({
          type: ActionType.CountriesResponse,
          data: { list, key: captchaKey }
        })
      });
  };

  const changePassword = (dispatch: AppDispatch) => () => {
    dispatch({
      type: ActionType.ChangePasswordRequest
    })
    userService.changePassword()
      // .pipe(map(list =>
      //   console.log("listwwwww",list)
      //   // list.map((i: any) => {
      //   //   return {
      //   //     id: i.CountryName,
      //   //     fullName: i.CountryFullName,
      //   //     language: i.Language
      //   //   }  
      //   // })
      // ))
      .subscribe(list => {
        dispatch({
          type: ActionType.ChangePasswordResponse,
          data: list
        })
      });
  };

  const getUserProfile = (dispatch: AppDispatch) => () => {
    dispatch({
      type: ActionType.UserProfileRequest
    })
    userService.getUserProfile()      
      .subscribe(list => {
        dispatch({
          type: ActionType.UserProfileResponse,
          data: list
        })
      });
  };
  const updateUserProfile = (dispatch: AppDispatch) => (data: any) => {
    dispatch({ type: ActionType.UpdateUserProfileRequest })
    userService.updateProfile(data).subscribe(() => {
      // if (res) {
      //  dispatch({ type: ActionType.UpdateUserProfileRequestResponse, data: JSON.stringify(data) })

      dispatch({
        type: ActionType.UserProfileRequest
      })
      userService.getUserProfile()
        .pipe(map(list => {
          if (list) {
            return {
              role: list?.Role,
              accname: list?.AccountName,
              email: list?.Email,
              mobile: list?.Mobile,
              phone: list?.Phone,
              isownerlogin: list?.IsOwnerLogin,
              BillingAddress: list?.BillingAddress,
              ShippingAddress: list?.ShippingAddress,
              list
            }
          } else {
            return undefined
          }
        }))
        .subscribe(list => {
          dispatch({
            type: ActionType.UserProfileResponse,
            data: list
          })
        });

      // }
    })
  }
  const getCountryConfiguration = (dispatch: AppDispatch, country: string) => {
    dispatch({
      type: ActionType.CountryConfigurationRequest
    })
    const countryConfig = userService.getCountryConfiguration(country).pipe(map(i => {
      return {
        AllowedLanguages: JSON.parse(i.AllowedLanguages),
        CurrencySymbol: i.CurrencySymbol,
        PerProductPrintAllowedQty: i.PerProductPrintAllowedQty,
        ShowExpiryDate: i.ShowExpiryDate,
        DirectTopupLocale: i.DirectTopupLocale,
        MobileNumberCountryDigits: parseInt(i.MobileNumberCountryDigits),
        MyAccountCommissionBlocks: JSON.parse(i.MyAccountCommissionBlocks),
        TopUpUI: JSON.parse(i.TopUpUI),
        DashboardTiles: i.DashboardTiles,
        CountryRegionId: i.CountryRegionId,
        InternationalDialingCode: i.InternationalDialingCode,
        MultiEpinPrintAllowedQty: i.MultiEpinPrintAllowedQty,
        IsTillPrintedFormat: i.IsTillPrintedFormat,
        IsMadateUseCommission: i.IsMadateUseCommission,
        ProductCategoryNotAllowedToMix: i.ProductCategoryNotAllowedToMix ? convertNotToMixCategory(JSON.parse(i.ProductCategoryNotAllowedToMix)) : undefined,
        IsDeliveryAddressEditable: i.IsDeliveryAddressEditable,
        ShowOffers: i.ShowOffers,
        AdvancePaymentDiscount: i.AdvancePaymentDiscount,
        VoucherLength: parseInt(i.VoucherLength),
        AllowedMethods: i.AllowedMethods?.split(','),
        TweakkerUrl: i.TweakkerUrl,
        CallRatesUrl: i.CallRatesUrl,
        MaxOQ: parseInt(i.MaxOQ),
        MinOQ: parseInt(i.MinOQ),
        IsSPVMPVMixedProducts: i.IsSPVMPVMixedProducts,
        ClientKey: i.ClientKey,
        ApiKey: i.ApiKey,
        ShowConfirmationForPrint: i.ShowConfirmationForPrint,
        LMVNOEnabled: i.LMVNOEnabled,
        QRCodeNumberToStart: i.QRCodeNumberToStart,
        ReportDataUntilDayMinus: i.ReportDataUntilDayMinus === 0 ? 1 : i.ReportDataUntilDayMinus,
        EmployeeWalletLockTimeout: i.EmployeeWalletLockTimeout || 5,
        OPEnabledForMultiPrint: i.OPEnabledForMultiPrint,
        IsPOSOrderSubmitToAx: i.IsPOSOrderSubmitToAx
        // LMVNOEnabled: false
      }
    }))

    countryConfig.subscribe({
      next: list => dispatch({
        type: ActionType.CountryConfigurationResponse,
        data: list
      }),
      error: err => {
        console.log(err)
        dispatch({ type: ActionType.CountrySelect, data: { country: '' } });
        dispatch({
          type: ActionType.CountryConfigurationResponse,
          data: undefined
        })
        dispatch({
          type: ActionType.setLanguageList,
          data: { lngList: { 'en-GB': { name: "English", shortName: 'ENG', loading: true } } }
        })
        setTimeout(() => dispatch(push('/login')))
      }
    })
  }


  const convertNotToMixCategory = (data: any[]) => {
    if (!Array.isArray(data) || data.length === 0) return;
    const result: any = {};
    data.forEach((item: any) => {
      const forCategory = item.ForCategory as string
      const exclusionCategory = item.ExclusionCategory as string[]
      result[forCategory] = [...exclusionCategory]
      exclusionCategory.forEach((el: string) => {
        if (!result[el]) {
          result[el] = []
        }
        result[el].push(forCategory)
      })
    })
    return result;
  }


  const cleanErorr = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.CleanErorr, data: undefined })
  };

  const termsDownload = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    termsAndConditionsService.downloadTerms(i18n.language)
      .pipe(map(data => {
        return data
      }))
      .subscribe((data) => {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = getState().user.termsAndConditionsData.FilePath.split('/').pop()
        a.click()
      })
  };

  const downloadTc = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    userService.downloadTc(i18n.language)
      .pipe(map(data => {
        return data
      }))
      .subscribe((data) => {
        window.open(`${data}`)
      })
  };

  const downloadCTC = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    userService.downloadCTC(i18n.language).pipe(map(data => {
      return data
    }))
      .subscribe((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        window.open(`${link}`)
        //   link.click();
      })
  };

  const termsSendEmail = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    dispatch({ type: ActionType.TermsAndConditionsSendEmailRequest })
    termsAndConditionsService.sendEmail(i18n.language)
      .subscribe(
        data => {
          dispatch({ type: ActionType.TermsAndConditionsSendEmailResponse, data: data })
        }
      )
  }

  const acceptTerms = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    dispatch({ type: ActionType.AcceptTermsRequest })
    termsAndConditionsService.accept(i18n.language)
      .subscribe({
        next: data => {
          dispatch({ type: ActionType.AcceptTermsResponse, data: { IsFileAvailable: false, FilePath: undefined } })
        },
        error: err => {
          dispatch({ type: ActionType.AcceptTermsResponse, data: { IsFileAvailable: false, FilePath: undefined } })
        }
      })
  }

  const readMarketingPopup = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    marketingPopupService.marketingPopupRead(i18n.language)
      .subscribe({
        next: data => {
          dispatch({ type: ActionType.MarketingPopupSkip, data: { IsFileAvailable: false, FilePath: undefined } })
        },
        error: err => {
          dispatch({ type: ActionType.MarketingPopupSkip, data: { IsFileAvailable: false, FilePath: undefined } })
        }
      })
  }

  const readLaterMarketingPopup = (dispatch: AppDispatch, getState: () => RootState, i18n: i18n) => () => {
    marketingPopupService.marketingPopupReadLater(i18n.language)
      .subscribe({
        next: data => {
          dispatch({ type: ActionType.MarketingPopupSkip, data: { IsFileAvailable: false, FilePath: undefined } })
        },
        error: err => {
          dispatch({ type: ActionType.MarketingPopupSkip, data: { IsFileAvailable: false, FilePath: undefined } })
        }
      })
  }

  const paymentLogo = (dispatch: AppDispatch) => () => {
    userService.paymentLogo()
      .subscribe(
        data => {
          window.localStorage.setItem('paymentLogo', JSON.stringify(data))
          dispatch({ type: ActionType.PaymentLogo, data: data })
        }
      )
  }

  //use this function for state cleanup
  const cleanUserProfie = (dispatch: AppDispatch) => () => {
    dispatch({ type: ActionType.CleanUserProfile, data: undefined })
  };

  // return { login, getCountries, countrySelect, logout, resetPassword, cleanErorr, termsDownload, termsSendEmail, acceptTerms, paymentLogo, readMarketingPopup, readLaterMarketingPopup };
  return { login, getCountries, getUserProfile, downloadTc, downloadCTC, changePassword, updateUserProfile, countrySelect, logout, resetPassword, cleanErorr, termsDownload, termsSendEmail, acceptTerms, paymentLogo, readMarketingPopup, readLaterMarketingPopup, cleanUserProfie };

};
export default initActions;