import { map } from "rxjs/operators"
import config from '../../env'
import { ajaxType } from "../interceptor"
import { filterEncodedStr } from "@src/utils"
import { FiltersDto } from "@src/dtos/filtersDto"
import { Observable } from "rxjs"
import { InvoiceHistoryDto } from "@src/dtos/invoiceHistoryDto"

const InvoiceHistoryService = (ajax: ajaxType) => ({
    fetchInvoices: (filters: FiltersDto): Observable<InvoiceHistoryDto> => ajax.get(
        `${config.url.API_URL}Order/InvoiceHistory?${filterEncodedStr(filters)}`,
        { "Content-Type": "application/json" }
    ).pipe(map(res => res.response)),
})

export type IInvoiceHistoryService = ReturnType<typeof InvoiceHistoryService>

export default InvoiceHistoryService